import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import classNames from "classnames";
import {withUserContext, withUserContextProps} from "../context/userContext";
import QueryString from "qs";
import J from 'proper-url-join';

interface Props extends WithStyles<typeof style>, withUserContextProps {
    title: string
}

const style = (theme: Theme) => createStyles({
    box: {
        maxWidth: 500,
        margin: '0 auto',
        marginTop: 50,
        marginBottom: 50
    },
    primaryBtn: {
        cursor: 'pointer',
        borderRadius: 10,
        padding: '25px 0',
        color: '#fff',
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '23px',
        width: '100%',
        border: 'none'
    },
    pinkSecondaryBg: {
        background: 'linear-gradient(180deg, #783A79 0%, #482748 100%)'
    },
    bluePrimaryBg: {
        backgroundColor: '#6777FF',
        boxShadow: '0px -2px 24px rgba(98, 151, 255, 0.44)'
    },
    blueSecondaryBg: {
        background: 'linear-gradient(180deg, #393F78 0%, #252845 100%)'
    },
});

const parse = QueryString.parse(window.location.search || '', {
    ignoreQueryPrefix: true
}) as any

delete parse.ukey;

const bronLink = J('https://payment.profinansy.ru/793', { query: parse });

class Bron extends React.Component<Props> {
    render() {

        const {classes} = this.props;

        const ukey = this.props.ukey;

        const finalLink = J(bronLink, { query: { ukey } })

        return (
            <div className={classes.box}>
                <a href={finalLink} target={'_blank'}>
                    <button
                        className={classNames(classes.primaryBtn, {
                            [classes.pinkSecondaryBg]: true,
                            [classes.blueSecondaryBg]: false
                        })}
                    >
                        {this.props.title}
                    </button>
                </a>
            </div>
        )
    }
}

export default withUserContext(withStyles(style)(Bron)) as any;