import {
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'

import { answerPoll } from '../../api/api'
import {
  withUserContext,
  withUserContextProps
} from '../../context/userContext'
import { ActivePoll, NoActivePoll } from '../../icons/ActivePoll'
import { GLOBAL_STORE, declOfNum } from '../lib'

interface Props extends WithStyles<typeof style>, withUserContextProps {
  info: any
  resetPolls: any
  isAdmin: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontWeight: 700,
      fontSize: 32,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    items: {},
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0
      },
      cursor: 'pointer'
    },
    itemMainInfo: {
      flex: 1,
      overflow: 'hidden',
      marginLeft: 5,
      position: 'relative',
      paddingTop: 10
    },
    itemTitle: {
      fontWeight: 400,
      fontSize: 14,
      color: 'rgba(255, 255, 255, 0.7)',
      position: 'relative',
      transition: 'top 1s',
      top: -5
    },
    itemTitleActive: {
      top: -10
    },
    count: {
      color: '#B3B3B3',
      marginLeft: 6
    },
    line: {
      borderRadius: 100,
      backgroundColor: '#181818',
      height: 3,
      position: 'absolute',
      bottom: 0,
      width: '100%'
    },
    lineActive: {
      borderRadius: 100,
      backgroundColor: '#6553F5',
      height: 3,
      position: 'absolute',
      bottom: 0,
      width: 0,
      transition: 'width 1s'
    },
    results: {
      width: 70,
      textAlign: 'right'
    },
    hidden: {
      visibility: 'hidden'
    },
    resultsCount: {
      textAlign: 'center',
      marginTop: 10
    },
    adminBox: {
      backgroundColor: '#181818',
      borderRadius: 20,
      width: 550,
      height: 550,
      marginBottom: 30,
      overflow: 'hidden',
      padding: '0 56px',
      display: 'flex',
      flexDirection: 'column'
    },
    adminTitle: {
      marginTop: 32,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '43px'
    },
    chartBlock: {
      flex: 1,
      overflow: 'hidden'
    }
  })

const COLORS_PIE = ['#6553F5', '#4134AB', '#886AF5', '#AB81F4']

class Poll extends React.Component<Props> {
  onPoll = (index: number) => {
    if (GLOBAL_STORE[this.props.info.id] !== undefined) return

    answerPoll({
      id: this.props.info.id,
      variant: index
    }, this.props.ukey)
      .then(() => {
        this.props.resetPolls(this.props.info.id, index)
      })
      .catch((e) => {})

    GLOBAL_STORE[this.props.info.id] = index
    this.forceUpdate()
  }

  render() {
    const { classes } = this.props

    if (this.props.isAdmin) {
      const categories = []
      const data = []
      const ys = [
        {
          name: 'test',
          data: []
        }
      ]

      this.props.info.variants.forEach((variant, index) => {
        categories.push(variant.title)
        data.push(variant.count)
        ys[0].data.push({
          name: variant.title,
          y: variant.count,
          color: COLORS_PIE[index % COLORS_PIE.length]
        })
      })

      return (
        <div className={classes.adminBox}>
          <div className={classes.adminTitle}>{this.props.info.title}</div>

          <div className={classes.chartBlock}>
            {this.props.info.type === 'circle' ? (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  credits: {
                    enabled: false
                  },
                  title: {
                    text: '',
                    align: 'left'
                  },
                  subtitle: {
                    text: '',
                    align: 'left'
                  },
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    style: {
                      fontFamily: 'Inter'
                    },
                    backgroundColor: '#181818'
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  accessibility: {
                    point: {
                      valueSuffix: '%'
                    }
                  },
                  plotOptions: {
                    pie: {
                      cursor: 'pointer',
                      dataLabels: {
                        enabled: true,
                        format:
                          '<b>{point.name}</b><br>{point.percentage:.1f} %',
                        distance: -50,
                        filter: {
                          property: 'percentage',
                          operator: '>',
                          value: 3
                        }
                      },
                      borderWidth: 0
                    }
                  },
                  series: ys
                }}
              />
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  xAxis: {
                    categories,
                    labels: {
                      style: {
                        color: 'rgba(255, 255, 255, 0.7)',
                        fontSize: '14px'
                      }
                    }
                  },
                  series: [
                    {
                      data,
                      name: '',
                      type: 'column',
                      color: '#6553F5',
                      borderWidth: 0
                    }
                  ],
                  legend: {
                    enabled: false
                  },
                  yAxis: [
                    {
                      gridLineWidth: 0,
                      title: {
                        text: 'test',
                        style: {
                          color: '#000'
                        },
                        enabled: false
                      },
                      labels: {
                        format: 'test',
                        style: {
                          color: '#000'
                        },
                        enabled: false
                      }
                    }
                  ],

                  credits: {
                    enabled: false
                  },
                  chart: {
                    style: {
                      fontFamily: 'Inter'
                    },
                    backgroundColor: '#181818'
                  },
                  title: {
                    text: '',
                    align: 'left'
                  },
                  subtitle: {
                    text: '',
                    align: 'left'
                  }
                }}
              />
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={classes.box}>
        <div className={classes.title}>{this.props.info.title}</div>

        <div className={classes.items}>
          {this.props.info.variants.map((el, index) => {
            return (
              <div
                className={classes.item}
                key={index}
                onClick={this.onPoll.bind(this, index)}
              >
                {GLOBAL_STORE[this.props.info.id] === index ? (
                  <ActivePoll />
                ) : (
                  <NoActivePoll />
                )}

                <div className={classes.itemMainInfo}>
                  <div
                    className={cx(classes.itemTitle, {
                      [classes.itemTitleActive]:
                        GLOBAL_STORE[this.props.info.id] !== undefined
                    })}
                  >
                    {el.title}
                  </div>

                  <div
                    className={cx({
                      [classes.hidden]:
                        GLOBAL_STORE[this.props.info.id] === undefined
                    })}
                  >
                    <div className={classes.line} />
                    <div
                      className={classes.lineActive}
                      style={{
                        width:
                          GLOBAL_STORE[this.props.info.id] === undefined
                            ? '0%'
                            : el.percent * 100 + '%'
                      }}
                    />
                  </div>
                </div>

                {GLOBAL_STORE[this.props.info.id] !== undefined ? (
                  <div className={classes.results}>
                    <span>{`${+(el.percent * 100).toFixed(2)}%`}</span>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>

        {GLOBAL_STORE[this.props.info.id] !== undefined ? (
          <div className={classes.resultsCount}>
            {`${this.props.info.totalCount} ${declOfNum(
              this.props.info.totalCount,
              ['голос', 'голоса', 'голосов']
            )}`}
          </div>
        ) : null}
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(Poll)) as any
