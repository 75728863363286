import { Button, Dialog, Input } from '@material-ui/core'
import classNames from 'classnames'
import { FC, useState } from 'react'

import { useAuthStyles } from './Auth.styles'

type EnterNameModalProps = {
  open: boolean
  onClose: () => void
  handleSubmit: (name: string) => void
}

export const EnterNameModal: FC<EnterNameModalProps> = ({
  open,
  onClose,
  handleSubmit
}) => {
  const [name, setName] = useState('')
  const classes = useAuthStyles()

  return (
    <Dialog open={open} onClose={onClose} className={classes.modal}>
      <p className={classNames(classes.title, classes.nameTitle)}>
        Введите свое имя, чтобы оставлять комментарии
      </p>
      <Input
        placeholder="Имя"
        onChange={({ target }) => setName(target.value)}
        value={name}
        className={classes.input}
      />
      <Button
        onClick={() => {
          if (!name) return
          handleSubmit(name)
        }}
        style={{ marginTop: 16 }}
        className={classNames(classes.filledButton, classes.btn)}
      >
        Добавить
      </Button>
    </Dialog>
  )
}
