import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useComboStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      padding: '16px 0px',
      display: 'flex',
      flexDirection: 'column',
      gap: 12
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
      '& > span': {
        fontSize: '16px',
        fontWeight: 600
      },
      '& > button': {
        cursor: 'pointer',
        background: 'transparent',
        height: '40px',
        borderRadius: '8px',
        border: '1px solid blue',
        fontSize: '18px',
        fontWeight: 600,
        color: 'blue'
      }
    }
  })
)
