export const tariffIncludesList = [
  {
    icon: 'icon-2.png',
    desc: '114 уроков по личным финансам и инвестициям'
  },
  {
    icon: 'icon-3.png',
    desc: 'Бессрочный доступ к материалам курса '
  },
  {
    icon: 'icon-4.png',
    desc: 'Учебное пособие на 590+ страниц и рабочая тетрадь с бесплатной доставкой по России'
  },
  {
    icon: 'icon-5.png',
    desc: 'Полезные файлы по инвестициям каждый день курса'
  },
  {
    icon: 'icon-6.png',
    desc: 'Живые вебинары с Ольгой Гогаладзе и финансовыми консультантами'
  },
  {
    icon: 'icon-7.png',
    desc: 'Ежедневная работа с финансовым консультантом в чате по 12 часов каждый день, в группах не более 50 человек.'
  },
  {
    icon: 'icon-8.png',
    desc: 'Персональный разбор инвестиционных идей в вашем портфеле'
  },
  {
    icon: 'icon-9.png',
    desc: 'Готовый финансовый план с рекомендациями по улучшению от сертифицированного финансового консультанта (для 1 цели)'
  },
  {
    icon: 'icon-10.png',
    desc: 'Воркшопы с финансовыми консультантами или Ольгой Гогаладзе'
  },
  {
    icon: 'icon-11.png',
    desc: '7 гарантий по договору'
  },
  {
    icon: 'icon-12.png',
    desc: 'Доступ в сообщество в инвестиционное сообщество выпускников курсов Ольги Гогаладзе'
  },
  {
    icon: 'icon-13.png',
    desc: 'Доступ к аккредитованному ПО Минцифры РФ "Конструктору финансового плана"'
  }
]

export const tariffGiftsList = [
  {
    icon: 'gift-1.png',
    desc: 'Финомика - детская обучающая игра'
  },
  {
    icon: 'gift-2.png',
    desc: 'Карточки по фундаментальному анализу'
  },
  {
    icon: 'gift-3.png',
    desc: 'Вебинар от Алексея Клеутина «Как наемному сотруднику увеличить свой доход»'
  },
  {
    icon: 'gift-4.png',
    desc: 'Экспресс-курс "Аналитика личных финансов"'
  },
  {
    icon: 'gift-5.png',
    desc: 'Экспресс-курс по недвижимости в России'
  },
    {
        icon: 'gift-5.png',
        desc: 'Экспресс-курс по недвижимости за рубежом'
    },
  {
    icon: 'gift-6.png',
    desc: 'Доступ к profinansy.ru на время обучения сроком на 6 месяцев'
  },
  {
    icon: 'gift-7.png',
    desc: 'Обучающие сессии с карьерным консультантом'
  }
]
