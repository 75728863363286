import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import React from 'react'

import Button from '../UI/Button'

interface Props extends WithStyles<typeof style> {
  onClose: any
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontSize: 36,
      fontWeight: 800,
      marginBottom: 26
    },
    group: {},
    groupTitle: {
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 700
    },
    groupItems: {
      marginBottom: 28
    },
    groupItem: {
      marginBottom: 12,
      display: 'flex'
    },
    groupCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#6553F5',
      marginTop: 7,
      marginRight: 12
    },
    groupItemText: {
      flex: 1,
      overflow: 'hidden',
      fontSize: 18
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10
    }
  })

const Render = [
  {
    title: 'Инвестиции для продвинутых:',
    blocks: [
      'Модуль 1. С помощью чего зарабатывают продвинутые инвесторы.',
      'Модуль 2.  Добавляем новые инструменты фондового рынка в свой портфель',
      'Модуль 3. Как повысить доходность портфеля с помощью активного управления его составом',
      'Модуль 4. Как анализ экономики позволяет понять перспективы и увеличить доходность инвестора?',
      'Модуль 5. Осваиваем искусство антикризисного управления инвестиционным портфелем'
    ]
  }
]

class Modules_seconds extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        <div className={classes.title}>Программа курса</div>

        {Render.map((group, index) => {
          return (
            <div className={classes.group}>
              <div className={classes.groupTitle}>{group.title}</div>
              <div className={classes.groupItems}>
                {group.blocks.map((el, index) => {
                  return (
                    <div className={classes.groupItem}>
                      <div className={classes.groupCircle} />
                      <div className={classes.groupItemText}>{el}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}

        <div className={classes.button}>
          <Button onClick={this.props.onClose}>Выбрать тариф</Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(Modules_seconds)
