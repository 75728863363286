import { CssBaseline } from '@material-ui/core'
import createMuiTheme from '@material-ui/core/styles/createTheme'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'

import './App.css'
import { Auth } from './components/Auth'
import Main from './components/Main'
import { userContext } from './context/userContext'
import { useCheckUser } from './hooks'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["'Inter'"].join(',')
  }
})

const App = () => {
  //const { onLogout, setUserData, userData, setBalance } = useCheckUser();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <SnackbarProvider
            maxSnack={10}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            autoHideDuration={10000}
            hideIconVariant={true}
            disableWindowBlurListener
        >

            <Main />
        </SnackbarProvider>
    </ThemeProvider>
  )
}
export default App
