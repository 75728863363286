import { createStyles, makeStyles } from '@material-ui/core'

export const useClubCardStyles = makeStyles((theme) =>
  createStyles({
      primaryBtn: {
          background: '#6553F5',
          padding: '25px 16px',
          width: '100%',
          border: 'none',
          color: '#fff',
          borderRadius: 8,
          fontWeight: 700,
          marginBottom: 40,
          cursor: 'pointer'
      },
    container: {
      maxWidth: 404,
      width: '100%',
      borderRadius: 20,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
      padding: '24px 36px',
      background: '#343434',
      textAlign: 'center'
    },
    subscribe: {
      fontSize: 14,
      lineHeight: '125%',
      color: '#716F7C',
      marginBottom: 12,
      display: 'inline-block'
    },
    title: {
      fontSize: 24,
      lineHeight: '29px',
      display: 'block',
      textTransform: 'uppercase',
      color: '#B7AEFF'
    },
    foreverTitle: {
      color: '#A0DD1E !important'
    },
    content: {
      padding: '36px 40px',
      background: '#242424',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: '1 0 auto'
    },
    priceBlock: {
      display: 'flex',
      alignItems: 'center'
    },
    newPrice: {
      fontFamily: 'Russo One',
      fontSize: 32,
      lineHeight: '133%',
      color: '#FFFFFF',
      '& > span': { fontSize: 18, paddingLeft: 12 }
    },
    oldPrice: {
      fontFamily: 'Russo One',
      fontSize: 20,
      lineHeight: '130%',
      color: '#716F7C',
      textDecoration: 'line-through',
      paddingLeft: 12
    },
    priceDescription: {
      marginTop: 10,
      fontSize: 14,
      lineHeight: '125%',
      color: '#716F7C',
      textAlign: 'center',
      marginBottom: 22,
      '& > span': { textDecoration: 'line-through' },
      '& > b': { color: '#ffffff' },
      [theme.breakpoints.down(850)]: {
        marginBottom: 0
      }
    },
    foreverPriceDescription: {
      marginBottom: 0
    },
    button: {
      maxWidth: 324,
      width: '100%',
      borderRadius: 8,
      background: '#6553F5',
      borderWidth: '0px 1px 2px 1px',
      borderStyle: 'solid',
      borderColor: '#A094FF',
      fontSize: 18,
      lineHeight: '130%',
      color: '#FFFFFF',
      fontWeight: 700,
      padding: '25px 18px',
      marginTop: 32,
      cursor: 'pointer'
    },
    foreverButton: {
      background: '#A0DD1E !important',
      borderColor: '#D1FF6D',
      color: '#000000'
    },
    listTitle: {
      fontFamily: 'Russo One',
      marginTop: 32,
      fontSize: 17,
      lineHeight: '20px',
      color: '#4B4A53',
      textTransform: 'uppercase',
      display: 'inline-block',
      marginBottom: 24
    },
    listItem: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 16
    },
    listIcon: {
      marginRight: 16
    },
    listText: {
      fontSize: 15,
      lineHeight: '130%',
      '& > span': { color: '#9D9AB9', fontWeight: 700 }
    },
    giftContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1
    },
    giftTitle: {
      fontFamily: 'Russo One',
      fontSize: 17,
      lineHeight: '20px',
      textTransform: 'uppercase',
      color: '#B7AEFF',
      marginTop: 60,
      marginBottom: 28,
      display: 'inline-block',
      [theme.breakpoints.down(850)]: {
        marginTop: '10px',
        marginBottom: 14
      }
    },
    foreverGiftTitle: {
      marginTop: '10px !important',
      marginBottom: 14
    },
    giftText: {
      fontSize: 16,
      lineHeight: '125%'
    },
    giftList: {
      marginTop: -20
    },
    foreverGiftList: {
      marginTop: 0
    },
    giftItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8
    },
    giftImg: {
      maxWidth: 349,
      height: 'auto',
      marginBottom: 16,
      [theme.breakpoints.down(450)]: {
        maxWidth: 260
      }
    }
  })
)
