import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import classNames from "classnames";
import Img from '../../../Group 1000001383 (1).png';
import PayDialog from "../PayDialog";

interface Props extends WithStyles<typeof style> {
    isBook: true,
    title: string,
    paymentUrl: any;
    customIn: string;
}

const style = (theme: Theme) => createStyles({
    box: {
        background: 'linear-gradient(180deg, #0D0F1F 0%, #0B0E24 100%)',
        color: '#fff',
        padding: 20,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //justifyContent: 'center',
        fontFamily: 'Russo One, sans-serif',
        maxWidth: 500,
        height: 'fit-content'
    },
    title: {
        fontFamily: 'Russo One, sans-serif',
        color: '#D6D9F3',
        fontSize: 36,
        textAlign: 'center'
    },
    pricesBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '32px 0',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left'
        }
    },

    offPrice: {
        color: '#FFE9FF',
        fontFamily: 'Russo One, sans-serif',
        fontSize: 50,
        lineHeight: '66.5px',
        paddingRight: 16,
        textShadow: '0px 0px 8px rgba(253, 133, 255, 0.5)'
    },

    fullPrice: {
        color: '#fff',
        fontFamily: 'Russo One, sans-serif',
        fontSize: 40,
        lineHeight: '52px',
        opacity: 0.5,
        textDecoration: 'line-through',
        [theme.breakpoints.down('md')]: {
            order: -1
        }
    },

    description: {
        textAlign: 'center',
        fontSize: 18
    },

    titleBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: 13,
        maxWidth: 496,
        width: '100%',
        borderRadius: '40px 6px',
        padding: '22px 8px',
        background: 'rgba(255, 255, 255, 0.01)',
        boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.16)',
        backdropFilter: 'blur(40px)',
        margin: '20px 0',
        [theme.breakpoints.down('xs')]: {
            width: 'auto'
            // flexDirection: 'column'
        }
    },

    titleIcon: {
        minWidth: 79,
        height: 79,
        [theme.breakpoints.down('md')]: {
            minWidth: 50,
            height: 50
        }
    },

    mainTitle: {
        color: '#D6D9F3',
        textTransform: 'uppercase',
        fontSize: 18,
        fontWeight: 400,
        fontFamily: 'Russo One, sans-serif',
        [theme.breakpoints.down('md')]: {
            fontSize: 22
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18
        }
    },

    tariff: {
        fontSize: 30,
        marginTop: 20,
    },

    links: {
        alignSelf: 'flex-start',
        marginTop: 10
    },

    link: {
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
        fontSize: 18
    },

    primaryBtn: {
        cursor: 'pointer',
        borderRadius: 10,
        padding: '25px 0',
        color: '#fff',
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '23px',
        width: '100%',
        border: 'none',
        margin: '30px 0',
    },

    pinkPrimaryBg: {
        backgroundColor: '#FC66FF',
        boxShadow: '0px -2px 24px rgba(252, 96, 255, 0.44)'
    },

    bluePrimaryBg: {
        backgroundColor: '#6777FF',
        boxShadow: '0px -2px 24px rgba(98, 151, 255, 0.44)'
    },

    image: {
        maxWidth: 300
    }
});

class CursCard extends React.Component<Props> {

    state = {
        open: false
    }

    handleClickPay = () => {
        const el = document.getElementById(
            this.props.isBook ? 'curs_book1' : 'curs_1'
        )
        el.scrollIntoView({ behavior: 'auto', block: 'center' })
    }

    openDialog = (open: boolean) => {
        this.setState({
            open
        });
    }

    render() {

        const {classes} = this.props;

        return (
            <div className={classes.box}>

                {/*<PayDialog
                    link1={this.props.paymentUrl[0]}
                    link2={this.props.paymentUrl[1]}
                    open={this.state.open}
                    onClose={this.openDialog.bind(this, false)}
                    isBook={this.props.isBook}
                    customIn={this.props.customIn}
                />*/}

                <div className={classes.title}>
                    {this.props.title}
                </div>

                {
                    this.props.isBook ? <img src={Img} className={classes.image}/> : null
                }

                {
                    this.props.isBook ?
                        <p className={classes.fullPrice}>300 Р</p>
                        : null
                }

                {
                    this.props.isBook ?
                        <>

                            <button
                                className={classNames(classes.primaryBtn, {
                                    [classes.pinkPrimaryBg]: true,
                                    [classes.bluePrimaryBg]: false
                                })}
                                onClick={this.openDialog.bind(this, true)}
                            >
                                Купить за 1 рубль
                            </button>
                        </>
                        :
                        <>
                            <div className={classes.pricesBlock}>
                                <p className={classes.offPrice}>3 000 Р</p>
                            </div>

                            <button
                                onClick={this.handleClickPay}
                                className={classNames(classes.primaryBtn, {
                                    [classes.pinkPrimaryBg]: true,
                                    [classes.bluePrimaryBg]: false
                                })}
                            >
                                Оплатить сейчас
                            </button>

                            <div className={classes.description}>
                                Двухнедельный курс по инвестициям для новичков.
                                <br/>
                                После двух недель занятий вы начнете инвестировать. Гарантированно.
                            </div>

                            <div className={classes.titleBlock}>
                                <img
                                    alt=""
                                    className={classes.titleIcon}
                                    src={'/assets/people-icon.png'}
                                />
                                <p className={classes.mainTitle}>
                                    Дата старта: 10 апреля
                                    <br/>
                                    Подходит для нулевого уровня знаний.
                                    <br/>
                                    Подходит для любой страны.
                                </p>
                            </div>

                            <div className={classes.tariff}>
                                Тариф «Единый»
                            </div>

                            <div className={classes.links}>
                                <div className={classes.link}>- 2 недели занятий - живые уроки</div>
                                <div className={classes.link}>- учебный комплект с доставкой по России - бесплатно, зарубеж доставка рассчитывается в зависимости от страны</div>
                                <div className={classes.link}>- чат с финансовым консультантом на 2 недели</div>
                                <div className={classes.link}>- доступ к урокам в записи 1 месяц (можно будет продлить)</div>
                                <div className={classes.link}>- 🎁 книга «33 заметки о финансах»</div>
                            </div>

                            <div id={ this.props.isBook ? 'curs_book1' : 'curs_1' }/>


                            <a href={this.props.paymentUrl} target={'_blank'} style={{width: '100%'}}>
                                <button
                                    className={classNames(classes.primaryBtn, {
                                        [classes.pinkPrimaryBg]: true,
                                        [classes.bluePrimaryBg]: false
                                    })}
                                    onClick={this.openDialog.bind(this, true)}
                                >
                                    Оплатить сейчас
                                </button>
                            </a>
                        </>
                }
            </div>
        )
    }
}

export default withStyles(style)(CursCard);