import {
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import React from 'react'

import { getPoll } from '../../api/api'
import {
  withUserContext,
  withUserContextProps
} from '../../context/userContext'
import whileRequest from '../lib/whileRequest'

import WheelPollComponent from './WheelPoll'

HC_more(Highcharts)

interface Props extends WithStyles<typeof style>, withUserContextProps {
  data: Array<string>
  isAdmin: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    adminBox: {
      backgroundColor: '#181818',
      borderRadius: 20,
      width: 550,
      height: 550,
      marginBottom: 30,
      overflow: 'hidden',
      padding: '0 56px',
      display: 'flex',
      flexDirection: 'column'
    },
    adminTitle: {
      marginTop: 32,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '43px'
    },
    chartBlock: {
      flex: 1,
      overflow: 'hidden'
    }
  })

class WheelPoll extends React.Component<Props> {
  state = {
    polls: []
  }

  request: any
  timeout: any

  loading = false

  _load = () => {
    if (this.loading) return
    this.loading = true

    this.request = whileRequest(
      getPoll,
      {
        polls: this.props.data
      },
      this.props.ukey
    )

    this.request.then((data) => {
      this.loading = false

      this.setState({
        polls: data.data
      })

      this.timeout = setTimeout(() => {
        this._load()
      }, 30000)
    })
  }

  componentDidMount() {
    this._load()
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort()
    }

    clearTimeout(this.timeout)
  }

  setMore = (id, index) => {
    const newState = this.state.polls

    const findIndex = newState.findIndex((el) => {
      return el.id === id
    })

    if (findIndex !== -1) {
      newState[findIndex].totalCount++
      newState[findIndex].variants[index].count++

      let allSum = 0

      newState[findIndex].variants.forEach((el, key) => {
        el.percent = +(el.count / newState[findIndex].totalCount).toFixed(3)

        allSum += el.count * key
      })

      newState[findIndex].wheel = +(
        allSum / newState[findIndex].totalCount
      ).toFixed(2)

      if (this.request) {
        this.request.abort()
      }

      clearTimeout(this.timeout)
      this.loading = false

      this.timeout = setTimeout(() => {
        this._load()
      }, 10000)

      this.forceUpdate()
    }
  }

  render() {
    const { classes } = this.props

    if (this.props.isAdmin && this.state.polls.length > 0) {
      const categories = []
      const data = []

      this.state.polls.forEach((poll) => {
        categories.push(poll.return_title)
        data.push(poll.wheel)
      })

      return (
        <div className={classes.adminBox}>
          <div className={classes.adminTitle}>Колесо баланса</div>

          <div className={classes.chartBlock}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                credits: {
                  enabled: false
                },
                chart: {
                  style: {
                    fontFamily: 'Inter'
                  },
                  backgroundColor: '#181818',
                  polar: true
                },
                title: {
                  text: '',
                  align: 'left'
                },
                subtitle: {
                  text: '',
                  align: 'left'
                },

                xAxis: {
                  categories,
                  tickmarkPlacement: 'on',
                  lineWidth: 0,
                  labels: {
                    style: {
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '14px'
                    }
                  }
                },

                yAxis: {
                  min: 0,
                  max: 10,
                  title: {
                    text: 'test',
                    style: {
                      color: '#000'
                    },
                    enabled: false
                  },
                  labels: {
                    format: 'test',
                    style: {
                      color: '#000'
                    },
                    enabled: false
                  }
                },

                series: [
                  {
                    type: 'area',
                    name: 'Area',
                    data,
                    color: 'rgba(101, 83, 245, 0.2)'
                  }
                ],
                legend: {
                  enabled: false
                }
              }}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.box}>
        <Grid container spacing={6}>
          {this.state.polls.map((el) => {
            return (
              <Grid item xs={12} md={4} key={el.id}>
                <WheelPollComponent info={el} resetPolls={this.setMore} />
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(WheelPoll)) as any
