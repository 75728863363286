export const CARDS = [
  {
    key: 'tech_anal',
    id: '640097:3500',
    image: '/assets/2024/tech_anal.png',
    name: 'Курс по техническому анализу для новичков',
    description:
      'Научитесь читать графики акций, прогнозировать движение их цены',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс по техническому анализу для новичков</h2>' +
      '<p>Курс для новичков про основы технического анализа. Разберетесь как «читать» графики акций, прогнозировать движение их цены и зарабатывать на этом в своём портфеле.\n' +
      'С помощью технического анализы вы всегда будете точно знать, когда покупать и продавать акции.</p>' +
      '<h3>Узнаете:</h3>' +
      '<ul>' +
      '<li>как понять, до какого уровня вырастет или упадёт цена акции</li>' +
      '<li>как использовать уровни и коррекцию Фибоначчи, чтобы понимать дальнейшее движение цены</li>' +
      '<li>как совершать разные виды сделок в зависимости от ситуации на рынке</li>' +
      '<p>Курс включает: 11 уроков, 2 часа занятий,</p>' +
      '<p>1 практическое задание</p>' +
      '</ul>'
  },
  {
    key: 'trading',
    id: '640349:3500',
    image: '/assets/2024/trading.png',
    name: 'Курс Трейдинг-стратегии на инсайдерских сделках',
    description: 'Как зарабатывать на бирже следуя за крупными игроками',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс по трейдинг-стратегии на инсайдерских сделках SMART money</h2>' +
      '<p>Smart money - универсальная стратегия торговли, которая подходит не только для акций, но и для других инструментов, например, криптовалют.</p>' +
      '<h3>Узнаете:</h3>' +
      '<ul>' +
      '<li>как получать доход на рынке вместе с крупными профессиональными участниками - пенсионными, инвестиционными, хедж - фондами и т.д.</li>' +
      '<li>как покупать и продавать акции, чтобы зарабатывать, а не терять деньги</li>' +
      '<p>После курса у вас будет готовый алгоритм анализа, который позволит прогнозировать движение цены и зарабатывать на этом.</p>' +
      '<p>Курс включает: 7 уроков, 2+ часа занятий,</p>' +
      '<p>1 практическое задание</p>' +
      '</ul>'
  },
  {
    key: 'gold',
    id: '640350:3500',
    image: '/assets/2024/gold.png',
    name: 'Золотой курс от Ольги Гогаладзе',
    description: '4 способа заработать на золоте и драгоценных металлах',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Золотой курс\n' +
      'от Ольги Гогаладзе</h2>' +
      '<p>Узнайте о современных способах зарабатывать на золоте. В уроках вы получите пошаговые инструкции по созданию ваших первых золотых накоплений. После курса у вас будет план создания капитала, который принесёт доход не только вам, но и вашим детям и внукам.</p>'
  },
  {
    key: 'bond_salary',
    id: '640358:3500',
    image: '/assets/2024/bond_salary.png',
    name: 'Мастер-класс Облигационная зарплата',
    description:
      'Создаем источник пассивного дохода от облигаций в период высоких ставок',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Мастер-класс Облигационная зарплата от Ольги Гогаладзе</h2>' +
      '<p>Топовый мастер-класс от Ольги Гогаладзе по работе с облигациями в рублях и валюте. На уроках вы узнаете:</p>' +
      '<ul>' +
      '<li>как получить доходность 40-50% от самого консервативного инструмента фондового рынка</li>' +
      '<li>5 стратегий инвестиций в облигации</li>' +
      '<li>чем инвестиции в иностранные облигации отличаются от вложений в российские облигации</li>' +
      '<li>почему российским инвесторам тоже нужны облигации в валюте</li>' +
      '<li>как выбрать и купить еврооблигации на практике</li>' +
      '<li>можно ли увеличить доходность вложений в облигации</li>' +
      '<p>В уроках вас ждут практические инструкции по выбору облигаций в разной валюте и 10+ готовых списков облигаций от аналитиков pro.finansy. Всё это позволит вам создать регулярный доход от самого безопасного инструмента фондового рынка.</p>' +
      '</ul>'
  },
  {
    key: 'economic',
    id: '640363:3500',
    image: '/assets/2024/economic.png',
    name: 'Экспресс-курс по экономике',
    description: 'Чтобы зарабатывать на экономике не нужно быть экономистом',
    price: 3500,
    oldPrice: 12990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Экспресс-курс по экономике</h2>' +
      '<p>Чтобы зарабатывать на экономике не нужно быть экономистом. Достаточно пройти экспресс-курс по экономике от pro.finansy и узнать самое важное для инвестора:</p>' +
      '<ul>' +
      '<li>Что такое экономический цикл, и как он влияет на фондовый рынок</li>' +
      '<li>Почему умение определять фазу экономических циклов повышает доходность вложений</li>' +
      '<li>Кто и как регулирует экономику страны, и можно ли на этом зарабатывать</li>' +
      '<p>Понимание основных экономических процессов поможет вам прогнозировать поведение рынка, готовиться к изменениям и зарабатывать даже в кризис.</p>' +
      '</ul>'
  },
  {
    key: 'oil',
    id: '640354:3500',
    image: '/assets/2024/oil.png',
    name: 'Курс Как и зачем инвестировать в нефть',
    description: 'Как вложиться в рынок нефти и заработать на этом',
    price: 3500,
    oldPrice: 16990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как и зачем инвестировать в нефть</h2>' +
      '<p>Чёрное золото интересно не только нефтедобытчикам, но и инвесторам. Ведь нефть позволяет зарабатывать, даже не выходя из дома.</p>' +
      '<h3>В курсе рассказывается:</h3>' +
      '<ul>' +
      '<li>почему нефть всегда в центре внимания и что влияет на её цены</li>' +
      '<li>какие особенности у сектора нефтегаза в США и РФ</li>' +
      '<li>как инвестировать в рынок нефти, чтобы на нём зарабатывать</li>' +
      '<li>какие есть альтернативы у нефти и как инвестировать в возобновляемые источники энергии.</li>' +
      '<p>В курсе есть дополнительные материалы от аналитиков pro.finansy с готовыми списками ценных бумаг для инвестиций в нефть и не только.</p>' +
      '</ul>'
  },
  {
    key: 'foreign_brokers',
    id: '640355:3500',
    image: '/assets/2024/foreign_brokers.png',
    name: 'Интенсив по зарубежным брокерам',
    description:
      'Курс для тех, кто хочет открыть зарубежный счет и получать доход в валюте',
    price: 3500,
    oldPrice: 20990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Интенсив по зарубежным брокерам</h2>' +
      '<p>Курс для тех, кто хочет получать доход в валюте. В уроках вы получите не только информацию о зарубежных брокерах, но и пошаговые инструкции по работе с ними:</p>' +
      '<ul>' +
      '<li>как открыть счета и инвестировать через зарубежных брокеров: Interactive Brokers, Just2Trade, Freedom Finance\n</li>' +
      '<li>что покупать и продавать у каждого из трёх брокеров, чтобы получать доход</li>' +
      '<li>как разобраться и облегчить работу с налогами при инвестициях через иностранных брокеров</li>' +
      '<li>какие особые возможности дают инвесторам Interactive Brokers, Just2Trade, Freedom Finance</li>' +
      '<p>Курс состоит из уроков от Ольги Гогаладзе и видео-инструкций по работе с популярными иностранными брокерами - Just2Trade, Freedom Finance, Interactive Brokers. Вы сможете открыть счет и начать инвестировать уже во время прохождения курса.</p>' +
      '</ul>'
  },
  {
    key: 'crypto',
    id: '640356:3500',
    image: '/assets/2024/crypto.png',
    name: 'Курс Как покупать, хранить криптовалюту',
    description: 'Научитесь пользоваться криптовалютами с нуля',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как покупать, хранить и переводить криптовалюту от Павла Гогаладзе</h2>' +
      '<p>Курс научит пользоваться криптовалютами даже тех, кто с ними совсем не знаком. Из уроков вы узнаете:\n</p>' +
      '<ul>' +
      '<li>что такое криптовалюта, и какой она бывает</li>' +
      '<li>как используют криптовалюту в современном мире</li>' +
      '<li>где и как безопасно покупать, продавать, хранить и переводить криптовалюту</li>' +
      '<p>В курс включены уроки с теорией и практикой, а также бонусные уроки и методическое пособие. Всё это позволит вам начать использовать валюту 21 века уже во время обучения.</p>' +
      '</ul>'
  },
  {
    key: 'ai',
    id: '640361:3500',
    image: '/assets/2024/ai.png',
    name: 'Курс о секторе искусственного интеллекта',
    description:
      'Курс о секторе искусственного интеллекта: как выбирать акции и во что инвестировать',
    price: 3500,
    oldPrice: 16990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Инвестиции в будущее: как заработать на искусственном интеллекте</h2>' +
      '<p>Искусственный интеллект - основной тренд современности. Понимать тренд=уметь зарабатывать на нём. Из курса вы узнаете:</p>' +
      '<ul>' +
      '<li>откуда появился искусственный интеллект и какие перспективы для вложения денег в него\n</li>' +
      '<li>как эта и смежные отрасли будут развиваться в ближайшем времени\n</li>' +
      '<li>какие компании выиграют от развития искусственного интеллекта, и сколько на этом можно заработать\n</li>' +
      '<li>куда инвестировать, чтобы получать доход на самой популярной теме современного мира.\n</li>' +
      '</ul>'
  },
  {
    key: '11_top',
    id: '640351:3500',
    image: '/assets/2024/11_top.png',
    name: '11 ТОП-стратегий заработка в валюте',
    description:
      'Готовые стратегии инвестирования в зарубежные рынки, чтобы зарабатывать',
    price: 3500,
    oldPrice: 20990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс 11 ТОП-стратегий заработка в валюте</h2>' +
      '<p>Начните получать доход на зарубежном брокерском счёте. Вас ждут готовые стратегии инвестирования:</p>' +
      '<ul>' +
      '<li>в рынок США,\n</li>' +
      '<li>в развитые рынки за пределами США,\n</li>' +
      '<li>в развивающиеся рынки,\n</li>' +
      '<li>в еврооблигации и ETF на них.\n</li>' +
      '<p>Кроме уроков вы получите готовые списки ценных бумаг от аналитиков pro.finansy, чтобы начать зарабатывать сразу после прохождения курса.</p>' +
      '</ul>'
  },
  {
    key: 'dividend_salary',
    id: '640352:3500',
    image: '/assets/2024/dividend_salary.png',
    name: 'Курс Дивидендная зарплата',
    description:
      'Составьте дивидендный портфель, который будет приносить пассивный доход',
    price: 3500,
    oldPrice: 20990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Дивидендная зарплата</h2>' +
      '<p>Хотите получать зарплату, но не работать? Тогда вам точно сюда. В курсе рассказывается про формирование дивидендного портфеля - инвестиций, которые будут приносить пассивный доход в рублях и валюте. На уроках вы узнаете:</p>' +
      '<ul>' +
      '<li>что такое дивиденды, и почему инвесторы так их любят\n</li>' +
      '<li>когда наступает дивидендный сезон, и нужно ли его ждать для покупки акций\n</li>' +
      '<li>как найти и выбрать компании, которые платят лучшие дивиденды в рублях и валюте\n</li>' +
      '<li>как еще можно заработать на дивидендных выплатах\n</li>' +
      '<p>Уроки от аналитиков pro.finansy, готовые списки акций, практика по поиску лучших дивидендых компаний помогут вам составить дивидендный портфель с регулярными выплатами денег.</p>' +
      '</ul>'
  },
  {
    key: 'invest_ideas',
    id: '640368:3500',
    image: '/assets/2024/invest_ideas.png',
    name: 'Марафон инвест-идей',
    description:
      'Получите 20 свежих инвестиционных идей от Ольги Гогаладзе и аналитиков pro.finansy',
    price: 3500,
    oldPrice: 7990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Марафон инвест-идей на 2024–2025 годы</h2>' +
      '<p>На марафоне инвест-идей на конец 2024 и начало 2025 года вы узнаете:\n</p>' +
      '<ul>' +
      '<li>20 актуальных инвестиционных идей от Ольги Гогаладзе и аналитиков pro.finansy\n</li>' +
      '<li>что происходит в экономике России и США, и как на этом можно заработать\n</li>' +
      '<li>чего стоит ждать от фондовых рынков в ближайшее время\n</li>' +
      '<li>какие инвестиционные идеи будут актуальны для инвестиций в рублях и валюте\n</li>' +
      '<li>где найти идеи для портфеля, которые принесут доход, а не убытки\n</li>' +
      '<p>Марафон включает: 3 урока, 8 готовых списков с ценными бумагами для ваших портфелей, 3 конспекта уроков с графиками, инвестиционными идеями и их описанием</p>' +
      '</ul>'
  }
]
