import CookiesLib from 'browser-cookies'

export interface Response extends Promise<any> {
  abort: () => void
}

export const makeRequest = (
  url: string,
  payload: any,
  type: 'json' | 'form' | 'multi',
  notificationsError: boolean = true,
  isGetRequest: boolean = false,
  ignoreError: boolean = false,
  canError?: Array<number>,
  isDelete?: boolean,
  customPrefix?: string
): Response => {
  const controller = new AbortController()

  const _promise = new Promise((resolve, reject) => {
    try {
      let body = '' as any
      let contentType = ''

      if (type === 'form') {
        for (let key in payload) {
          if (payload.hasOwnProperty(key)) {
            const value = payload[key]
            body += `${key}=${encodeURIComponent(value)}&`
          }
        }
        body = body.substring(0, body.length - 1)

        contentType = 'application/x-www-form-urlencoded'
      } else if (type === 'json') {
        body = JSON.stringify(payload)

        contentType = 'application/json'
      } else if (type === 'multi') {
        const formData = new FormData()
        for (let key in payload) {
          if (payload.hasOwnProperty(key)) {
            formData.append(key, payload[key])
          }
        }
        body = formData
      }

      const headers = {} as any

      if (contentType) {
        headers['content-type'] = contentType
      }

      if (isGetRequest) {
        delete headers['content-type']
      }

      let method

      if (isGetRequest && !isDelete) {
        method = 'GET'
      } else if (isGetRequest && isDelete) {
        method = 'DELETE'
      } else {
        method = 'POST'
      }

      const params = {
        method,
        headers,
        signal: controller.signal
      } as any

      if (url.match(/^\/auth(?:\/|$)/)) {
        params['credentials'] = 'include'
      }

      if (isGetRequest === false) {
        params.body = body

        const _payload = {} as any

        const str = Object.keys(_payload)
          .map((k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(_payload[k])
          })
          .join('&')

        if (str) {
          url = `${url}?${str}`
        }
      } else {
        if (payload) {
          payload._ = Date.now()
        }
        const cookies = CookiesLib.get('auth-translation-offline')


        const str = Object.keys(payload)
          .map((k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
          })
          .join('&')

        if (str) {
          url = `${url}?${str}`
        }
      }

      const _PREFIX = 'https://live.profinansy.ru/api';
      //const _PREFIX = 'http://localhost:3000/translation-api'

      fetch(`${customPrefix || _PREFIX}${url}`, params)
        .then((response) => {
          return new Promise((resolve, reject) => {
            response
              .json()
              .then((data) => {
                resolve({
                  json: data,
                  status: response.status
                })
              })
              .catch((e) => {
                reject(e)
              })
          })
        })
        .then((_response: any) => {
          const response = _response.json

          if (
            ignoreError &&
            (_response.status === 200 || _response.json.ok !== undefined)
          ) {
            resolve(response)
          } else {
            let success = false

            if (_response.json.ok === true) {
              success = true
            } else if (
              canError &&
              canError.find((el) => el === _response.json.code)
            ) {
              success = true
            }

            if (success) {
              resolve(response)
            } else {
              reject('err')
            }
          }
        })
        .catch((e) => {
          reject(e)
        })
    } catch (e) {
      reject(e)
    }
  })

  //@ts-ignore
  _promise.abort = () => {
    if (controller) {
      controller.abort()
    }
  }

  return _promise as Response
}
