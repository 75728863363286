import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import React from 'react'

import { getAverage, getPoll } from '../../api/api'
import {
  withUserContext,
  withUserContextProps
} from '../../context/userContext'
import whileRequest from '../lib/whileRequest'

import Average from './Average'

interface Props extends WithStyles<typeof style>, withUserContextProps {
  data: Array<string>
  isAdmin: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {}
  })

class Averages extends React.Component<Props> {
  needUpdate = true

  state = {
    averages: []
  }

  request: any
  timeout: any

  loading = false

  _load = () => {
    if (this.loading) return
    this.loading = true

    this.request = whileRequest(
      getAverage,
      {
        averages: this.props.data
      },
      this.props.ukey
    )

    this.request.then((data) => {
      this.loading = false
      this.needUpdate = true

      this.setState({
        averages: data.data
      })

      this.timeout = setTimeout(() => {
        this._load()
      }, 30000)
    })
  }

  componentDidMount() {
    this._load()
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort()
    }

    clearTimeout(this.timeout)
  }

  setMore = (id, amount) => {
    const newState = this.state.averages

    const findIndex = newState.findIndex((el) => {
      return el.id === id
    })

    if (findIndex !== -1) {
      newState[findIndex].count++
      newState[findIndex].sum += amount

      newState[findIndex].rating = Math.round(
        newState[findIndex].sum / newState[findIndex].count
      )

      if (this.request) {
        this.request.abort()
      }

      clearTimeout(this.timeout)
      this.loading = false

      this.timeout = setTimeout(() => {
        this._load()
      }, 10000)

      this.forceUpdate()
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        {this.state.averages.map((el) => {
          return (
            <Average
              key={el.id}
              info={el}
              resetPolls={this.setMore}
              isAdmin={this.props.isAdmin}
            />
          )
        })}
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(Averages)) as any
