import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useTimerStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    timer: {
      borderRadius: '6px',
      border: '1px solid #FF4B33',
      background: '#1B1C27',
      padding: '8px 42px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 28,
      position: 'relative',
      width: 'fit-content',
      margin: '0 auto',
      [theme.breakpoints.down(1000)]: {
        marginTop: 20
      },
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      }
    },
    text: {
      fontSize: '20px',
      color: '#898989',
      fontWeight: 600,
      lineHeight: '125%',
      textTransform: 'uppercase',
      paddingRight: 40,
      [theme.breakpoints.down(1000)]: {
        fontSize: '24px'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '12px',
        paddingRight: 0,
        paddingBottom: 10
      }
    },
    time: {
      fontSize: '42px',
      background: 'linear-gradient(90deg, #FA9233 0.34%, #DD3320 108.55%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      fontWeight: 600,
      lineHeight: '125%',
      [theme.breakpoints.down(1000)]: {
        fontSize: '35px'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    clockLeft: {
      position: 'absolute',
      bottom: -20,
      left: -20,
      maxWidth: 70
    },
    clockRight: {
      position: 'absolute',
      top: -20,
      right: -20,
      maxWidth: 58
    }
  })
)
