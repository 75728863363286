import { createStyles, makeStyles } from '@material-ui/core'

export const usePlatformCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 404,
      borderRadius: 15,
      overflow: 'hidden',
      background: '#fff'
    },

    head: {
      padding: '24px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: '#242424',
      gap: 12
    },

    accessSubTitle: {
      color: '#5E5E5E',
      fontSize: 14,
      lineHeight: '17.5px'
    },

    mainTitle: {
      fontFamily: 'Russo One, sans-serif',
      fontSize: 24,
      lineHeight: '28px',
      textTransform: 'uppercase'
    },

    cardBody: {
      padding: '33px 40px 51px',
      color: '#000',
      textAlign: 'center'
    },

    primaryBtn: {
      background: '#6553F5',
      padding: '25px 16px',
      width: '100%',
      border: 'none',
      color: '#fff',
      borderRadius: 8,
      fontWeight: 700,
      marginBottom: 40,
      cursor: 'pointer'
    },

    pricesHead: {},
    desktopPrice: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12,
      [theme.breakpoints.down(1200)]: {
        display: 'none'
      }
    },
    mobilePrice: {
      display: 'none',
      textAlign: 'left',
      [theme.breakpoints.down(1200)]: {
        display: 'block'
      }
    },
    title1: {
      fontSize: 32,
      color: '#383838',
      lineHeight: '42px',
      fontFamily: 'Russo One, sans-serif'
    },

    fullPrice: {
      color: '#CDC9EA',
      fontSize: 20,
      lineHeight: '26px',
      fontFamily: 'Russo One, sans-serif',
      textDecoration: 'line-through'
    },

    title3: {
      color: '#242424',
      fontSize: 16,
      lineHeight: '20.8px',
      fontFamily: 'Russo One, sans-serif'
    },

    subtitle: {
      color: '#5E5E5E',
      fontSize: 14,
      marginBottom: 32,
      [theme.breakpoints.down(850)]: {
        textAlign: 'left'
      }
    },

    contentTitle: {
      color: '#CDC9EA',
      textTransform: 'uppercase',
      fontFamily: 'Russo One, sans-serif',
      fontSize: 17,
      textAlign: 'left',
      marginBottom: 18
    },

    benefitsList: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      marginBottom: 16,
      paddingBottom: 26,
      borderBottom: '1px dashed #ccc'
    },

    descBlock: {
      display: 'flex',
      gap: 16,
      alignItems: 'flex-start'
    },

    descText: {
      fontSize: 15,
      lineHeight: '20px',
      color: '#383838',
      textAlign: 'left'
    },

    icon: {
      width: 28,
      height: 28
    },

    giftsTitle: {
      color: '#C733D3',
      fontSize: 17,
      lineHeight: '20.49px',
      fontFamily: 'Russo One, sans-serif',
      marginBottom: 240
    },

    present: {
      position: 'absolute',
      top: -400,
      left: -40
    }
  })
)
