import React from 'react'

import { Timer } from '../BlackFriday/components/Timer/Timer'

import { useBlackStyles } from './BlackFriday.styled'
import { CARDS_MEDIUM, CARDS_MEDIUM2 } from './cardsMedium.const'
import { CARDS } from './cardsSmall.const'
import { CARDS_SUPER, CARDS_SUPER_NOT_RUSSIA } from './cardsSuper.const'
import { Banner } from './components/Banner/Banner'
import { CardSmall } from './components/CardSmall/CardSmall'
import { CardMedium } from './components/CardsMedium/CardMedium'
import { Cart } from './components/Cart/Cart'

interface IProps {
  deleteArr: string[]
}

const BlackFriday = ({ deleteArr }: IProps) => {
  const classes = useBlackStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div>
      <div className={classes.buttons}>
        <a href="https://clck.ru/3EdReW" target="_blank">
          <button>ОТДЕЛ ПРОДАЖ</button>
        </a>
        <a href="https://clck.ru/3EeDnB" target="_blank">
          <button>ПЕРСОНАЛЬНЫЙ МЕНЕДЖЕР</button>
        </a>
      </div>
      <a
        style={{ textDecoration: 'none' }}
        href="https://investmafk.ru/zayavka/blackfriday"
        target="_blank"
      >
        <button className={classes.giftButton}>
          Забрать подарки и участвовать в розыгрыше
        </button>
      </a>
      <Timer />
      <Banner deleteArr={deleteArr} />
      <div className={classes.pinkBlock}>
        <img className={classes.banner} src="/assets/2024/banner.png" />
        <p className={classes.text2}>
          <span>
            Все пакеты можно купить в рассрочку. Заполнение анкеты займет 2
            минуты.
          </span>
          <span>
            Сейчас вы ничего не платите - первый платеж будет только через
            месяц!
          </span>
          <span>
            Рассрочка полностью без переплат. Вы можете выбрать рассрочку на 6
            или 12 месяцев
          </span>
        </p>
        <div className={classes.cardsMedium}>
          {filterCards(CARDS_MEDIUM).map((card, index) => (
            <CardMedium data={card} key={index} />
          ))}
        </div>
        <div className={classes.cardsMedium2}>
          {filterCards(CARDS_MEDIUM2).map((card, index) => (
            <CardMedium data={card} key={index} isBig={true} />
          ))}
        </div>
      </div>
      <p className={classes.text}>
        Сделай любую покупку на этом сайте, и вам станет доступно{' '}
        <span>секретное предложение лично от Ольги Гогаладзе!</span>
      </p>
      <div className={classes.cardsSmall}>
        {filterCards(CARDS).map((card, index) => (
          <CardSmall data={card} key={index} />
        ))}
      </div>

      <div className={classes.blackBlock}>
        <img className={classes.banner} src="/assets/2024/banner2.png" />
        <p className={classes.superText}>
          <span>Эти продукты можно купить</span> только на этой Черной Пятнице,
          только на этом сайте прямо сейчас!
        </p>
        <div className={classes.cardsSuper}>
          {filterCards(CARDS_SUPER).map((card, index) => (
            <CardSmall data={card} key={index} />
          ))}
        </div>
        <h2 className={classes.not_russia}>
          для <span>нерезидентов</span>
        </h2>
        <div className={classes.cardsSuper}>
          {filterCards(CARDS_SUPER_NOT_RUSSIA).map((card, index) => (
            <CardSmall data={card} key={index} />
          ))}
        </div>
      </div>
      <Cart />
    </div>
  )
}

export { BlackFriday }
