export const CARDS_SUPER = [
  {
    key: 'nalog',
    id: '640371:14900',
    image: '/assets/2024/nalog.png',
    name: 'Персональная консультация по налогам',
    description: 'Оптимизируйте свои налоги вместе с налоговым консультантом',
    price: 14900,
    oldPrice: 30000,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Персональная консультация по налогам</h2>' +
      '<p>Получите персональную консультацию с налоговым консультантом и ответы на все ваши вопросы по налогам:\n</p>' +
      '<ul>' +
      '<li>Как оптимизировать налоговую нагрузку\n</li>' +
      '<li>Как и когда правильно платить налоги\n</li>' +
      '<li>Как заполнить налоговую декларацию\n</li>' +
      '<li>Как платить налоги от зарубежных инвестиций\n</li>' +
      '<li>Как получить налоговые вычеты и заработать на налогах\n</li>' +
      '<li>Как законно уменьшить налоги\n</li>' +
      '<p>+ Подарок курс «Как легко платить налоги от инвестиций»\n</p>' +
      '<p>Это поможет разобраться с налогами и больше не бояться их</p>' +
      '</ul>'
  },
  {
    key: 'portfolio',
    id: '640372:35000',
    image: '/assets/2024/portfolio.png',
    name: 'Персональная консультация по портфелю',
    description:
      'Повысьте доходность вашего портфеля на личной консультации с экспертом',
    price: 35000,
    oldPrice: 60000,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Персональная консультация по портфелю</h2>' +
      '<p>Задайте все интересующие вас вопросы по портфелю сертифицированному финансовому консультанту. Вам помогут навести порядок в своих инвестициях, подскажут слабые места портфеля, и как сделать его более устойчивым к влиянию рынка.</p>'
  },
  {
    key: 'kfp',
    id: '640370:7000',
    image: '/assets/2024/kfp.png',
    name: 'Доступ к конструктору финансового плана',
    description: 'Составьте пошаговый план достижения ваших целей',
    price: 7000,
    oldPrice: 70000,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Доступ к конструктору финансового плана</h2>' +
      '<p>Конструктор финансового плана создан pro.finansy и аккредитован в Минцифре. Конструктор финансового плана учитывает все ваши запросы, поэтому вы получаете индивидуальный план достижения цели. Вам остается только следовать ему.</p>'
  }
]

export const CARDS_SUPER_NOT_RUSSIA = [
  {
    key: 'europe',
    id: '640367:3500',
    image: '/assets/2024/europe.png',
    name: 'Курс Как инвестировать в Европе',
    description: 'Курс поможет резидентам Европы разобраться в фондовом рынке',
    price: 3500,
    oldPrice: 12990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как инвестировать в Европе</h2>' +
      '<p>Курс поможет резидентам Европы разобраться в особенностях отечественного фондового рынка.\n</p>' +
      '<ul>' +
      '<li>вы подберете подходящего брокера\n</li>' +
      '<li>откроете брокерский счет и начнёте зарабатывать на бирже\n</li>' +
      '<li>изучите нюансы налогообложения\n</li>' +
      '</ul>'
  },
  {
    key: 'kaz',
    id: '640365:3500',
    image: '/assets/2024/kaz.png',
    name: 'Курс Как инвестировать в Казахстане',
    description:
      'Курс поможет резидентам Казахстана разобраться в фондовом рынке',
    price: 3500,
    oldPrice: 12990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как инвестировать в Казахстане</h2>' +
      '<p>Курс поможет резидентам Казахстана разобраться в особенностях отечественного фондового рынка.\n</p>' +
      '<ul>' +
      '<li>вы подберете подходящего брокера\n</li>' +
      '<li>откроете брокерский счет и начнёте зарабатывать на бирже\n</li>' +
      '<li>изучите нюансы налогообложения\n</li>' +
      '</ul>'
  },
  {
    key: 'belarus',
    id: '640366:3500',
    image: '/assets/2024/belarus.png',
    name: 'Курс Как инвестировать в Беларуси',
    description:
      'Курс поможет резидентам Республики Беларусь разобраться в фондовом рынке',
    price: 3500,
    oldPrice: 12990,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      }
    ],
    descriptionModal:
      '<h2>Курс Как инвестировать в Беларуси</h2>' +
      '<p>Курс поможет резидентам Республики Беларусь разобраться в особенностях отечественного фондового рынка.\n</p>' +
      '<ul>' +
      '<li>вы подберете подходящего брокера\n</li>' +
      '<li>откроете брокерский счет и начнёте зарабатывать на бирже\n</li>' +
      '<li>изучите нюансы налогообложения\n</li>' +
      '</ul>'
  }
]
