import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 100
    },

    tabList: {
      display: 'flex',
      justifyContent: 'center',
      gap: 12,
      padding: 8,
      borderRadius: 8,
      backgroundColor: '#383838'
    },

    tab: {
      padding: '18px 72px',
      cursor: 'pointer',
      border: 'none',
      borderRadius: 8,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '22.5px',
      background: 'transparent',
      fontFamily: 'Russo One, sans-serif',
      textTransform: 'uppercase',
      color: 'rgba(255, 255, 255, 0.51)',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: '18px 30px'
      }
    },

    activeTab: {
      background: '#F7F6FB',
      color: '#383838'
    },

    cardList: {
      marginTop: 47,
      display: 'flex',
      gap: 30,
      [theme.breakpoints.down(850)]: {
        flexDirection: 'column'
      }
    }
  })
)
