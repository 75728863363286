import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import React from 'react'

import Button from '../UI/Button'

interface Props extends WithStyles<typeof style> {
  onClose: any
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontSize: 36,
      fontWeight: 800,
      marginBottom: 26
    },
    group: {
      marginBottom: 20
    },
    groupTitle: {
      marginBottom: 12,
      fontSize: 22,
      fontWeight: 700
    },
    groupItems: {
      marginBottom: 28
    },
    groupItem: {
      marginBottom: 12,
      display: 'flex'
    },
    groupCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#6553F5',
      marginTop: 7,
      marginRight: 12
    },
    groupItemText: {
      flex: 1,
      overflow: 'hidden',
      fontSize: 18
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10
    },
    subTitle: {
      fontSize: 18,
      color: '#59555C',
      lineHeight: '22px'
    }
  })

const Render = [
  {
    title: '1. Гарантия получения твёрдых навыков',
    subText:
      'На курсе вы получаете настоящую профессию - «Частный инвестор», а после экзамена - сертификат о дополнительном образовании'
  },
  {
    title: '2. Гарантия лучшей программы',
    subText:
      'Если вы найдете программу лучше, чем у нас, перешлите ее нам и мы дадим вам ту же информацию в рамках нашего курса, улучшив его'
  },
  {
    title: '3. Юридическая гарантия сделки',
    subText:
      'Наши продукты продаются только на официальном сайте. Оплата только через банк. Никаких переводов на карту. Обязательный чек. '
  },
  {
    title: '4. Гарантия лучшей цены',
    subText:
      'Если найдете программу с такими же составляющими как у нас, и дешевле, то мы вернем деньги. Это зафиксировано в договоре!'
  }
]

class Garant4 extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        <div className={classes.title}>Даём гарантии или возвращаем деньги</div>

        {Render.map((group, index) => {
          return (
            <div className={classes.group} key={index}>
              <div className={classes.groupTitle}>{group.title}</div>
              <div className={classes.subTitle}>{group.subText}</div>
            </div>
          )
        })}

        <div className={classes.button}>
          <Button onClick={this.props.onClose}>Выбрать тариф</Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(Garant4)
