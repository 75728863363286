import React from 'react'

import { IItemCart, addToCart } from '../../../../utils'

import { useComboStyles } from './Combo.styled'
import { COMBO } from './combo.const'

interface IProps {
  items: IItemCart[]
}

const Combo = ({ items }: IProps) => {
  const classes = useComboStyles()

  const handleClick = (item: (typeof COMBO)[0]) => {
    addToCart(
      {
        name: item.name,
        price: item.price,
        key: item.key,
        id: item.id
      },
      item.deleteCourses
    )
  }

  return (
    <div className={classes.container}>
      {COMBO.map((item, index: number) => {
        let render = true
        items.forEach((val) => {
          if (item.notShow.includes(val.key)) {
            render = false
          }
        })
        if (!render) return null
        return (
          <div className={classes.item} key={index}>
            <span>{item.name}</span>
            <button onClick={() => handleClick(item)}>{item.button}</button>
          </div>
        )
      })}
    </div>
  )
}

export { Combo }
