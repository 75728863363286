import React, { useEffect, useState } from 'react'

import { useTimerStyles } from './Timer.styled'

interface IProps {
  text?: string
  targetTime?: Date
}

const Timer = ({ text, targetTime }: IProps) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining())
  const classes = useTimerStyles()

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining())
    }, 1000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [targetTime])

  function calculateTimeRemaining() {
    const target = targetTime || new Date(2024, 10, 17, 21, 59, 59, 59)

    const moscow = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Moscow'
    })
    const now = new Date(moscow)

    const timeDifference = target.getTime() - now.getTime()

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      )
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
      return { days, hours, minutes, seconds }
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
  }

  return (
    <div className={classes.timer}>
      <img
        src="/assets/black-friday/clock_left.png"
        className={classes.clockLeft}
      />
      <img
        src="/assets/black-friday/clock_right.png"
        className={classes.clockRight}
      />
      <span className={classes.text}>{text || 'Скидки исчезнут через:'}</span>
      <span className={classes.time}>
        {`${String(timeRemaining.days).padStart(2, '0')}:${String(
          timeRemaining.hours
        ).padStart(2, '0')}:${String(timeRemaining.minutes).padStart(
          2,
          '0'
        )}:${String(timeRemaining.seconds).padStart(2, '0')}`}
      </span>
    </div>
  )
}

export { Timer }
