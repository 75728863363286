import { createContext } from 'react'

type SetFunc = React.Dispatch<
  React.SetStateAction<{
    ukey: any
    loading: number
    userMetadata: {
      user_id: number | null
      email: string | null
      phone: string | null
      balance?: number | null
      reserve?: number | null
      name?: string | null
    }
  }>
>

type Context = {
  onLogout: () => void
  setUserData: SetFunc
    setBalance: any
  ukey?: string
  userMetadata: {
    user_id: number | null
    email: string | null
    phone: string | null
    balance?: number | null
    reserve?: number | null
    name?: string | null
  }
}

export interface withUserContextProps {
  ukey: string
  user: any
  userName: string
  setUserData: SetFunc,
    setBalance: any
}

export const userContext = createContext<Context>({
  onLogout: () => null,
  setUserData: (userData) => null,
    setBalance: (balance: number) => null,
  ukey: null,
  userMetadata: {
    user_id: null,
    email: null,
    phone: null,
    reserve: null,
    balance: null,
    name: null
  }
})

export const withUserContext = <T extends {}>(
  Child: React.ComponentType<any>
) => {
  return (props: T) => {
    return (
      <userContext.Consumer>
        {({ ukey, userMetadata, setUserData, setBalance }) => {
          return (
            <Child
              ukey={ukey}
              user_id={userMetadata.user_id}
              userName={userMetadata.name}
              setUserData={setUserData}
              setBalance={setBalance}
              {...props}
            />
          )
        }}
      </userContext.Consumer>
    )
  }
}
