export const COMBO = [
  {
    key: '12_course',
    id: '640102:15000',
    price: 15000,
    name: 'Забираю все курсы за 15 000р.',
    button: 'Все курсы за 15 000р.',
    deleteCourses: [
      'tech_anal',
      'trading',
      'gold',
      'bond_salary',
      'economic',
      'oil',
      'foreign_brokers',
      'crypto',
      'ai',
      '11_top',
      'dividend_salary',
      'invest_ideas',
      '3_course',
      '5_course'
    ],
    notShow: ['combo_5', 'combo_6', '12_course']
  },
  {
    key: 'combo_5',
    id: '640104:1,640534:15000,269474:44989',
    price: 59900,
    name: 'Забираю все курсы + клуб навсегда + подписка на profinansy.ru навсегда',
    button: 'Все курсы + Комбо набор.',
    deleteCourses: [
      'tech_anal',
      'trading',
      'gold',
      'bond_salary',
      'economic',
      'oil',
      'foreign_brokers',
      'crypto',
      'ai',
      '11_top',
      'dividend_salary',
      'invest_ideas',
      '3_course',
      '5_course',
      '12_course'
    ],
    notShow: ['combo_5', 'combo_6']
  },
  {
    key: 'combo_6',
    id: '640104:1,640534:15000,269474:51421,437803:25443,326282:7078,326283:957',
    price: 99900,
    name: 'Забираю все и сразу (все курсы 12 шт + Метод + клуб навсегда + подписка на profinansy.ru навсегда)',
    button: 'Все и сразу',
    deleteCourses: [
      'tech_anal',
      'trading',
      'gold',
      'bond_salary',
      'economic',
      'oil',
      'foreign_brokers',
      'crypto',
      'ai',
      '11_top',
      'dividend_salary',
      'invest_ideas',
      '3_course',
      '5_course',
      '12_course',
      'combo_5'
    ],
    notShow: ['combo_6']
  }
]
