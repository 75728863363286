import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

import Button from '../UI/Button'

interface Props extends WithStyles<typeof style> {
  open: boolean
  link1: string
  link2: string
  onClose: any
  isBook?: boolean
  customIn?: string
  title?: string
  description?: string
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    link: {
      textDecoration: 'none'
    },
    buttonsPay: {},
    buttonPay: {
      marginBottom: 30,
      maxWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    },
    customHeight: {
      height: 'auto'
    },
    description: {
      marginBottom: 12,
      '& > span': {
        display: 'block',
        textAlign: 'center'
      }
    }
  })

class Allocation extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <Dialog open={this.props.open} fullWidth onClose={this.props.onClose}>
        <DialogTitle style={{ textAlign: 'center' }}>
          {this.props.title || 'Пожалуйста, выберите вариант оплаты подписки'}
        </DialogTitle>

        <DialogContent>
          {!!this.props.description && (
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          )}
          <div className={classes.buttonsPay}>
            <a
              href={this.props.link1}
              target={'_blank'}
              className={classes.link}
            >
              <Button className={classes.buttonPay}>
                Оплатить российской картой
              </Button>
            </a>

            <a
              href={this.props.link2}
              target={'_blank'}
              className={classes.link}
            >
              <Button
                className={cx(classes.buttonPay, {
                  [classes.customHeight]: !!this.props.customIn
                })}
              >
                {this.props.customIn
                  ? this.props.customIn
                  : 'Оплатить иностранной картой'}
              </Button>
            </a>

            {this.props.isBook ? (
              <div>
                *Минимальная сумма списания с иностранной карты 100 руб. Мы
                зачетем их тебе в счет оплаты доставки заграницу
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(style)(Allocation)
