export const SendMessage = (props: { onClick?: any; className?: string }) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2041 7.42783L3.69302 0.161739C2.95531 -0.144403 2.12188 -0.0097291 1.51803 0.513026C0.91418 1.03587 0.66162 1.84145 0.858988 2.61537L2.41763 8.72768H10.049C10.4001 8.72768 10.6849 9.01238 10.6849 9.36358C10.6849 9.71474 10.4002 9.99947 10.049 9.99947H2.41763L0.858988 16.1118C0.66162 16.8857 0.914138 17.6913 1.51803 18.2141C2.1231 18.7379 2.95662 18.8709 3.69307 18.5654L21.2042 11.2993C22.0015 10.9685 22.4967 10.2268 22.4967 9.36358C22.4967 8.50039 22.0015 7.7586 21.2041 7.42783Z"
        fill="#6553F5"
      />
    </svg>
  )
}
