import {
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import React from 'react'

import { getPoll } from '../../api/api'
import {
  withUserContext,
  withUserContextProps
} from '../../context/userContext'
import whileRequest from '../lib/whileRequest'

import Poll from './Poll'

interface Props extends WithStyles<typeof style>, withUserContextProps {
  data: Array<string>
  isAdmin: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {}
  })

class Polls extends React.Component<Props> {
  state = {
    polls: []
  }

  request: any
  timeout: any

  loading = false

  _load = () => {
    if (this.loading) return
    this.loading = true

    this.request = whileRequest(
      getPoll,
      {
        polls: this.props.data
      },
      this.props.ukey
    )

    this.request.then((data) => {
      this.loading = false

      this.setState({
        polls: data.data
      })

      this.timeout = setTimeout(() => {
        this._load()
      }, 30000)
    })
  }

  componentDidMount() {
    this._load()
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort()
    }

    clearTimeout(this.timeout)
  }

  setMore = (id, index) => {
    const newState = this.state.polls

    const findIndex = newState.findIndex((el) => {
      return el.id === id
    })

    if (findIndex !== -1) {
      newState[findIndex].totalCount++
      newState[findIndex].variants[index].count++

      newState[findIndex].variants.forEach((el) => {
        el.percent = +(el.count / newState[findIndex].totalCount).toFixed(3)
      })

      if (this.request) {
        this.request.abort()
      }

      clearTimeout(this.timeout)
      this.loading = false

      this.timeout = setTimeout(() => {
        this._load()
      }, 10000)

      this.forceUpdate()
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        <Grid container spacing={this.props.isAdmin ? 0 : 4}>
          {this.state.polls.map((el) => {
            return (
              <Grid item xs={12} md={this.props.isAdmin ? 12 : 4} key={el.id}>
                <Poll
                  info={el}
                  resetPolls={this.setMore}
                  isAdmin={this.props.isAdmin}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(Polls)) as any
