import React from 'react'

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const MainContext = React.createContext({
  user: null,
  reAuth: null
})

export interface withMainContextProps {
  user: any
  reAuth: any
}

export const withMainContext = <T extends {}>(
  Child: React.ComponentType<any>
) => {
  return (props: T) => {
    return (
      <MainContext.Consumer>
        {({ user, reAuth }) => {
          return <Child user={user} reAuth={reAuth} {...props} />
        }}
      </MainContext.Consumer>
    )
  }
}

export const declOfNum = (number: number, titles: Array<any>) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ]
}

export const GLOBAL_STORE = {}
