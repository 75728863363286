import React, { useEffect, useState } from 'react'

function Timer({ date, color, fontSize }) {
  const [finishTime] = useState(date.getTime())
  const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0])
  const [tick, setTick] = useState(false)
  const [isTimeout, setIsTimeout] = useState(false)
  const [timerId, setTimerID] = useState(0)

  useEffect(() => {
    const diff =
      (finishTime -
        new Date() -
        new Date().getTimezoneOffset() * 60 * 1000 -
        180 * 60 * 1000) /
      1000
    if (diff < 0) {
      setIsTimeout(true)
      return
    }
    setDiff([
      Math.floor(diff / 86400), // дни
      Math.floor((diff / 3600) % 24),
      Math.floor((diff / 60) % 60),
      Math.floor(diff % 60)
    ])
  }, [tick, finishTime])
  useEffect(() => {
    if (isTimeout) clearInterval(timerId)
  }, [isTimeout, timerId])

  useEffect(() => {
    const timerID = setInterval(() => {
      setTick(!tick)
    }, 1000)
    setTimerID(timerID)
    return () => clearInterval(timerID)
  }, [tick])

  return (
    <div
      style={{
        fontWeight: '700',
        fontSize: fontSize,
        textAlign: 'center',
        color: color
      }}
    >{`${diffDays}:${diffH.toString().padStart(2, '0')}:${diffM
      .toString()
      .padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}</div>
  )
}

export default Timer
