import React from 'react';
import {createStyles, InputBase, Theme, withStyles, WithStyles} from "@material-ui/core";
import Button from "../UI/Button";
import {authApi} from "../../api/auth.api";
import {withUserContext, withUserContextProps} from "../../context/userContext";
import {WithSnackbarProps, withSnackbar} from 'notistack';
import {BadIconCode, SuccessIconCode} from "../../icons/ActivePoll";

interface Props extends WithStyles<typeof style>, withUserContextProps, WithSnackbarProps {
    data: Array<string>
}

const style = (theme: Theme) => createStyles({
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    infoBlock: {

    },
    title: {
        fontWeight: 700,
        fontSize: 32,
        color: '#F8F8F8'
    },
    subTitle: {
        fontWeight: 500,
        color: '#AA9FFF',
        fontSize: 17
    },
    inputWrapper: {
        backgroundColor: '#181818',
        border: '1px solid #616161',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
        width: 300,
        maxWidth: 300,
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: 20,
            marginBottom: 20
        }
    },
    input: {
        color: '#fff',
        height: '100%',
        padding: 16,
        '& input': {
            fontSize: 16
        }
    },
    inputProps: {
        padding: 0
    },
    noti: {
        display: 'flex',
        padding: 20,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginLeft: 0
        }
    },
    icon: {
        marginRight: 16
    },
    textBlock: {

    },
    textTitle: {
        fontSize: 16,
        fontWeight: 700
    },
    textSubTitle: {
        fontSize: 10
    },
    again: {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
});

class Answers extends React.Component<Props> {

    state = {
        value: '',
        loading: 0
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        });
    }

    onSave = () => {

        if ( this.props.data[2].match( new RegExp(this.state.value.trim(), 'i') ) ) {
            authApi.AddBalance({
                ukey: this.props.ukey,
                //@ts-ignore
                event_num: +this.props.data[4]
            }).then((data: any) => {
                if (data.ok) {
                    this.props.setBalance(data.balance);
                    this.setState({
                        loading: 3
                    });
                } else {
                    this.props.enqueueSnackbar('Произошла неизвестная ошибка, повторите действие', {
                        variant: 'error'
                    });
                }
            })
        } else {
            this.setState({
                loading: 2
            });
        }

        /*authApi.AddBalance({
            ukey: this.props.ukey,
            //@ts-ignore
            event_num: 2
        })*/

        //this.props.setBalance(100);
    }

    again = () => {
        this.setState({
            loading: 0
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.box}>
                <div className={classes.infoBlock}>
                    <div className={classes.title}>{this.props.data[0]}</div>
                    {
                        this.props.data[1] ? <div className={classes.subTitle}>
                                <div>{this.props.data[1]}</div>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    this.state.loading === 0 ?
                        <>
                            <div className={classes.inputWrapper}>
                                <InputBase
                                    fullWidth
                                    className={classes.input}
                                    value={this.state.value}
                                    placeholder={'Введите кодовое слово'}
                                    onChange={this.onChange}
                                    inputProps={{
                                        className: classes.inputProps
                                    }}
                                />
                            </div>

                            <Button onClick={this.onSave}>Отправить</Button>
                        </>
                        :
                        null
                }

                {
                    (this.state.loading === 2 || this.state.loading === 3) ?
                        <div className={classes.noti} style={{
                            backgroundColor: this.state.loading === 3 ? '#74C991' : '#DF3E3F'
                        }}>
                            <div className={classes.icon}>
                                {
                                    this.state.loading === 2 ? <BadIconCode/> : <SuccessIconCode/>
                                }
                            </div>
                            <div className={classes.textBlock}>
                                <div className={classes.textTitle}>
                                    {
                                        this.state.loading === 2 ? 'Задание выполнено неверно!' : 'Задание выполнено успешно!'
                                    }
                                </div>

                                {
                                    this.state.loading === 3 ? <div className={classes.textSubTitle}>
                                        {this.props.data[1]}
                                    </div> : <div className={classes.again} onClick={this.again}>
                                        Попробовать еще раз
                                    </div>
                                }
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

export default withSnackbar(withUserContext(withStyles(style)(Answers))) as any;