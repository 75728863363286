import { createStyles, makeStyles } from '@material-ui/core'

export const useAuthStyles = makeStyles((theme) =>
  createStyles({
      inputProps: {
          padding: 0
      },
      image: {
          maxHeight: 400,
          marginTop: 20
      },
    box: {
      positive: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 500,
      [theme.breakpoints.down('sm')]: {
        minHeight: 600
      }
    },

    authBlock: {
      padding: '70px 52px',
      width: 686,
      borderRadius: 16,
      background: '#000000',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0
      },
      [theme.breakpoints.down('sm')]: {
        padding: '70px 30px'
      }
    },

    title: {
      fontSize: 32,
      color: '#fff',
      fontWeight: 700,
      lineHeight: '31.47px',
        textAlign: 'center'
    },

    emailTitle: {
      fontSize: 26
    },

    buttons: {
      marginTop: 'auto',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 21
    },

    filledButton: {
      background: '#6553F5',
      color: '#fff',
      borderRadius: 8,
      width: '100%',
      padding: '12px 0',
      '&:hover': {
        background: '#6553F5'
      }
    },

    outlinedButton: {
      padding: '20px 0',
      border: '3px solid #6553F5'
    },

    description: {
      marginTop: 9,
      fontSize: 16,
      lineHeight: '19px',
      color: '#999999',
        textAlign: 'center'
    },

    btn: {
      textTransform: 'none',
      fontSize: 20,
      fontWeight: 600,
      color: '#fff'
    },

    inputsBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 14,
      width: '100%',
      margin: '34px 0'
    },

    input: {
      color: '#878787',
      background: '#181818',
      border: '1px solid #616161',
      width: '100%',
      padding: '20px 24px',
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },

    moneyTitle: {
      color: '#AA9FFF',
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '36.31px',
      marginTop: 23
    },

    modal: {
      '& .MuiDialog-paperScrollPaper': {
        borderRadius: 12,
        background: '#000',
        maxWidth: 644,
        width: '100%',
        padding: 24,
        [theme.breakpoints.down('xs')]: {
          margin: 0,
          borderRadius: 0
        }
      }
    },

    nameTitle: {
      fontSize: 20,
      marginBottom: 20,
      textAlign: 'center'
    }
  })
)
