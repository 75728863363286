import { Dialog, DialogContent } from '@material-ui/core'
import QueryString from 'qs'
import React, { useEffect, useMemo, useState } from 'react'

import { getLocalStringNumber } from '../../../../utils/getLocalStringNamber'
import { cartKey, eventCart, removeFromCart } from '../../utils'

import { useCartStyles } from './Cart.styled'
import { Combo } from './components/Combo/Combo'

const Cart = () => {
  const [items, setItems] = useState([])
  const [isOpen, setIsOpen] = React.useState(false)

  const classes = useCartStyles()

  useEffect(() => {
    const getItems = () => {
      const cart = JSON.parse(localStorage.getItem(cartKey) || '[]')
      setItems(cart)
    }
    getItems()
    const onStorageUpdate = () => {
      getItems()
      setIsOpen(true)
    }
    window.addEventListener(eventCart, onStorageUpdate)
    return () => {
      window.removeEventListener(eventCart, onStorageUpdate)
    }
  }, [])

  const getSum = () => {
    let sum = 0
    items.forEach((item) => {
      sum += item.price * item.quantity
    })
    return sum
  }

  const quantity = useMemo(() => {
    let sum = 0
    items.forEach((item) => {
      sum += item.quantity
    })
    return sum
  }, [items])

  const getQueries = () => {
    const queries = QueryString.parse(window.location.search || '', {
      ignoreQueryPrefix: true
    }) as any
    let queriesString = ''
    Object.keys(queries).forEach((key) => {
      queriesString += `&${key}=${queries[key]}`
    })
    return queriesString
  }

  const handlePay = (frame: string) => {
    const products = []
    items.forEach((item) => {
      const ids = item.id.split(',')
      ids.forEach((id) => {
        const split = id.split(':')
        const btx_id = split[0]
        const price = split[1]
        products.push(`${btx_id}:1-${price}`)
      })
    })
    const productsString = products.join(',')
    fetch(
      'https://vm.test.profinansy.ru/tin-ras-payment-html-form-check-sign?products=' +
        encodeURIComponent(productsString)
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          const url =
            `${frame}?products=${productsString}&products_sign=${data.sign}` +
            getQueries()
          window.location.href = url
        }
      })
  }

  return (
    <div>
      <button className={classes.cart} onClick={() => setIsOpen(true)}>
        <img src="/assets/2024/shop.svg" />
        {!!quantity && (
          <div className={classes.countItems}>
            {getLocalStringNumber(quantity)}
          </div>
        )}
      </button>
      <Dialog
        maxWidth="sm"
        open={isOpen}
        fullWidth
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          <h3 className={classes.title}>Ваш заказ</h3>
          <div className={classes.items}>
            {items.map((item, index) => (
              <div key={index} className={classes.item}>
                <span>{item.name}</span>
                <div className={classes.right}>
                  <div className={classes.price}>
                    {getLocalStringNumber(item.quantity * item.price)}&nbsp;р.
                    <button
                      className={classes.delete}
                      onClick={() => removeFromCart(item.key)}
                    >
                      <img src="/assets/2024/trash.svg" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Combo items={items} />
          <div className={classes.bottom}>
            <span>ИТОГО</span>
            <span>{getLocalStringNumber(getSum())}&nbsp;р.</span>
          </div>

          <div className={classes.buttons}>
            <button
              className={classes.button}
              onClick={() => handlePay('https://payment.profinansy.ru/2848')}
            >
              Оплатить
            </button>
            <button
              className={classes.buttonTinkoff}
              onClick={() =>
                handlePay('https://promopay.profinansy.ru/frame/2848')
              }
            >
              <img src="/assets/2024/tinkoff.svg" alt="tinkoff" />
              <span className={classes.tinkoffText}>Оформить в рассрочку</span>
            </button>
            <button
              className={classes.buttonSber}
              onClick={() =>
                handlePay('https://promopay.profinansy.ru/frame-3/2848')
              }
            >
              <img src="/assets/black-friday/sber.svg" alt="sber" />В рассрочку
            </button>
            <button
              className={classes.buttonOther}
              onClick={() =>
                handlePay('https://promopay.profinansy.ru/frame-2/2848')
              }
            >
              Рассрочка других банков
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export { Cart }
