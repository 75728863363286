import Garant4 from '../Dialogs/Garant4'
import Garant7 from '../Dialogs/Garant7'
import Modules11 from '../Dialogs/Modules11'
import Modules_seconds from '../Dialogs/Modules_seconds'
import { Vector1, Vector2, Vector3 } from '../../icons/ActivePoll'

export const Plans = {
  metod_1_first: {
    icon: Vector1,
    title: 'ВСЁ САМ',
    subTitle: (
      <div>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#9669E3' }}>3 месяца</span>
      </div>
    ),
    isBasic: true,

    marketingPrice: 19600,
    userPrice: 9800,

    blocks: [
      {
        type: 'dialog',
        title: '11 обучающих модулей',
        contentDialog: Modules11
      },
      {
        type: 'niceBlock',
        title: 'Входит в тариф'
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15 }}>
              ✅ Доступ к материалам курса 3 месяца
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ Учебник с бесплатной доставкой по России
            </div>
            <div style={{ marginBottom: 15 }}>✅ Дополнительные материалы</div>
            <div>✅ Живые вебинары с экспертами и Ольгой Гогаладзе </div>
          </>
        )
      },
      {
        type: 'dialog',
        title: '4 гарантии по договору',
        contentDialog: Garant4
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/20',
        'https://profinansy.store/page30847827.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/19',
        'https://profinansy.store/page30849906.html'
      ],
      '0': [
        'https://payment.profinansy.ru/18',
        'https://profinansy.store/page30849884.html'
      ],
      '120': [
        'https://payment.profinansy.ru/21',
        'https://profinansy.store/page30850866.html'
      ],
      '180': [
        'https://payment.profinansy.ru/22',
        'https://profinansy.store/page30850879.html'
      ],
      '216': [
        'https://payment.profinansy.ru/23',
        'https://profinansy.store/page30851296.html'
      ],
      '238': [
        'https://payment.profinansy.ru/25',
        'https://profinansy.store/page30862061.html'
      ],
      '274': [
        'https://payment.profinansy.ru/26',
        'https://profinansy.store/page30862079.html'
      ],
      '315': [
        'https://payment.profinansy.ru/27',
        'https://profinansy.store/page30884683.html'
      ],
      '600': [
        'https://payment.profinansy.ru/28',
        'https://profinansy.store/page30884724.html'
      ],
      '660': [
        'https://payment.profinansy.ru/29',
        'https://profinansy.store/page30885102.html'
      ],
      '727': [
        'https://payment.profinansy.ru/30',
        'https://profinansy.store/page30885115.html'
      ],
      '799': [
        'https://payment.profinansy.ru/31',
        'https://profinansy.store/page30885172.html'
      ],
      '919': [
        'https://payment.profinansy.ru/32',
        'https://profinansy.store/page30885201.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/33',
        'https://profinansy.store/page30885227.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/34',
        'https://profinansy.store/page30885239.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/35',
        'https://profinansy.store/page30885247.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/36',
        'https://profinansy.store/page30885264.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/37',
        'https://profinansy.store/page30885278.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/38',
        'https://profinansy.store/page30885281.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/39',
        'https://profinansy.store/page30885307.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/39',
        'https://profinansy.store/page30885307.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/40',
        'https://profinansy.store/page30885311.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/41',
        'https://profinansy.store/page30885315.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/42',
        'https://profinansy.store/page30885319.html'
      ]
    }
  },
  metod_1_second: {
    icon: Vector2,
    title: 'С ФИНАНСОВЫМ КОНСУЛЬТАНТОМ',
    subTitle: (
      <div style={{ color: '#fff' }}>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#FED788' }}>6 месяцев</span>
      </div>
    ),
    isBasic: false,

    marketingPrice: 31600,
    userPrice: 15800,
    blocks: [
      {
        type: 'dialog',
        title: '11 обучающих модулей',
        contentDialog: Modules11
      },
      {
        type: 'niceBlock',
        title: (
          <div>
            Все, что входит в тариф{' '}
            <span style={{ fontWeight: 700 }}>«Всё сам»</span>
          </div>
        )
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Бессрочный доступ к материалам курса{' '}
            </div>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Ответы на вопросы и разборы по обучению в закрытом чате с
              экспертом
            </div>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Проверка экзаменационной работы по заработку на инвестициях с
              рекомендациями эксперта на 20+ страниц PDF
            </div>
          </>
        )
      },
      {
        type: 'dialog',
        title: '7 гарантии по договору',
        contentDialog: Garant7
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/20',
        'https://profinansy.store/page30847827.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/77',
        'https://profinansy.store/page30885334.html'
      ],
      '0': [
        'https://payment.profinansy.ru/43',
        'https://profinansy.store/page30885327.html'
      ],
      '120': [
        'https://payment.profinansy.ru/73',
        'https://profinansy.store/page30885364.html'
      ],
      '180': [
        'https://payment.profinansy.ru/74',
        'https://profinansy.store/page30885367.html'
      ],
      '216': [
        'https://payment.profinansy.ru/75',
        'https://profinansy.store/page30885378.html'
      ],
      '238': [
        'https://payment.profinansy.ru/78',
        'https://profinansy.store/page30885382.html'
      ],
      '274': [
        'https://payment.profinansy.ru/79',
        'https://profinansy.store/page30885386.html'
      ],
      '315': [
        'https://payment.profinansy.ru/80',
        'https://profinansy.store/page30885390.html'
      ],
      '600': [
        'https://payment.profinansy.ru/81',
        'https://profinansy.store/page30885394.html'
      ],
      '660': [
        'https://payment.profinansy.ru/82',
        'https://profinansy.store/page30885402.html'
      ],
      '727': [
        'https://payment.profinansy.ru/84',
        'https://profinansy.store/page30885405.html'
      ],
      '799': [
        'https://payment.profinansy.ru/85',
        'https://profinansy.store/page30885418.html'
      ],
      '919': [
        'https://payment.profinansy.ru/86',
        'https://profinansy.store/page30885424.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/87',
        'https://profinansy.store/page30885436.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/88',
        'https://profinansy.store/page30885439.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/90',
        'https://profinansy.store/page30885442.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/91',
        'https://profinansy.store/page30885444.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/92',
        'https://profinansy.store/page30885480.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/93',
        'https://profinansy.store/page30885488.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/95',
        'https://profinansy.store/page30885491.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/95',
        'https://profinansy.store/page30885491.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/96',
        'https://profinansy.store/page30885495.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/97',
        'https://profinansy.store/page30885498.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/98',
        'https://profinansy.store/page30885515.html'
      ]
    }
  },
  metod_1_third: {
    icon: Vector3,
    title: 'ВСЕ ВКЛЮЧЕНО',
    subTitle: (
      <div>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#9669E3' }}>12 месяцев</span>
      </div>
    ),
    isBasic: true,

    showDivider: true,

    marketingPrice: 96000,
    userPrice: 48000,

    blocks: [
      {
        type: 'dialog',
        title: '11 обучающих модулей',
        contentDialog: Modules11
      },
      {
        type: 'niceBlock',
        title: (
          <div>
            Все, что входит в тариф
            <br />
            <span style={{ fontWeight: 700 }}>
              «С финансовым консультантом»
            </span>
          </div>
        )
      },
      {
        type: 'dialog',
        title: '7 гарантии по договору',
        contentDialog: Garant7
      },
      {
        type: 'niceBlockBig',
        title: <div>Дополнительно</div>
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15 }}>
              ✅ Регулярные рекомендации от карьерного консультанта и
              финансового психолога «Как пробить финансовый потолок»
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ 1 групповая сессия- разбор с карьерным консультантом
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ 1 групповая сессия-разбор с финансовым психологом
            </div>
          </>
        )
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/20',
        'https://profinansy.store/page30847827.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/101',
        'https://profinansy.store/page30885534.html'
      ],
      '0': [
        'https://payment.profinansy.ru/99',
        'https://profinansy.store/page30885529.html'
      ],
      '120': [
        'https://payment.profinansy.ru/102',
        'https://profinansy.store/page30885552.html'
      ],
      '180': [
        'https://payment.profinansy.ru/103',
        'https://profinansy.store/page30885566.html'
      ],
      '216': [
        'https://payment.profinansy.ru/105',
        'https://profinansy.store/page30885568.html'
      ],
      '238': [
        'https://payment.profinansy.ru/106',
        'https://profinansy.store/page30885573.html'
      ],
      '274': [
        'https://payment.profinansy.ru/107',
        'https://profinansy.store/page30885579.html'
      ],
      '315': [
        'https://payment.profinansy.ru/108',
        'https://profinansy.store/page30885582.html'
      ],
      '600': [
        'https://payment.profinansy.ru/109',
        'https://profinansy.store/page30885590.html'
      ],
      '660': [
        'https://payment.profinansy.ru/110',
        'https://profinansy.store/page30885609.html'
      ],
      '727': [
        'https://payment.profinansy.ru/111',
        'https://profinansy.store/page30885612.html'
      ],
      '799': [
        'https://payment.profinansy.ru/112',
        'https://profinansy.store/page30886007.html'
      ],
      '919': [
        'https://payment.profinansy.ru/113',
        'https://profinansy.store/page30886011.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/114',
        'https://profinansy.store/page30885615.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/115',
        'https://profinansy.store/page30885634.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/116',
        'https://profinansy.store/page30885726.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/117',
        'https://profinansy.store/page30885886.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/118',
        'https://profinansy.store/page30885890.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/119',
        'https://profinansy.store/page30885898.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/120',
        'https://profinansy.store/page30885900.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/120',
        'https://profinansy.store/page30885900.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/121',
        'https://profinansy.store/page30885903.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/122',
        'https://profinansy.store/page30885911.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],

      '213916': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/123',
        'https://profinansy.store/page30885914.html'
      ]
    }
  },

  metod_2_first: {
    icon: Vector1,

    title: 'ВСЁ САМ',
    subTitle: (
      <div>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#9669E3' }}>3 месяца</span>
      </div>
    ),
    isBasic: true,

    marketingPrice: 32680,
    userPrice: 9800,

    blocks: [
      {
        type: 'dialog',
        title: '5 обучающих модулей',
        contentDialog: Modules_seconds
      },
      {
        type: 'niceBlock',
        title: 'Входит в тариф'
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15 }}>
              ✅ Доступ к материалам курса 3 месяца
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ Учебник с бесплатной доставкой по России
            </div>
            <div>✅ Живые вебинары с экспертами и Ольгой Гогаладзе </div>
          </>
        )
      },
      {
        type: 'dialog',
        title: '4 гарантии по договору',
        contentDialog: Garant4
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/45',
        'https://profinansy.store/page30886171.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/124',
        'https://profinansy.store/page30886177.html'
      ],
      '0': [
        'https://payment.profinansy.ru/47',
        'https://profinansy.store/page30886174.html'
      ],
      '120': [
        'https://payment.profinansy.ru/49',
        'https://profinansy.store/page30886186.html'
      ],
      '180': [
        'https://payment.profinansy.ru/50',
        'https://profinansy.store/page30886188.html'
      ],
      '216': [
        'https://payment.profinansy.ru/51',
        'https://profinansy.store/page30886191.html'
      ],
      '238': [
        'https://payment.profinansy.ru/52',
        'https://profinansy.store/page30886194.html'
      ],
      '274': [
        'https://payment.profinansy.ru/53',
        'https://profinansy.store/page30886200.html'
      ],
      '315': [
        'https://payment.profinansy.ru/54',
        'https://profinansy.store/page30886199.html'
      ],
      '600': [
        'https://payment.profinansy.ru/55',
        'https://profinansy.store/page30886216.html'
      ],
      '660': [
        'https://payment.profinansy.ru/56',
        'https://profinansy.store/page30886218.html'
      ],
      '727': [
        'https://payment.profinansy.ru/57',
        'https://profinansy.store/page30886220.html'
      ],
      '799': [
        'https://payment.profinansy.ru/58',
        'https://profinansy.store/page30886223.html'
      ],
      '919': [
        'https://payment.profinansy.ru/59',
        'https://profinansy.store/page30886225.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/60',
        'https://profinansy.store/page30886279.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/61',
        'https://profinansy.store/page30886227.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/62',
        'https://profinansy.store/page30886229.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/63',
        'https://profinansy.store/page30886232.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/64',
        'https://profinansy.store/page30886235.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/65',
        'https://profinansy.store/page30886238.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/67',
        'https://profinansy.store/page30886242.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/67',
        'https://profinansy.store/page30886242.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/68',
        'https://profinansy.store/page30886250.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/69',
        'https://profinansy.store/page30886257.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/71',
        'https://profinansy.store/page30886259.html'
      ]
    }
  },
  metod_2_second: {
    icon: Vector2,
    title: 'С ФИНАНСОВЫМ КОНСУЛЬТАНТОМ',
    subTitle: (
      <div style={{ color: '#fff' }}>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#FED788' }}>6 месяцев</span>
      </div>
    ),
    isBasic: false,

    marketingPrice: 52600,
    userPrice: 15800,

    blocks: [
      {
        type: 'dialog',
        title: '5 обучающих модулей',
        contentDialog: Modules_seconds
      },
      {
        type: 'niceBlock',
        title: (
          <div>
            Все, что входит в тариф{' '}
            <span style={{ fontWeight: 700 }}>«Всё сам»</span>
          </div>
        )
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Бессрочный доступ к материалам курса{' '}
            </div>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Ответы на вопросы и разборы по обучению в закрытом чате с
              экспертом в малых группах до 50 человек
            </div>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Персональный разбор инвестиционных идей в вашем портфеле
            </div>
            <div style={{ marginBottom: 15, color: '#fff' }}>
              ✅ Детальный анализ выбранных 3-х тактических идей, которые
              повысят доходность вашего портфеля, от сертифицированного
              финансового консультанта
            </div>
          </>
        )
      },
      {
        type: 'dialog',
        title: '7 гарантии по договору',
        contentDialog: Garant7
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/45',
        'https://profinansy.store/page30886171.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/130',
        'https://profinansy.store/page30886308.html'
      ],
      '0': [
        'https://payment.profinansy.ru/129',
        'https://profinansy.store/page30886306.html'
      ],
      '120': [
        'https://payment.profinansy.ru/132',
        'https://profinansy.store/page30886318.html'
      ],
      '180': [
        'https://payment.profinansy.ru/133',
        'https://profinansy.store/page30886320.html'
      ],
      '216': [
        'https://payment.profinansy.ru/134',
        'https://profinansy.store/page30886322.html'
      ],
      '238': [
        'https://payment.profinansy.ru/135',
        'https://profinansy.store/page30903073.html'
      ],
      '274': [
        'https://payment.profinansy.ru/136',
        'https://profinansy.store/page30903097.html'
      ],
      '315': [
        'https://payment.profinansy.ru/137',
        'https://profinansy.store/page30903180.html'
      ],
      '600': [
        'https://payment.profinansy.ru/138',
        'https://profinansy.store/page30903194.html'
      ],
      '660': [
        'https://payment.profinansy.ru/139',
        'https://profinansy.store/page30903216.html'
      ],
      '727': [
        'https://payment.profinansy.ru/140',
        'https://profinansy.store/page30903234.html'
      ],
      '799': [
        'https://payment.profinansy.ru/141',
        'https://profinansy.store/page30903254.html'
      ],
      '919': [
        'https://payment.profinansy.ru/142',
        'https://profinansy.store/page30903274.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/143',
        'https://profinansy.store/page30903285.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/144',
        'https://profinansy.store/page30903305.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/146',
        'https://profinansy.store/page30903324.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/147',
        'https://profinansy.store/page30903340.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/148',
        'https://profinansy.store/page30903360.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/149',
        'https://profinansy.store/page30903397.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/150',
        'https://profinansy.store/page30903435.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/150',
        'https://profinansy.store/page30903435.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/151',
        'https://profinansy.store/page30903454.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/152',
        'https://profinansy.store/page30903474.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/153',
        'https://profinansy.store/page30903494.html'
      ]
    }
  },
  metod_2_third: {
    icon: Vector3,

    title: 'ВСЕ ВКЛЮЧЕНО',
    subTitle: (
      <div>
        🔥 Доступ к платформе
        <br />и приложению pro.finansy -{' '}
        <span style={{ color: '#9669E3' }}>12 месяцев</span>
      </div>
    ),
    isBasic: true,

    marketingPrice: 160000,
    userPrice: 48000,

    showDivider: true,

    blocks: [
      {
        type: 'dialog',
        title: '5 обучающих модулей',
        contentDialog: Modules_seconds
      },
      {
        type: 'niceBlock',
        title: (
          <div>
            Все, что входит в тариф
            <br />
            <span style={{ fontWeight: 700 }}>
              «С финансовым консультантом»
            </span>
          </div>
        )
      },
      {
        type: 'dialog',
        title: '7 гарантии по договору',
        contentDialog: Garant7
      },
      {
        type: 'niceBlockBig',
        title: <div>Дополнительно</div>
      },
      {
        type: 'subBlock',
        block: (
          <>
            <div style={{ marginBottom: 15 }}>
              ✅ Регулярные рекомендации от карьерного консультанта и
              финансового психолога «Как пробить финансовый потолок»
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ 1 групповая сессия- разбор с карьерным консультантом
            </div>
            <div style={{ marginBottom: 15 }}>
              ✅ 1 групповая сессия-разбор с финансовым психологом
            </div>
          </>
        )
      }
    ],

    balances: {
      bron: [
        'https://payment.profinansy.ru/45',
        'https://profinansy.store/page30886171.html'
      ],
      firstPay: [
        'https://payment.profinansy.ru/126',
        'https://profinansy.store/page30904086.html'
      ],
      '0': [
        'https://payment.profinansy.ru/125',
        'https://profinansy.store/page30904066.html'
      ],
      '120': [
        'https://payment.profinansy.ru/127',
        'https://profinansy.store/page30904130.html'
      ],
      '180': [
        'https://payment.profinansy.ru/128',
        'https://profinansy.store/page30904150.html'
      ],
      '216': [
        'https://payment.profinansy.ru/154',
        'https://profinansy.store/page30904172.html'
      ],
      '238': [
        'https://payment.profinansy.ru/155',
        'https://profinansy.store/page30904228.html'
      ],
      '274': [
        'https://payment.profinansy.ru/156',
        'https://profinansy.store/page30904247.html'
      ],
      '315': [
        'https://payment.profinansy.ru/157',
        'https://profinansy.store/page30904265.html'
      ],
      '600': [
        'https://payment.profinansy.ru/158',
        'https://profinansy.store/page30904293.html'
      ],
      '660': [
        'https://payment.profinansy.ru/159',
        'https://profinansy.store/page30904326.html'
      ],
      '727': [
        'https://payment.profinansy.ru/160',
        'https://profinansy.store/page30904354.html'
      ],
      '799': [
        'https://payment.profinansy.ru/161',
        'https://profinansy.store/page30904367.html'
      ],
      '919': [
        'https://payment.profinansy.ru/162',
        'https://profinansy.store/page30904386.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/163',
        'https://profinansy.store/page30904396.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/164',
        'https://profinansy.store/page30904421.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/166',
        'https://profinansy.store/page30904435.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/167',
        'https://profinansy.store/page30904452.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/170',
        'https://profinansy.store/page30904464.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/171',
        'https://profinansy.store/page30904475.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/172',
        'https://profinansy.store/page30904497.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/172',
        'https://profinansy.store/page30904497.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/173',
        'https://profinansy.store/page30904532.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/174',
        'https://profinansy.store/page30904548.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],

      '213916': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],

      '282904': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/175',
        'https://profinansy.store/page30904564.html'
      ]
    }
  },

  platform_first: {
    icon: Vector1,
    title: 'ВСЁ САМ',
    subTitle: '6 месяцев',
    isBasic: true,

    sale: '47%',

    subTitleSub: 'Оплата подписки: раз в полгода',

    marketingPrice: 8800,
    userPrice: 4740,

    monthPrice: 790,
    economySum: 8000,

    platformTariff: true,

    balances: {
      '0': [
        'https://payment.profinansy.ru/189',
        'https://profinansy.store/page30918219.html'
      ],
      '120': [
        'https://payment.profinansy.ru/190',
        'https://profinansy.store/page30918281.html'
      ],
      '180': [
        'https://payment.profinansy.ru/191',
        'https://profinansy.store/page30918291.html'
      ],
      '216': [
        'https://payment.profinansy.ru/192',
        'https://profinansy.store/page30918300.html'
      ],
      '238': [
        'https://payment.profinansy.ru/193',
        'https://profinansy.store/page30918311.html'
      ],
      '274': [
        'https://payment.profinansy.ru/194',
        'https://profinansy.store/page30918318.html'
      ],
      '315': [
        'https://payment.profinansy.ru/195',
        'https://profinansy.store/page30918328.html'
      ],
      '600': [
        'https://payment.profinansy.ru/196',
        'https://profinansy.store/page30918338.html'
      ],
      '660': [
        'https://payment.profinansy.ru/197',
        'https://profinansy.store/page30918348.html'
      ],
      '727': [
        'https://payment.profinansy.ru/198',
        'https://profinansy.store/page30918365.html'
      ],
      '799': [
        'https://payment.profinansy.ru/199',
        'https://profinansy.store/page30918389.html'
      ],
      '919': [
        'https://payment.profinansy.ru/200',
        'https://profinansy.store/page30918400.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/201',
        'https://profinansy.store/page30918418.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/202',
        'https://profinansy.store/page30918431.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/203',
        'https://profinansy.store/page30918441.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/204',
        'https://profinansy.store/page30918454.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/205',
        'https://profinansy.store/page30918466.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/206',
        'https://profinansy.store/page30918491.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/207',
        'https://profinansy.store/page30918500.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/207',
        'https://profinansy.store/page30918500.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/208',
        'https://profinansy.store/page30918511.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/209',
        'https://profinansy.store/page30918520.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '282904': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/210',
        'https://profinansy.store/page30918541.html'
      ]
    }
  },
  platform_second: {
    icon: Vector1,
    title: 'ВСЁ САМ',
    subTitle: '12 месяцев',
    isBasic: true,

    sale: '59%',

    subTitleSub: 'Оплата подписки: раз в год',

    marketingPrice: 17760,
    userPrice: 7390,

    monthPrice: 615,
    economySum: 10370,

    platformTariff: true,

    balances: {
      '0': [
        'https://payment.profinansy.ru/211',
        'https://profinansy.store/page30952035.html'
      ],
      '120': [
        'https://payment.profinansy.ru/212',
        'https://profinansy.store/page30952101.html'
      ],
      '180': [
        'https://payment.profinansy.ru/213',
        'https://profinansy.store/page30952145.html'
      ],
      '216': [
        'https://payment.profinansy.ru/214',
        'https://profinansy.store/page30952307.html'
      ],
      '238': [
        'https://payment.profinansy.ru/215',
        'https://profinansy.store/page30952386.html'
      ],
      '274': [
        'https://payment.profinansy.ru/216',
        'https://profinansy.store/page30952599.html'
      ],
      '315': [
        'https://payment.profinansy.ru/217',
        'https://profinansy.store/page30952756.html'
      ],
      '600': [
        'https://payment.profinansy.ru/218',
        'https://profinansy.store/page30952975.html'
      ],
      '660': [
        'https://payment.profinansy.ru/219',
        'https://profinansy.store/page30953073.html'
      ],
      '727': [
        'https://payment.profinansy.ru/220',
        'https://profinansy.store/page30953161.html'
      ],
      '799': [
        'https://payment.profinansy.ru/221',
        'https://profinansy.store/page30953705.html'
      ],
      '919': [
        'https://payment.profinansy.ru/222',
        'https://profinansy.store/page30979493.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/223',
        'https://profinansy.store/page30953792.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/224',
        'https://profinansy.store/page30953894.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/225',
        'https://profinansy.store/page30953944.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/226',
        'https://profinansy.store/page30954019.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/227',
        'https://profinansy.store/page30979559.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/228',
        'https://profinansy.store/page30954070.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/229',
        'https://profinansy.store/page30954212.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/229',
        'https://profinansy.store/page30954212.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/230',
        'https://profinansy.store/page30954278.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/231',
        'https://profinansy.store/page30954325.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '282904': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/232',
        'https://profinansy.store/page30954384.html'
      ]
    }
  },
  platform_third: {
    icon: Vector1,
    title: 'ВСЁ САМ',
    subTitle: 'Навсегда',
    isBasic: true,

    premiumCard: true,

    sale: '75%',

    subTitleSub: 'Оплата подписки: раз в год',

    marketingPrice: 60000,
    userPrice: 15000,

    monthPrice: 125,
    economySum: 162600,

    platformTariff: true,

    balances: {
      '0': [
        'https://payment.profinansy.ru/234',
        'https://profinansy.store/page30954778.html'
      ],
      '120': [
        'https://payment.profinansy.ru/235',
        'https://profinansy.store/page30954876.html'
      ],
      '180': [
        'https://payment.profinansy.ru/236',
        'https://profinansy.store/page30954944.html'
      ],
      '216': [
        'https://payment.profinansy.ru/237',
        'https://profinansy.store/page30954978.html'
      ],
      '238': [
        'https://payment.profinansy.ru/238',
        'https://profinansy.store/page30955025.html'
      ],
      '274': [
        'https://payment.profinansy.ru/239',
        'https://profinansy.store/page30955082.html'
      ],
      '315': [
        'https://payment.profinansy.ru/240',
        'https://profinansy.store/page30955121.html'
      ],
      '600': [
        'https://payment.profinansy.ru/241',
        'https://profinansy.store/page30955201.html'
      ],
      '660': [
        'https://payment.profinansy.ru/242',
        'https://profinansy.store/page30955290.html'
      ],
      '727': [
        'https://payment.profinansy.ru/243',
        'https://profinansy.store/page30955346.html'
      ],
      '799': [
        'https://payment.profinansy.ru/244',
        'https://profinansy.store/page30955425.html'
      ],
      '919': [
        'https://payment.profinansy.ru/245',
        'https://profinansy.store/page30955480.html'
      ],
      '1057': [
        'https://payment.profinansy.ru/246',
        'https://profinansy.store/page30955551.html'
      ],
      '1215': [
        'https://payment.profinansy.ru/247',
        'https://profinansy.store/page30955639.html'
      ],
      '1397': [
        'https://payment.profinansy.ru/248',
        'https://profinansy.store/page30955685.html'
      ],
      '1607': [
        'https://payment.profinansy.ru/249',
        'https://profinansy.store/page30955727.html'
      ],
      '1848': [
        'https://payment.profinansy.ru/250',
        'https://profinansy.store/page30955764.html'
      ],
      '2125': [
        'https://payment.profinansy.ru/251',
        'https://profinansy.store/page30955825.html'
      ],
      '2443': [
        'https://payment.profinansy.ru/252',
        'https://profinansy.store/page30955865.html'
      ],
      '2448': [
        'https://payment.profinansy.ru/252',
        'https://profinansy.store/page30955865.html'
      ],
      '2810': [
        'https://payment.profinansy.ru/253',
        'https://profinansy.store/page30955946.html'
      ],
      '3231': [
        'https://payment.profinansy.ru/254',
        'https://profinansy.store/page30955977.html'
      ],
      '122308': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '213916': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '569021': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '865409': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '37675847': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '282904': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '995221': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ],
      '43327223': [
        'https://payment.profinansy.ru/255',
        'https://profinansy.store/page30956065.html'
      ]
    }
  }
}
