import { delay } from './index'

export default (method: any, body: any, ukey): any => {
  let aborted = false

  const promise = new Promise((resolve, reject) => {
    ;(async () => {
      while (true) {
        if (aborted) break
        try {
          const result = await method(body, ukey)

          if (!aborted) {
            resolve(result)
          }

          break
        } catch (e) {
          await delay(1000)
        }
      }
    })().catch((e) => {
      reject(e)
    })
  })

  //@ts-ignore
  promise.abort = () => {
    aborted = true
  }

  return promise
}
