import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const useBlackStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      maxWidth: '500px',
      margin: '0 auto 20px auto',
      '& button': {
        height: '50px',
        borderRadius: 8,
        width: '250px',
        fontSize: '16px',
        fontWeight: 600,
        cursor: 'pointer',
        border: 'none'
      },
      '& a:first-child button': {
        cursor: 'pointer',
        backgroundColor: '#FFDD2D'
      },
      '& a:last-child button': {
        cursor: 'pointer',
        backgroundColor: 'red',
        color: 'white'
      },
      [theme.breakpoints.down(600)]: {
        flexDirection: 'column'
      }
    },
    giftButton: {
      height: '60px',
      borderRadius: 8,
      padding: '4px 28px',
      fontSize: '16px',
      fontWeight: 600,
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#E4F344',
      display: 'block',
      margin: '0 auto 20px auto'
    },
    text: {
      padding: '0px 24px',
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      marginTop: '40px',
      fontSize: '28px',
      lineHeight: '132%',
      fontWeight: 700,
      color: '#fff',
      '& > span': {
        color: '#FE21E0'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    cardsSmall: {
      maxWidth: 1248,
      padding: '0px 24px',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      },
      [theme.breakpoints.down(950)]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      },
      [theme.breakpoints.down(650)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    pinkBlock: {
      width: '100%',
      backgroundColor: '#FFAFF4',
      padding: '40px 24px'
    },
    banner: {
      display: 'block',
      maxWidth: 1200,
      width: '100%',
      margin: '0 auto'
    },
    text2: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 950,
      textAlign: 'center',
      margin: '32px auto',
      fontSize: '22px',
      lineHeight: '120%',
      fontWeight: 700,
      color: '#000',
      [theme.breakpoints.down(600)]: {
        fontSize: '18px'
      }
    },
    cardsMedium: {
      maxWidth: 1248,
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        maxWidth: '600px'
      },
      [theme.breakpoints.down(750)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    cardsMedium2: {
      maxWidth: 1248,
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(750)]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    blackBlock: {
      width: '100%',
      backgroundColor: '#0C0C0C',
      padding: '40px 24px'
    },
    superText: {
      marginTop: 40,
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      fontSize: '30px',
      lineHeight: '132%',
      fontWeight: 900,
      color: '#fff',
      '& > span': {
        color: '#E4F344'
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 20,
        fontSize: '24px'
      }
    },
    cardsSuper: {
      maxWidth: 900,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: 14,
      rowGap: 54,
      margin: '80px auto 0 auto',
      [theme.breakpoints.down(900)]: {
        maxWidth: 500,
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    not_russia: {
      marginTop: 40,
      maxWidth: 800,
      textAlign: 'center',
      margin: '0px auto',
      fontSize: '56px',
      lineHeight: '132%',
      fontWeight: 900,
      color: '#fff',
      '& > span': {
        color: '#FE21E0'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '36px'
      }
    }
  })
)
