import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import React from 'react'

import Button from '../UI/Button'

interface Props extends WithStyles<typeof style> {
  onClose: any
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontSize: 36,
      fontWeight: 800,
      marginBottom: 26
    },
    group: {},
    groupTitle: {
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 700
    },
    groupItems: {
      marginBottom: 28
    },
    groupItem: {
      marginBottom: 12,
      display: 'flex'
    },
    groupCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#6553F5',
      marginTop: 7,
      marginRight: 12
    },
    groupItemText: {
      flex: 1,
      overflow: 'hidden',
      fontSize: 18
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10
    }
  })

const Render = [
  {
    title: 'Личные финансы:',
    blocks: [
      'Модуль 1. Как выглядит правильная стратегия личных финансовых: от хаоса к порядку',
      'Модуль 2. Финансовые цели: как на самом деле их правильно ставить и считать',
      'Модуль 3. Как работать меньше и зарабатывать больше',
      'Модуль 4. Закрываем кредиты и долги'
    ]
  },
  {
    title: 'Инвестиции:',
    blocks: [
      'Модуль 1. Какие возможности даёт рынок ценных бумаг для всех желающих',
      'Модуль 2.  Знакомимся со структурой рынка, брокерами и их возможностями. Открываем счёт для накоплений',
      'Модуль 3. Про обязательные нормы регулирования и основы налогообложения на фондовом рынке.',
      'Модуль 4. Как биржевые инструменты помогают сохранить деньги и заработать новые',
      'Модуль 5. Что влияет на рынки ценных бумаг.',
      'Модуль 6. Осваиваем искусство управления инвестиционным портфелем.',
      'Модуль 7. Изучаем поведенческие финансы'
    ]
  }
]

class Modules11 extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        <div className={classes.title}>Программа курса</div>

        {Render.map((group, index) => {
          return (
            <div className={classes.group}>
              <div className={classes.groupTitle}>{group.title}</div>
              <div className={classes.groupItems}>
                {group.blocks.map((el, index) => {
                  return (
                    <div className={classes.groupItem}>
                      <div className={classes.groupCircle} />
                      <div className={classes.groupItemText}>{el}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}

        <div className={classes.button}>
          <Button onClick={this.props.onClose}>Выбрать тариф</Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(Modules11)
