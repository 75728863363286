import { SessionReqPayload, SessionReqResponse } from './api.types'
import { makeRequest } from './makeRequest'

type SessionReqArgs = SessionReqPayload & { isGet?: boolean; isLogin?: boolean }

const Prefix = 'https://profinansy.ru/api';

export const authApi = {
  getSession: ({
    isGet = false,
    isLogin = false,
    ...payload
  }: SessionReqArgs): Promise<SessionReqResponse> =>
    makeRequest(
      '/auth/fast_session' + (isLogin ? '/login' : ''),
      payload,
      'json',
      true,
      isGet,
      true,
      null,
      false,
        Prefix
    ),
    AddBalance: ({
                     isGet = false,
                     isLogin = false,
                     ...payload
                 }: SessionReqArgs): Promise<SessionReqResponse> =>
        makeRequest(
            '/auth/fast_session/add_balance',
            payload,
            'json',
            true,
            isGet,
            true,
            null,
            false,
            Prefix
        )
}
