import classNames from 'classnames'
import J from 'proper-url-join'
import QueryString from 'qs'
import { FC, useContext, useState } from 'react'

import { userContext } from '../../context/userContext'

import { ClubCard } from './ClubCard'
import { MethodCard } from './MethodCard'
import { PlatformCard } from './PlatformCard'
import { useTabsStyles } from './Tabs.styles'

import CursCard from "./CursCard";

type Tab = {
  title: string
  isReverse?: boolean
  items: {
    type: string
    isFull: boolean
    typeCard: 'platforma' | 'metod' | 'club'
  }[]
}

export type TabsProps = {
  tabs: Tab[]
}

export type CardDetails = {
  name: string
  bg?: string
  fullPrice: string
  offPrice: string
  personalTariff?: boolean
  titleIcon: string
  payNow?: string
  payFull?: string
  takePlace?: string
  payPart?: Array<string> | string
  price?: string
  subtitlePrice?: string
  paymentUrl?: string
  title?: string
  buttonTitle?: string
  forever?: boolean,
    buttons?: any
}

const parse = QueryString.parse(window.location.search || '', {
  ignoreQueryPrefix: true
}) as any

delete parse.ukey

export const Tabs: FC<TabsProps> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const classes = useTabsStyles()

  const { setUserData, onLogout, userMetadata, ukey } = useContext(userContext);

  const METHOD_CARDS: Record<string, CardDetails> = {
    metod_1: {
      name: 'С финансовым консультантом',
      fullPrice: '69 800 Р',
      offPrice: '37 580 Р',
      personalTariff: false,
      bg: 'bg-pink.png',
      titleIcon: 'people-icon.png',
      payNow: J('https://payment.profinansy.ru/795?potok=3', { query: parse }), // оплатить сейчас
      payFull: J('https://payment.profinansy.ru/795?potok=3', { query: parse }), //оплатить полностью
      takePlace: J('https://payment.profinansy.ru/793', { query: parse }), // забронировать место
      payPart: [
        J('https://payment.profinansy.ru/796?potok=3', { query: parse }),
        J('https://payment.profinansy.ru/797?potok=3', { query: parse })
      ]
    },
    metod_2: {
      name: 'Персональный тариф',
      bg: 'bg-blue.png',
      fullPrice: '250 000 Р',
      offPrice: '150 000 Р',
      personalTariff: true,
      titleIcon: 'diamond.png',
      payNow: J('https://payment.profinansy.ru/799?potok=3', { query: parse }),
      payFull: J('https://payment.profinansy.ru/799?potok=3', { query: parse }),
      takePlace: J('https://payment.profinansy.ru/793', { query: parse }),
      payPart: [
        J('https://payment.profinansy.ru/800?potok=3', { query: parse }),
        J('https://payment.profinansy.ru/801?potok=3', { query: parse })
      ]
    }
  }

  const PLATFORM_CARDS = {
    platforma_1: {
      title: 'на 6 месяцев',
      name: 'Платформа 1',
      fullPrice: '1480 Р',
      price: '980 Р',
      subtitlePrice: '5 880',
      paymentUrl: [
        J('https://payment.profinansy.ru/816', { query: parse }),
        J('https://payment.profinansy.ru/817', { query: parse })
      ],
      buttonTitle: 'Купить на 6 месяцев'
    },
    platforma_2: {
      title: 'на 12 месяцев',
      name: 'Платформа 2',
      fullPrice: '1480 Р',
      subtitlePrice: '9 880',
      price: '823 Р',
      paymentUrl: [
        J('https://payment.profinansy.ru/818', { query: parse }),
        J('https://payment.profinansy.ru/819', { query: parse })
      ],
      buttonTitle: 'Купить на 12 месяцев'
    },
    platforma_3: {
      title: 'навсегда',
      name: 'Платформа 3',
      fullPrice: '1480 Р',
      price: '198 Р',
      paymentUrl: J('https://payment.profinansy.ru/820', { query: parse }),
      buttonTitle: 'Купить навсегда',
      forever: true
    }
  }

  const CLUB_CARDS = {
    club_1: {
      title: 'клуб на 3 месяца',
      isForever: false,
      newPrice: '3 180 P',
      oldPrice: '3 600 P',
      priceDescription: '* стоимость за 1 мес — 1 060 рублей',
      link: 'https://google.com',
      paymentUrl: [
        J('https://payment.profinansy.ru/821', { query: parse }),
        J('https://payment.profinansy.ru/822', { query: parse })
      ]
    },
    club_2: {
      title: 'клуб наВСЕГДА',
      isForever: true,
      newPrice: '199 P',
      oldPrice: '500 P',
      priceDescription:
        'При единовременной оплате <span>28 800р</span>. <b>23&nbsp;880&nbsp;₽</b> за доступ навсегда',
      paymentUrl: J('https://payment.profinansy.ru/823', { query: parse })
    }
  };

  const CURS_CARDS = {
      new_curs_1: {
          paymentUrl: J('https://payment.profinansy.ru/842', { query: parse }),
          isBook: false,
          title: 'Курс "Инвестиции доступны всем"',
          customIn: 'Оставить заявку на оплату иностранной картой'
      },
      new_curs_2: {
          paymentUrl: [J('https://payment.profinansy.ru/843', { query: parse }), J('https://payment.profinansy.ru/844', { query: parse })],
          isBook: true,
          title: 'Книга "33 заметки о финансах"'
      }
  };

  ;[METHOD_CARDS, PLATFORM_CARDS, CLUB_CARDS, CURS_CARDS].forEach((item) => {
    for (const key in item) {
      const val = item[key]
      ;['payNow', 'payFull', 'takePlace', 'payPart', 'paymentUrl'].forEach(
        (field) => {
          if (val[field]) {
            if (Array.isArray(val[field])) {
              val[field].forEach((el, index) => {
                val[field][index] = J(val[field][index], { query: { ukey } })
              })
            } else {
              val[field] = J(val[field], { query: { ukey } })
            }
          }
        }
      )
    }
  })

  const Cards = {
    metod: MethodCard,
    platforma: PlatformCard,
    club: ClubCard,
      new_curs: CursCard
  }

  return (
    <div className={classes.root}>
      {tabs.length !== 1 && (
        <div className={classes.tabList}>
          {tabs.map((tab) => (
            <button
              className={classNames(classes.tab, {
                [classes.activeTab]: selectedTab.title === tab.title
              })}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.title}
            </button>
          ))}
        </div>
      )}

      <div className={classes.cardList}>
        {selectedTab.items.map((cardInfo) => {

          let cardDetails;

          if (cardInfo.typeCard === 'metod') {
              cardDetails = METHOD_CARDS[cardInfo.type];
          } else if (cardInfo.typeCard === 'club') {
              cardDetails = CLUB_CARDS[cardInfo.type];
          } else if (cardInfo.typeCard === 'platforma') {
              cardDetails = PLATFORM_CARDS[cardInfo.type];
          } else if (cardInfo.typeCard === 'new_curs') {
              cardDetails = CURS_CARDS[cardInfo.type];
          }

          //@ts-ignore
          const buttons = cardInfo.buttons || [];

          const Card = Cards[cardInfo.typeCard];

          return <Card {...cardDetails} buttons={buttons} />
        })}
      </div>
    </div>
  )
}
