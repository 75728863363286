import { createStyles, makeStyles } from '@material-ui/core'

export const useMethodCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      maxWidth: 560,
      borderRadius: 24,
      padding: '32px 47px 30px 36px',
      background: 'linear-gradient(180deg, #0D0F1F 0%, #0B0E24 100%)',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: '32px 20px 30px 20px',
        width: 'auto',
        maxWidth: '100%'
      }
    },

    bgImg: {
      position: 'absolute',
      left: 0,
      top: 0
    },

    content: {
      position: 'relative',
      zIndex: 1
    },

    titleBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: 13,
      maxWidth: 496,
      width: '100%',
      borderRadius: '40px 6px',
      padding: '22px 8px',
      background: 'rgba(255, 255, 255, 0.01)',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.16)',
      backdropFilter: 'blur(40px)',
      [theme.breakpoints.down('xs')]: {
        width: 'auto'
        // flexDirection: 'column'
      }
    },

    titleIcon: {
      minWidth: 79,
      height: 79,
      [theme.breakpoints.down('md')]: {
        minWidth: 50,
        height: 50
      }
    },

    mainTitle: {
      color: '#D6D9F3',
      textTransform: 'uppercase',
      fontSize: 36,
      fontWeight: 400,
      fontFamily: 'Russo One, sans-serif',
      [theme.breakpoints.down('md')]: {
        fontSize: 22
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    },

    pinkPrimaryBg: {
      backgroundColor: '#FC66FF',
      boxShadow: '0px -2px 24px rgba(252, 96, 255, 0.44)'
    },
    pinkSecondaryBg: {
      background: 'linear-gradient(180deg, #783A79 0%, #482748 100%)'
    },
    bluePrimaryBg: {
      backgroundColor: '#6777FF',
      boxShadow: '0px -2px 24px rgba(98, 151, 255, 0.44)'
    },
    blueSecondaryBg: {
      background: 'linear-gradient(180deg, #393F78 0%, #252845 100%)'
    },

    pricesBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '32px 0',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left'
      }
    },

    offPrice: {
      color: '#FFE9FF',
      fontFamily: 'Russo One, sans-serif',
      fontSize: 50,
      lineHeight: '66.5px',
      paddingRight: 16,
      textShadow: '0px 0px 8px rgba(253, 133, 255, 0.5)'
    },

    fullPrice: {
      color: '#fff',
      fontFamily: 'Russo One, sans-serif',
      fontSize: 40,
      lineHeight: '52px',
      opacity: 0.5,
      textDecoration: 'line-through',
      [theme.breakpoints.down('md')]: {
        order: -1
      }
    },

    primaryBtn: {
      cursor: 'pointer',
      borderRadius: 10,
      padding: '25px 0',
      color: '#fff',
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '23px',
      width: '100%',
      border: 'none'
    },

    accessDescBlock: {
      marginTop: 42,
      paddingBottom: 32,
      borderBottom: '1px dashed #ccc'
    },

    accessBlockHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20
    },

    accessBlockTitle: {
      fontFamily: 'Russo One, sans-serif',
      color: '#fff',
      fontSize: 17,
      lineHeight: '20.49px',
      marginBottom: 0
    },

    descBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 24,
      [theme.breakpoints.down('xs')]: {
        gap: 10
      }
    },

    classicDescription: {
      fontSize: 16,
      fontWeight: 400,
      color: '#fff',
      lineHeight: '20px',
      maxWidth: 348,
      width: '100%'
    },

    iconLeft: {},

    tariffIncludes: {
      marginTop: 24
    },

    tariffIncludesTitle: {
      fontFamily: 'Russo One, sans-serif',
      color: '#fff',
      fontSize: 17,
      lineHeight: '20.49px',
      textAlign: 'center',
      marginBottom: 24
    },

    tariffIncludesList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 30
    },

    giftsBlock: {
      marginTop: 40,
      position: 'relative'
    },

    giftsTitle: {
      color: '#C733D3',
      fontFamily: 'Russo One, sans-serif',
      fontSize: 17,
      lineHeight: '20.49px',
      textAlign: 'center',
      marginBottom: 270
    },

    giftsGroup: {
      position: 'absolute',
      top: -160,
      left: -55,
      [theme.breakpoints.down('xs')]: {
        left: -120
      }
    },

    bonusesBlock: {
      borderTop: '1px dashed #ccc',
      paddingTop: 24,
      marginTop: 40
    },

    bonusesTitle: {
      marginBottom: 32
    },

    bonusesList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      marginBottom: 28
    },

    bold: {
      fontWeight: 700
    },

    boldBlue: {
      color: '#6777FF'
    },

    lineThrough: {
      color: '#898989',
      textDecoration: 'line-through'
    },

    subBtns: {
      marginTop: 16,
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      '& button': {
        padding: '0px 20px',
        height: 73
      }
    }
  })
)
