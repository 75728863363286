import {
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    button: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#6553F5',
      color: '#fff',
      borderRadius: 6,
      fontWeight: 'bold',
      padding: '0 25px',
      cursor: 'pointer',
      border: '1px solid #6553F5',
      userSelect: 'none',
      transition: 'box-shadow .3s',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.14)'
      },
      '&:active': {
        boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.14)'
      },
      textDecoration: 'none',
      justifyContent: 'center'
    },
    smallButton: {
      height: 36,
      padding: '0 24px'
    },
    disabled: {
      backgroundColor: '#E9E9E9',
      border: '1px solid #E9E9E9',
      '&:hover': {
        boxShadow: 'none'
      },
      '&:active': {
        boxShadow: 'none'
      },
      cursor: 'default'
    }
  })

interface Props extends WithStyles<typeof styles> {
  children?: any
  variant?: 'small' | 'default' | 'big'
  className?: string
  onClick?: any
  style?: any
  disabled?: boolean
  useRef?: any
  type?: any
}

class Button extends React.Component<Props> {
  onClick = (e: any) => {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(e)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Typography
        ref={this.props.useRef}
        style={this.props.style}
        onClick={this.onClick}
        className={cx(
          classes.button,
          {
            [classes.smallButton]: this.props.variant === 'small',
            [classes.disabled]: !!this.props.disabled
          },
          this.props.className
        )}
        component={'div'}
      >
        {this.props.children}
      </Typography>
    )
  }
}

export default withStyles(styles)(Button)
