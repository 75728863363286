import {
  Grid,
  Slider,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

import { answerPoll } from '../../api/api'
import {
  userContext,
  withUserContext,
  withUserContextProps
} from '../../context/userContext'
import { ActivePoll, NoActivePoll } from '../../icons/ActivePoll'
import { GLOBAL_STORE, declOfNum } from '../lib'

interface Props extends WithStyles<typeof style>, withUserContextProps {
  info: any
  resetPolls: any
}

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0'
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000'
    }
  },
  track: {
    height: 2
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  },
  markLabel: {
    color: 'rgba(255, 255, 255, 0.7)'
  },
  markLabelActive: {
    color: 'rgba(255, 255, 255, 1)'
  }
})(Slider)

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontWeight: 700,
      fontSize: 32,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    items: {},
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0
      },
      cursor: 'pointer'
    },
    itemMainInfo: {
      flex: 1,
      overflow: 'hidden',
      marginLeft: 5,
      position: 'relative',
      paddingTop: 10
    },
    itemTitle: {
      fontWeight: 400,
      fontSize: 14,
      color: 'rgba(255, 255, 255, 0.7)',
      position: 'relative',
      transition: 'top 1s',
      top: -5
    },
    itemTitleActive: {
      top: -10
    },
    count: {
      color: '#B3B3B3',
      marginLeft: 6
    },
    line: {
      borderRadius: 100,
      backgroundColor: '#181818',
      height: 3,
      position: 'absolute',
      bottom: 0,
      width: '100%'
    },
    lineActive: {
      borderRadius: 100,
      backgroundColor: '#6553F5',
      height: 3,
      position: 'absolute',
      bottom: 0,
      width: 0,
      transition: 'width 1s'
    },
    results: {
      width: 70,
      textAlign: 'right'
    },
    hidden: {
      visibility: 'hidden'
    },
    resultsCount: {
      textAlign: 'center',
      marginTop: 10
    },
    sliderBlockWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: 30
      }
    },
    sliderBlock: {
      position: 'relative'
    },
    valueLabel: {
      position: 'absolute',
      top: -30,
      left: 0,
      transform: 'scale(0)',
      transition: 'left .3s, transform .3s'
    },
    valueLabelActive: {
      transform: 'scale(1) translateY(-10px)'
    },
    valueLabelContainer: {
      width: 36,
      height: 36,
      display: 'flex',
      transform: 'rotate(-45deg)',
      alignItems: 'center',
      borderRadius: '50% 50% 50% 0',
      justifyContent: 'center',
      backgroundColor: '#6553F5'
    },
    valueLabelText: {
      transform: 'rotate(45deg)'
    },
    subTitle: {
      color: '#B3B3B3',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '24px',
      marginBottom: 50,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '18px'
      }
    }
  })

class Poll extends React.Component<Props> {
  onPoll = (index: number) => {
    if (GLOBAL_STORE[this.props.info.id] !== undefined) return

    answerPoll(
      {
        id: this.props.info.id,
        variant: index
      },
      this.props.ukey
    )
      .then(() => {
        this.props.resetPolls(this.props.info.id, index)
      })
      .catch((e) => {})

    GLOBAL_STORE[this.props.info.id] = index
    this.forceUpdate()
  }

  handleChange = (e: any, newItem: any) => {
    newItem--

    if (GLOBAL_STORE[this.props.info.id] !== undefined) return

    answerPoll(
      {
        id: this.props.info.id,
        variant: newItem
      },
      this.props.ukey
    )
      .then(() => {
        this.props.resetPolls(this.props.info.id, newItem)
      })
      .catch((e) => {})

    GLOBAL_STORE[this.props.info.id] = newItem
    this.forceUpdate()
  }

  render() {
    const { classes } = this.props

    const marks = this.props.info.variants.map((el, index) => {
      return {
        label: el.title,
        value: index + 1
      }
    })

    return (
      <div className={classes.box}>
        <div className={classes.title}>{this.props.info.title}</div>

        <div className={classes.subTitle}>{this.props.info.sub_title}</div>

        <div className={classes.sliderBlockWrapper}>
          <div className={classes.sliderBlock}>
            <IOSSlider
              defaultValue={0}
              aria-labelledby="discrete-slider-custom"
              step={1}
              max={this.props.info.variants.length}
              min={1}
              valueLabelDisplay="auto"
              marks={marks}
              onChangeCommitted={this.handleChange}
              disabled={GLOBAL_STORE[this.props.info.id] !== undefined}
            />

            <div
              className={cx(classes.valueLabel, {
                [classes.valueLabelActive]:
                  GLOBAL_STORE[this.props.info.id] !== undefined
              })}
              style={{
                left: `calc(${
                  (this.props.info.wheel /
                    (this.props.info.variants.length - 1)) *
                  100
                }% - 18px)`
              }}
            >
              <div className={classes.valueLabelContainer}>
                <div className={classes.valueLabelText}>
                  {+this.props.info.wheel.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className={classes.items}>
                    {
                        this.props.info.variants.map((el, index) => {
                            return (
                                <div className={classes.item} key={index} onClick={this.onPoll.bind(this, index)}>

                                    {
                                        GLOBAL_STORE[this.props.info.id] === index ?
                                            <ActivePoll/>
                                            :
                                            <NoActivePoll/>
                                    }

                                    <div className={classes.itemMainInfo}>

                                        <div className={cx(classes.itemTitle, {
                                            [classes.itemTitleActive]: GLOBAL_STORE[this.props.info.id] !== undefined
                                        })}>
                                            {el.title}
                                        </div>

                                        <div className={cx({
                                            [classes.hidden]: GLOBAL_STORE[this.props.info.id] === undefined
                                        })}>
                                            <div className={classes.line}/>
                                            <div className={classes.lineActive} style={{
                                                width: GLOBAL_STORE[this.props.info.id] === undefined ? '0%' : el.percent * 100 + '%'
                                            }}/>
                                        </div>

                                    </div>

                                    {
                                        GLOBAL_STORE[this.props.info.id] !== undefined ?
                                            <div className={classes.results}>
                                                <span>
                                                    {
                                                        `${+(el.percent*100).toFixed(2)}%`
                                                    }
                                                </span>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                            )
                        })
                    }
                </div>*/}

        {GLOBAL_STORE[this.props.info.id] !== undefined ? (
          <div className={classes.resultsCount}>
            {`${this.props.info.totalCount} ${declOfNum(
              this.props.info.totalCount,
              ['голос', 'голоса', 'голосов']
            )}`}
          </div>
        ) : null}
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(Poll)) as any
