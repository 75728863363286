import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

import Button from './UI/Button'
import Img from '../Group 48095380.png'

interface Props extends WithStyles<typeof style> {
  link: string
  title: string
  subLink: string
  subTitle: string
}

const style = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    box: {
      borderRadius: 20,
      background: '#181818',
      padding: 20,
      textAlign: 'center',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 15px 25px 15px',
        flexDirection: 'column'
      },
      position: 'relative'
    },
    img: {
      maxWidth: 300,
      height: 150,
      position: 'absolute',
      top: -50,
      left: 0,
      [theme.breakpoints.down('sm')]: {
        left: 20
      }
    },
    button: {
      textDecoration: 'none',
      marginLeft: 30,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        '&:last-child': {
          marginTop: 20
        }
      }
    },
    buttonStyle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    subButton: {
      background: '#2AABEE',
      borderColor: '#2AABEE'
    },
    firstBlock: {
      width: 180,
      [theme.breakpoints.down('sm')]: {
        height: 80
      }
    }
  })

class Present extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <img src={Img} className={classes.img} />

          <div className={classes.firstBlock} />

          <a
            href={this.props.link}
            target={'_blank'}
            className={classes.button}
          >
            <Button className={classes.buttonStyle}>{this.props.title}</Button>
          </a>

          {this.props.subLink ? (
            <a
              href={this.props.subLink}
              target={'_blank'}
              className={classes.button}
            >
              <Button className={cx(classes.buttonStyle, classes.subButton)}>
                {this.props.subTitle}
              </Button>
            </a>
          ) : null}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(Present)
