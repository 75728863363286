import { Theme, createStyles, makeStyles } from '@material-ui/core'

interface IProps {
  isBig: boolean
}

export const useCardStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: (props) => (props.isBig ? '100%' : '300px'),
      background: '#eeeeee',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(750)]: {
        maxWidth: '100% !important'
      }
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      padding: '18px 15px',
      borderRadius: '0 0 20px 20px'
    },
    image: {
      position: 'relative',
      top: '-40px',
      width: '100%',
      marginBottom: '-40px',
      aspectRatio: (props) => (props.isBig ? '1 / 0.6' : '1 / 0.8'),
      '& > img': {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        display: 'block',
        margin: '0px auto',
        width: 'auto',
        maxHeight: '100%'
      }
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: 0
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16
    },
    name: {
      color: '#101010',
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '120%'
    },
    price: {
      color: '#101010',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '120%',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 12,
        left: 2,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-10deg)',
        transformOrigin: 'left'
      }
    },
    new: {
      position: 'relative',
      '& > img': {
        width: '80px'
      },
      '& > span': {
        position: 'absolute',
        left: '14%',
        top: '18%',
        color: '#101010',
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '120%'
      }
    },
    description: {
      color: '#101010',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '120%',
      marginTop: 0,
      marginBottom: 10
    },
    contentContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end'
    },
    more: {
      color: '#FE21E0',
      textDecoration: 'underline',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '10px',
      background: 'none',
      border: 'none',
      padding: '0px',
      cursor: 'pointer'
    },
    prices: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 5
    },
    oldPrice: {
      position: 'relative',
      color: '#191919',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '135%',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 21,
        left: 4,
        width: '100%',
        height: '100%',
        borderTop: '2px solid red',
        transform: 'rotate(-12deg)',
        transformOrigin: 'left'
      }
    },
    newPrice: {
      position: 'relative',
      '& > span': {
        position: 'absolute',
        left: 22,
        top: 8,
        color: '#242424',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '125%'
      }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      width: '100%'
    },
    buttonDiscount: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      boxShadow: '0px 2.557px 0px 0px #A43191',
      background: '#FE21E0',
      color: '#fff',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '45px'
    },
    buttonCart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      width: '100%',
      borderRadius: 8,
      cursor: 'pointer',
      border: '2px solid #FE21E0',
      background: 'transparent',
      color: '#FE21E0',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '17px',
      height: '45px'
    },
    buttonTinkoff: {
      width: '100%',
      border: 'none',
      borderRadius: 8,
      cursor: 'pointer',
      background: '#FFDD2D',
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 12
    },
    tinkoffText: {
      fontSize: 12,
      color: '#333333',
      fontWeight: 700,
      display: 'block',
      textAlign: 'left'
    },
    tinkoffSubText: {
      fontSize: 10,
      color: '#333333',
      fontWeight: 400,
      display: 'block',
      textAlign: 'left'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column'
      }
    },
    modalImage: {
      maxWidth: '300px',
      minWidth: '300px',
      [theme.breakpoints.down(600)]: {
        minWidth: 'auto'
      }
    },
    descriptionModal: {
      minWidth: '400px',
      marginBottom: 20,
      '& h2': {
        fontSize: 24,
        marginTop: 0
      },
      '& h3': {
        fontSize: 18,
        marginTop: 20,
        marginBottom: 0
      },
      '& ul': {
        padding: '0px 14px',
        margin: '10px 0 10px'
      },
      '& span': {
        display: 'block',
        color: '#898989'
      },
      [theme.breakpoints.down(900)]: {
        minWidth: 'auto'
      }
    }
  })
)
