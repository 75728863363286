export const CARDS_MEDIUM = [
  {
    key: 'combo_1',
    id: '640100:7000,626578:12499',
    name: '3 любых курса + клуб на год',
    image: '/assets/2024/combo_1.png',
    description:
      'Получите 3 курса на ваш выбор и участие в Клубе pro.finansy на год',
    products: [
      { name: '3 любых курса', price: 10500 },
      { name: 'Клуб на год', price: 46800 }
    ],
    price: 19499,
    oldPrice: 57300,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        subtext: 'от 1 624 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>3 любых курса + клуб на год</h2>' +
      '<p>Выбирайте 3 любых продвинутых курса после оплаты и вступайте в Клуб pro.finansy.</p>' +
      '<p><b>Клуб pro.finansy</b> - это сообщество, где приумножают деньги. Получайте свежую и точную аналитику по фондовому рынку. Следите за сделками профессионального трейдера и зарабатывайте вместе с ним. Общайтесь с другими инвесторами и перенимайте их опыт. Задавайте вопросы финансовым консультантам и не тратьте время на самостоятельный поиск ответа. А еще мы встречаемся вместе вживую и ездим в путешествия.\n</p>' +
      '<h3>Что получают участники клуба pro.finansy:\n</h3>' +
      '<ul>' +
      '<li>Закрытый телеграм-канал с новостями и аналитикой\n</li>' +
      '<li>Личный кабинет участника клуба в отдельном приложении\n</li>' +
      '<li>Ежемесячный инвест комитет: куда пойдут рынки в этом месяце\n</li>' +
      '<li>Еженедельный инвест апдейт: за чем следить на неделе и разбор новостей\n</li>' +
      '<li>Рубрика «Выбор pro.finansy» — список лучших акций из всех отраслей.\n</li>' +
      '<li>Реальные сделки в реальном портфеле. С аналитикой. Точкой входа и выхода.\n</li>' +
      '<li>Аналитические разборы акций в pdf на 15-20 страниц\n</li>' +
      '<li>Эфиры «Американские горки»: разбор фондового рынка США\n</li>' +
      '<li>Экономический календарь: за чем следить инвестору и как на этом заработать Присоединяйтесь, нас уже больше 25 000!\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_2',
    id: '640101:11000,451438:23990',
    name: '5 любых курсов + Клуб навсегда',
    image: '/assets/2024/combo_2.png',
    description:
      'Получите 5 курсов на ваш выбор и участие в Клуб pro.finansy навсегда',
    products: [
      { name: '5 любых курсов', price: 17500 },
      { name: 'Клуб навсегда', price: 69900 }
    ],
    price: 34990,
    oldPrice: 87400,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        links: ['https://promopay.profinansy.ru/frame/1461'],
        subtext: 'от 2 915 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>5 любых курсов + Клуб навсегда</h2>' +
      '<p>Выбирайте 5 любых продвинутых курсов после оплаты и вступайте в Клуб pro.finansy навсегда.\n</p>' +
      '<p><b>Клуб pro.finansy</b> - это сообщество, где приумножают деньги. Получайте свежую и точную аналитику по фондовому рынку. Следите за сделками профессионального трейдера и зарабатывайте вместе с ним. Общайтесь с другими инвесторами и перенимайте их опыт. Задавайте вопросы финансовым консультантам и не тратьте время на самостоятельный поиск ответа. А еще мы встречаемся вместе вживую и ездим в путешествия.\n</p>' +
      '<h3>Что получают участники клуба pro.finansy:\n</h3>' +
      '<ul>' +
      '<li>Закрытый телеграм-канал с новостями и аналитикой\n</li>' +
      '<li>Личный кабинет участника клуба в отдельном приложении\n</li>' +
      '<li>Ежемесячный инвест комитет: куда пойдут рынки в этом месяце\n</li>' +
      '<li>Еженедельный инвест апдейт: за чем следить на неделе и разбор новостей\n</li>' +
      '<li>Рубрика «Выбор pro.finansy» — список лучших акций из всех отраслей.\n</li>' +
      '<li>Реальные сделки в реальном портфеле. С аналитикой. Точкой входа и выхода.\n</li>' +
      '<li>Аналитические разборы акций в pdf на 15-20 страниц\n</li>' +
      '<li>Эфиры «Американские горки»: разбор фондового рынка США\n</li>' +
      '<li>Экономический календарь: за чем следить инвестору и как на этом заработать Присоединяйтесь, нас уже больше 25 000!\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_3',
    id: '640101:11000,192175:22990',
    name: '5 любых курсов + подписка на profinansy.ru навсегда',
    image: '/assets/2024/combo_3.png',
    description:
      'Получите 5 курсов на ваш выбор и premium доступ к profinansy.ru',
    products: [
      { name: '5 любых курсов', price: 17500 },
      { name: 'подписка на profinansy.ru навсегда', price: 64900 }
    ],
    price: 33990,
    oldPrice: 82400,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        subtext: 'от 2 833 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>5 любых курсов + подписка на profinansy.ru навсегда</h2>' +
      '<p>Выбирайте 5 любых продвинутых курсов после оплаты и начните использовать возможности сайта profinansy.ru по максимуму.\n</p>' +
      '<p><b>На сайте profinansy.ru<b/> вы сможете легко находить ценные бумаги для получения высокой доходности. Сайт подходит как начинающим, так и опытным инвесторам. Вам станут доступны больше 10 сервисов в одном месте. Пользуйтесь всеми возможностями через сайт или приложение pro.finansy.\n</p>' +
      '<h3>Чтобы находить доходные идеи, вам не придётся тратить слишком много времени. Достаточно иметь простые и надёжные инструменты под рукой. Вы найдёте их на сайте profinansy.ru:\n</h3>' +
      '<ul>' +
      '<li>Обзор рынка на 360 градусов: котировки, показатели, события\n</li>' +
      '<li>Ведение инвестиционных портфелей и аналитика по ним в одном месте\n</li>' +
      '<li>Доступ к самой полной базе инвестиционных инструментов\n</li>' +
      '<li>Аналитика по инвестиционным инструментам и отчетность за 10 лет\n</li>' +
      '<li>Сервис расчета пассивного дохода от облигаций\n</li>' +
      '<li>Готовые фильтры быстрого подбора инвестиций\n</li>' +
      '<li>Календарь всех событий фондового рынка\n</li>' +
      '</ul>'
  },
  {
    key: 'combo_4',
    id: '640101:11000,437803:21280,326282:5919,326283:800',
    name: '5 любых курсов + Метод I + КФП',
    image: '/assets/2024/combo_4.png',
    description:
      'Получите 5 курсов на ваш выбор и обучение на курсе «Метод управления деньгами»',
    products: [
      { name: '5 любых курсов', price: 11000 },
      { name: 'Метод 1', price: 69800 },
      { name: 'КФП', price: 70000 }
    ],
    price: 38999,
    oldPrice: 157300,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        subtext: 'от 3 249 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>5 любых курсов + Метод I + КФП</h2>' +
      '<p>На курсе «Метод управления деньгами» вы научитесь эффективно вкладывать деньги, управлять ими и приумножать вне зависимости от происходящего.</p>' +
      '<p>Уже во время обучения вы создадите свою стратегию формирования капитала, соберете свой инвестиционный портфель и совершите первые сделки на бирже.</p>' +
      '</ul>'
  }
]

export const CARDS_MEDIUM2 = [
  {
    key: 'combo_5',
    id: '640104:1,640534:15000,269474:44989',
    name: '12 продвинутых курсов + Клуб навсегда + Подписка на profinansy.ru навсегда + Метод II',
    image: '/assets/2024/combo_5.png',
    description:
      'Получите 12 курсов, участие в Клубе pro.finansy и premium доступ к сайту profinansy.ru навсегда',
    products: [
      { name: '12 продвинутых курсов', price: 17100 },
      {
        name: 'Клуб навсегда',
        price: 69900
      },
      {
        name: 'Подписка на profinansy.ru навсегда (комбо)',
        price: 64900
      },
      { name: 'Метод 2', price: 0 }
    ],
    price: 59900,
    oldPrice: 151900,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        subtext: 'от 4 999 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>12 продвинутых курсов + Клуб навсегда + Подписка на profinansy.ru навсегда + Метод II</h2>' +
      '<p>Вы получаете 12 продвинутых курсов, premium доступ к сайту profinansy.ru и вступаете в Клуб pro.finansy навсегда.</p>' +
      '<p>Клуб PRO.FINANSY - сообщество, где приумножают деньги! Здесь вы сможете получать прибыльные сделки и актуальную аналитику в компании единомышленников.</p>' +
      '<p>Premium доступ к сайту profinansy.ru позволит находить доходные идеи быстро и легко. Всё надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой.</p>' +
      '</ul>'
  },
  {
    key: 'combo_6',
    id: '640104:1,640534:15000,269474:51421,437803:25443,326282:7078,326283:957',
    name: 'Всё и сразу: 12 продвинутых курсов + Метод I + Метод II + КФП + Клуб навсегда + Подписка на profinansy.ru навсегда',
    image: '/assets/2024/combo_6.png',
    description:
      'Это самый полный пакет продуктов Ольги Гогаладзе. Персональный фин консультант скажет, в какой последовательности все изучать под ваши финансовые задачи',
    products: [
      { name: '12 продвинутых курсов', price: 17100 },
      {
        name: 'Метод 1',
        price: 69800
      },
      { name: 'Метод 2', price: 0 },
      { name: 'Конструктор финансового плана', price: 70000 },
      {
        name: 'Клуб навсегда',
        price: 69900
      },
      {
        name: 'Подписка на profinansy.ru навсегда (комбо)',
        price: 64900
      }
    ],
    price: 99900,
    oldPrice: 316600,
    buttons: [
      {
        type: 'discount'
      },
      {
        type: 'cart'
      },
      {
        type: 'tinkoff',
        subtext: 'от 8 325 ₽ в месяц'
      }
    ],
    descriptionModal:
      '<h2>Всё и сразу: 12 продвинутых курсов + Метод I + Метод II + КФП + Клуб навсегда + Подписка на profinansy.ru навсегда</h2>' +
      '<p>Вы получаете 12 продвинутых курсов, обучение на курсе «Метод управления деньгами», premium доступ к сайту profinansy.ru и вступаете в Клуб pro.finansy навсегда.\n</p>' +
      '<p>Клуб PRO.FINANSY - сообщество, где приумножают деньги! Здесь вы сможете получать прибыльные сделки и актуальную аналитику в компании единомышленников.\n</p>' +
      '<p>Premium доступ к сайту profinansy.ru позволит находить доходные идеи быстро и легко. Всё надёжные инструменты для поиска ценных бумаг всегда будут у вас под рукой.</p>' +
      '</ul>'
  }
]
