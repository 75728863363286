import React from 'react'

import { BUTTONS_COURSES } from '../../buttons.const'
import { addToCart } from '../../utils'

import { useBannerStyles } from './Banner.styled'

interface IProps {
  deleteArr: string[]
}

const Banner = ({ deleteArr }: IProps) => {
  const classes = useBannerStyles()

  const filterCards = (items) => {
    return items.filter((cartItem) => !deleteArr.includes(cartItem.key))
  }

  return (
    <div className={classes.container}>
      <img className={classes.giftLeft} src="/assets/2024/gift.png" />
      <img className={classes.star} src="/assets/2024/star.png" />
      <p className={classes.text}>Забирайте 3 курса = 7 000₽,</p>
      <p className={classes.text}>
        5 курсов = 11 000₽ или все 12 курсов сразу за 15 000 ₽
      </p>
      <div className={classes.buttons}>
        {filterCards(BUTTONS_COURSES).map((button, index) => (
          <button
            key={index}
            className={classes.buttonDiscount}
            onClick={() =>
              addToCart({
                name: button.name,
                price: button.price,
                key: button.key,
                id: button.id
              })
            }
          >
            {button.name}
          </button>
        ))}
      </div>
      <img className={classes.giftRight} src="/assets/2024/gift.png" />
    </div>
  )
}

export { Banner }
