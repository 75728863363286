import {
  Container,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import React from 'react'

interface Props extends WithStyles<typeof style> {}

const style = (theme: Theme) =>
  createStyles({
    box: {
      padding: '49px 0'
    },
    text: {
      fontSize: 12,
      color: '#8B8A8B'
    },
    links: {
      display: 'flex',
      marginTop: 20,
      flexWrap: 'wrap'
    },
    link: {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0
      },
      marginBottom: 20
    },
    a: {
      textDecoration: 'none',
      color: '#fff'
    }
  })

class Footer extends React.Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.box}>
        <Container>
          <div className={classes.text}>
            Инвестиционные результаты прошлых периодов не гарантируют доходности
            в будущем. Все решения об инвестировании средств должны приниматься
            без учета давления со стороны заинтересованных лиц, в соответствии с
            целями и возможностями инвестора.
            <br />
            <br />
            Никакие упоминания каких-либо компаний на сайтах, принадлежащих
            Ольге Гогаладзе, не являются рекламой или попыткой очернить деловую
            репутацию. Любые совпадения фактов и упоминаний случайны.
            <br />
            <br />
            Под словом «инвестиции» имеется в виду вложение денег инвестором в
            активы, которые подлежат регулированию законодательством Российской
            Федерации о рынке ценных бумаг.
            <br />
            <br />
            Услуги представляются в соответствии с офертой. Консультационные
            услуги и обучающие курсы не несут в себе руководства к действию
            и/или принуждения к инвестированию денег в какие-либо активы, а лишь
            являются информационным ресурсом. Решение о размещении денег
            инвестор принимает самостоятельно. Предлагаемые вниманию решения не
            являются индивидуальной инвестиционной рекомендацией. Инвестиции на
            фондовом рынке не дают обещаний и гарантий доходностей. Автор не
            рекомендует инвестиции через Forex дилеров, не ведёт обучение
            трейдингу и любым видам краткосрочных заработков с повышенными
            рисками, не предлагает участие в проектах, организованных по
            принципу сетевого маркетинга.
            <br />
            <br />
            Бонусные рубли используются только в учетных целях за некие
            определенные достижения и ни в коем случае не являются и не могут
            являться средствами платежа, каким-либо видом валюты или ценной
            бумагой, не могут быть обналичены, подарены третьим лицам или
            переданы по наследству.
          </div>

          <div className={classes.links}>
            <div className={classes.link}>
              <a
                className={classes.a}
                href={
                  'https://profinansy-online.ru/files/oferta/black_friday2023/oferta_chp_paket_1_2_mafk.pdf'
                }
                target={'_blank'}
              >
                Оферта МАФК
              </a>
            </div>
            <div className={classes.link}>
              <a
                className={classes.a}
                href={
                  'https://profinansy-online.ru/files/oferta/black_friday2023/oferta_chp_paket_3_4_5_pitr.pdf'
                }
                target={'_blank'}
              >
                Оферта ПИТР
              </a>
            </div>
            <div className={classes.link}>
              <a
                className={classes.a}
                href={
                  'https://profinansy-online.ru/files/oferta/black_friday2023/oferta_chp_ezhenedelnik_mafk_translyatsiya.pdf'
                }
                target={'_blank'}
              >
                Оферта на заключение договора купли-продажи
              </a>
            </div>

            <div className={classes.link}>
              <a
                className={classes.a}
                href={'https://profinansy.ru/docs/user-agreement'}
                target={'_blank'}
              >
                Пользовательское соглашение
              </a>
            </div>

            <div className={classes.link}>
              <a
                className={classes.a}
                href={'https://profinansy.ru/docs/privacy-conditions'}
                target={'_blank'}
              >
                Условия предоставления информации
              </a>
            </div>

            <div className={classes.link}>
              <a
                className={classes.a}
                href={'https://profinansy.ru/privacy_policy'}
                target={'_blank'}
              >
                Политика обработки персональных данных
              </a>
            </div>

            <div className={classes.link}>
              <a
                className={classes.a}
                href={'https://disk.yandex.ru/i/z5H71ADNaefXBA'}
                target={'_blank'}
              >
                Свидетельство о государственной регистрации программы
              </a>
            </div>

            <div className={classes.link}>
              <a
                className={classes.a}
                href={'https://disk.yandex.ru/i/t0a23rAlvx1_cA'}
                target={'_blank'}
              >
                Выписка из единого реестра российских программ для ЭВМ
              </a>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default withStyles(style)(Footer)
