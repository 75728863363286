export const ListClubInformation = [
  {
    icon: 'icon-1.svg',
    text: 'Список <span>лучших акций</span> из всех отраслей. Всегда свежий'
  },
  {
    icon: 'icon-2.svg',
    text: '<span>Реальные сделки</span> в реальном портфеле. С аналитикой. Точкой входа и выхода'
  },
  {
    icon: 'icon-3.svg',
    text: 'Экономический календарь: за чем следить инвестору и как на этом заработать'
  },
  {
    icon: 'icon-4.svg',
    text: '<span>Аналитические разборы</span> акций в pdf на 15-20 страниц'
  },
  {
    icon: 'icon-5.svg',
    text: 'Ежемесячный инвест комитет: <span>куда пойдут рынки</span> в этом месяце'
  },
  {
    icon: 'icon-6.svg',
    text: 'Ежемесячный аналитический отчет: самое важное и куда пойдет рынок'
  },
  {
    icon: 'icon-7.svg',
    text: 'Еженедельный инвест апдейт: <span>за чем следить на неделе</span> и разбор новостей'
  },
  {
    icon: 'icon-8.svg',
    text: '<span>Разбор топ-вопросов от инвесторов</span> каждую неделю'
  },
  {
    icon: 'icon-9.svg',
    text: 'Эфиры «Американские горки»: <span>разбор фондового рынка США</span>'
  },
  {
    icon: 'icon-10.svg',
    text: 'Разбор реальных портфелей участников клуба: <span>как увеличить доход в реальном портфеле</span>'
  },
  {
    icon: 'icon-11.svg',
    text: 'Эфиры с приглашенными гостями для повышения ваших доходов: <span>аналитики, психологи, карьерные консультанты и другие</span>'
  }
]
