import classNames from 'classnames'
import React, { useState } from 'react'

import PayDialog from '../PayDialog'

import { useClubCardStyles } from './ClubCard.styles'
import { ListClubInformation } from './const'

interface IProps {
  title: string
  isForever: boolean
  newPrice: string
  oldPrice: string
  priceDescription: string
  paymentUrl: string
}

const ClubCard = ({
  title,
  isForever,
  newPrice,
  oldPrice,
  priceDescription,
  paymentUrl
}: IProps) => {
  const classes = useClubCardStyles()

  const [openDialog, setOpenDialog] = useState(false)

  const handleClickPay = () => {
    const el = document.getElementById(
      isForever ? 'payment_forever' : 'payment'
    )
    el.scrollIntoView({ behavior: 'auto', block: 'center' })
  }

  return (
    <div className={classes.container}>
      <PayDialog
        link1={paymentUrl[0]}
        link2={paymentUrl[1]}
        open={openDialog}
        onClose={setOpenDialog.bind(this, false)}
      />

      <div className={classes.header}>
        <span className={classes.subscribe}>Подписка на клуб pro.finansy</span>
        <span
          className={classNames(classes.title, {
            [classes.foreverTitle]: isForever
          })}
        >
          {title}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.priceBlock}>
          <span className={classes.newPrice}>
            {newPrice}
            {isForever && <span>в месяц</span>}
          </span>
          <span className={classes.oldPrice}>{oldPrice}</span>
        </div>
        <span
          className={classNames(classes.priceDescription, {
            [classes.foreverPriceDescription]: isForever
          })}
          dangerouslySetInnerHTML={{ __html: priceDescription }}
        />
        <button
          className={classNames(classes.button, {
            [classes.foreverButton]: isForever
          })}
          onClick={handleClickPay}
        >
          Вступить в клуб
        </button>
        {isForever && (
          <span
            className={classNames(classes.priceDescription, {
              [classes.foreverPriceDescription]: isForever
            })}
            style={{ marginTop: 16 }}
          >
            * Сумма рассчитана исходя из <br /> участия в Клубе от 10 лет
          </span>
        )}
        <span className={classes.listTitle}>Что входит в клубную подписку</span>
        {ListClubInformation.map((item) => (
          <div className={classes.listItem}>
            <img
              className={classes.listIcon}
              src={`/assets/club-card/${item.icon}`}
              alt="description"
            />
            <div
              className={classes.listText}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </div>
        ))}
        <div className={classes.giftContainer}>
          <span
            className={classNames(classes.giftTitle, {
              [classes.foreverGiftTitle]: isForever
            })}
          >
            Подарки
          </span>
          <img
            src={
              isForever
                ? '/assets/club-card/gifts-forever.png'
                : '/assets/club-card/gifts.png'
            }
            className={classes.giftImg}
            alt="gifts"
          />
          <div
            className={classNames(classes.giftList, {
              [classes.foreverGiftList]: isForever
            })}
          >
            {' '}
            {!isForever ? (
              <div className={classes.giftItem}>
                <img
                  style={{ width: 33, height: 25, marginRight: 24 }}
                  src={`/assets/club-card/mac.png`}
                  alt="gift"
                />
                <div className={classes.giftText}>
                  Курс «Как избежать ошибок в инвестировании».
                </div>
              </div>
            ) : (
              <>
                <div className={classes.giftItem}>
                  <img
                    style={{ width: 33, height: 25, marginRight: 38 }}
                    src={`/assets/club-card/mac.png`}
                    alt="gift"
                  />
                  <div className={classes.giftText}>
                    Курс «Как избежать ошибок в инвестировании».
                  </div>
                </div>
                <div className={classes.giftItem}>
                  <img
                    style={{ width: 38, height: 65, marginRight: 33 }}
                    src={`/assets/club-card/book.png`}
                    alt="gift"
                  />
                  <div className={classes.giftText}>
                    Карточки по фундаментальному анализу
                  </div>
                </div>
                <div className={classes.giftItem}>
                  <img
                    style={{ width: 58, height: 65, marginRight: 12 }}
                    src={`/assets/club-card/2-books.png`}
                    alt="gift"
                  />
                  <div className={classes.giftText}>
                    Карточки по техническому анализу
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {Array.isArray(paymentUrl) ? (
          <button
            id="payment"
            className={classNames(classes.button, {
              [classes.foreverButton]: isForever
            })}
            onClick={setOpenDialog.bind(this, true)}
          >
            Вступить в клуб
          </button>
        ) : (
          <a href={paymentUrl} target={'_blank'} style={{ width: '100%' }}>
            <button
              id="payment_forever"
              className={classNames(classes.button, {
                [classes.foreverButton]: isForever
              })}
            >
              Вступить в клуб
            </button>
          </a>
        )}
      </div>
    </div>
  )
}

export { ClubCard }
