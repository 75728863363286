import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'

import { getLocalStringNumber } from '../../../../utils/getLocalStringNamber'
import { addToCart } from '../../utils'

import { useCardStyles } from './CardMedium.styled'

interface IProps {
  data: {
    id: string
    key: string
    name: string
    image: string
    products: { name: string; price: number }[]
    description: string
    price: number
    oldPrice: number
    descriptionModal: string
    buttons: { type: string; subtext?: string }[]
  }
  isBig?: boolean
}

const CardMedium = ({ data, isBig }: IProps) => {
  const classes = useCardStyles({ isBig })

  const [openDescription, setOpenDescription] = useState(false)

  const handleOpenCart = () => {
    addToCart({
      name: data.name,
      price: data.price,
      key: data.key,
      id: data.id
    })
  }

  const getButtons = (item) => {
    if (item.type === 'discount') {
      return (
        <button className={classes.buttonDiscount} onClick={handleOpenCart}>
          Оплатить со скидкой
        </button>
      )
    }
    if (item.type === 'cart') {
      return (
        <button className={classes.buttonCart} onClick={handleOpenCart}>
          <img src="/assets/2024/cart.svg" />
          Добавить в корзину
        </button>
      )
    }

    if (item.type === 'tinkoff')
      return (
        <button className={classes.buttonTinkoff} onClick={handleOpenCart}>
          <img src="/assets/2024/tinkoff.svg" alt="tinkoff" />
          <div>
            <span className={classes.tinkoffText}>Оформить в рассрочку</span>
            <span className={classes.tinkoffSubText}>
              {item.subtext || 'от 3 500 ₽ в месяц'}
            </span>
          </div>
        </button>
      )
    return null
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.image}>
          <img src={data.image} />
        </div>

        <div className={classes.content}>
          <ul className={classes.list}>
            {data.products.map((item, index) => (
              <li className={classes.listItem} key={index}>
                <span className={classes.name}>- {item.name}</span>
                {item.price ? (
                  <span className={classes.price}>
                    {getLocalStringNumber(item.price)}&nbsp;р.
                  </span>
                ) : (
                  <div className={classes.new}>
                    <img src="/assets/2024/price.svg" />
                    <span>Подарок</span>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className={classes.contentContainer}>
            <div style={{ display: 'flex', gap: '2px', marginBottom: '20px' }}>
              <div>
                <p className={classes.description}>{data.description}</p>
                <button
                  className={classes.more}
                  onClick={() => setOpenDescription(true)}
                >
                  Подробнее о пакете
                </button>
              </div>
              <div className={classes.prices}>
                <span className={classes.oldPrice}>
                  {getLocalStringNumber(data.oldPrice)}&nbsp;р.
                </span>
                <span className={classes.newPrice}>
                  <img src="/assets/2024/price.svg" />
                  <span>{getLocalStringNumber(data.price)}&nbsp;р.</span>
                </span>
              </div>
            </div>

            <div className={classes.buttons}>
              {data.buttons.map((button, index) => (
                <React.Fragment key={index}>
                  {getButtons(button)}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth="md"
        open={!!openDescription}
        fullWidth
        onClose={() => setOpenDescription(null)}
      >
        <DialogContent>
          <div className={classes.modal}>
            <img className={classes.modalImage} src={data.image} />
            <div>
              <div
                className={classes.descriptionModal}
                dangerouslySetInnerHTML={{ __html: data.descriptionModal }}
              />
              <div
                style={{ justifyContent: 'flex-start' }}
                className={classes.prices}
              >
                <span className={classes.oldPrice}>
                  {getLocalStringNumber(data.oldPrice)}&nbsp;р.
                </span>
                <span className={classes.newPrice}>
                  <img src="/assets/2024/price.svg" />
                  <span>{getLocalStringNumber(data.price)}&nbsp;р.</span>
                </span>
              </div>
              <div className={classes.buttons}>
                {data.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {getButtons(button)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { CardMedium }
