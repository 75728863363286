export const BUTTONS_COURSES = [
  {
    key: '3_course',
    id: '640100:7000',
    name: 'Забрать 3 курса за 7 000 р',
    price: 7000
  },
  {
    key: '5_course',
    id: '640101:11000',
    name: 'Забрать 5 курсов за 11 000 р',
    price: 11000
  },
  {
    key: '12_course',
    id: '640102:15000',
    name: 'Забрать все 12 курсов за 15 000 р',
    price: 15000
  }
]
