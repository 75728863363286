import {
  Dialog,
  DialogContent,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import React from 'react'

import Button from './UI/Button'
import VicPng from '../VicPng.png'
import { DialogFall } from '../icons/ActivePoll'

interface Props extends WithStyles<typeof style> {
  info: any
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    title: {
      fontSize: 36,
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    buttons: {},
    item: {
      backgroundColor: '#181818',
      border: '1px solid #616161',
      borderRadius: 20,
      padding: '0 5px',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: 20,
      height: 115,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0'
    },
    fallTitle: {
      marginTop: 33,
      fontWeight: 700,
      fontSize: 22,
      color: '#242424'
    },
    fallTitleSub: {
      marginTop: 12,
      fontWeight: 400,
      fontSize: 22,
      color: '#242424',
      textAlign: 'center'
    },
    button: {
      marginTop: 24
    },
    img: {
      maxWidth: 300
    }
  })

class Victorina extends React.Component<Props> {
  state = {
    open: false,
    type: 'success',
    answerIndex: null
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <Dialog
          open={this.state.open}
          fullWidth
          onClose={() => {
            this.setState({
              open: false
            })
          }}
        >
          <div className={classes.dialogContent}>
            {this.state.type === 'wrong' ? (
              <>
                <DialogFall />
                <div className={classes.fallTitle}>Вы ответили неверно!</div>
                <div className={classes.fallTitleSub}>
                  Пожалуйста, попробуйте ещё раз
                </div>

                <div className={classes.button}>
                  <Button
                    onClick={() => {
                      this.setState({
                        open: false
                      })
                    }}
                  >
                    Хорошо
                  </Button>
                </div>
              </>
            ) : (
              <>
                <img src={VicPng} className={classes.img} />
                <div
                  className={classes.fallTitle}
                  style={{
                    marginTop: -20
                  }}
                >
                  Ваш ответ правильный
                </div>

                <div className={classes.fallTitleSub}>
                  {this.props.info.answerTrue}
                </div>

                <div className={classes.button}>
                  <a
                    href={this.props.info.link}
                    target={'_blank'}
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    <Button>Скачать</Button>
                  </a>
                </div>
              </>
            )}
          </div>
        </Dialog>

        <div className={classes.box}>
          <div className={classes.title}>{this.props.info.title}</div>

          <Grid container className={classes.buttons} spacing={4}>
            {this.props.info.variants.map((el, index) => {
              return (
                <Grid key={index} item xs={12} md={3}>
                  <div
                    style={{
                      backgroundColor:
                        this.state.answerIndex === index
                          ? el.is_valid
                            ? '#21AE51'
                            : '#AC2626'
                          : ''
                    }}
                    className={classes.item}
                    onClick={() => {
                      this.setState({
                        open: true,
                        type: el.is_valid ? 'success' : 'wrong',
                        answerIndex: index
                      })
                    }}
                  >
                    {el.title}
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </>
    )
  }
}

export default withStyles(style)(Victorina)
