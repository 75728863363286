import {
  Dialog,
  DialogContent,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

import Olya from '../../Olya.png'
import Payment from '../Payment'
import { DividerTariff } from '../../icons/ActivePoll'
import { PlusIcon } from '../../icons/ActivePoll'
import { Plans } from '../lib/Tariffs'

interface Props extends WithStyles<typeof style> {
  info: any
  onClick: any
  tariff: string
  isFull: boolean
  index: number
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    tariff: {
      borderRadius: 22,
      background: '#F7F6FB',
      padding: '0 36px 32px 36px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '0 18px 32px 18px'
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 9px 32px 9px'
      },
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        marginTop: 70
      }
    },
    tariffBig: {
      background: 'linear-gradient(0deg, #9669E3, #9669E3), #F7F6FB'
    },
    title: {
      color: '#201E1F',
      paddingTop: 104,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 26
    },
    icon: {
      background:
        'linear-gradient(135.41deg, #49484A -38.05%, #1C1818 103.07%)',

      border: '8.95862px solid #F7F6FB',
      position: 'absolute',

      height: 128.78,
      width: 128.78,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      left: '50%',
      marginLeft: -64.39,
      top: -64.39
    },
    iconBig: {
      borderColor: '#9669E3'
    },
    divider: {
      marginTop: 40,
      marginBottom: 18,
      '& > svg': {
        width: '100%'
      }
    },
    subTitle: {
      color: '#000',
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: 23,
      textAlign: 'center'
    },
    niceBlock: {
      backgroundColor: '#FFFFFF',
      borderRadius: 10,
      padding: 15,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 26
    },
    niceBlockTitle: {
      color: '#201E1F',
      fontSize: 20
    },
    niceBlockIcon: {},
    subBlock: {
      marginBottom: 23,
      color: '#000',
      fontSize: 20
    },
    emptyField: {
      height: 176,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    marketingPrice: {
      color: '#ABABB4',
      textDecorationLine: 'line-through',
      fontSize: 52,
      textAlign: 'center',
      fontWeight: 700
    },
    realPrice: {
      color: '#9669E3',
      marginTop: 2,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 30,
      marginBottom: 30
    },
    buttonPay: {
      borderRadius: 10,
      background:
        'radial-gradient(50% 50% at 50% 50%, #FDCC75 0%, #FDD98C 100%)',
      color: '#000',
      padding: '26px 0',
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 18,
      cursor: 'pointer',
      marginBottom: 40
    },
    buttonSubPay: {
      borderRadius: 10,
      background: '#fff',
      color: '#000',
      padding: '26px 0',
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 18,
      cursor: 'pointer',
      marginBottom: 40,
      border: '2.23966px solid #FEDA8D'
    },
    Olya: {
      width: 233,
      height: 233
    },
    OlyaWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    titleBig: {
      color: '#fff'
    },
    marketingPriceBig: {
      color: '#E6D8FF',
      marginTop: 10
    },
    realPriceBig: {
      color: '#FFFFFF'
    },
    niceBlockBig: {
      background: 'linear-gradient(0deg, #9669E3, #9669E3), #050405',
      '&>div': {
        color: '#fff !important'
      }
    },
    emptyFieldShort: {
      height: 30
    }
  })

const Banners = {}

class PlansWidget extends React.Component<Props> {
  state = {
    indexDialogOpen: null
  }

  onCloseDialog = () => {
    this.setState({
      indexDialogOpen: null
    })
  }

  openDialog = (index) => {
    this.setState({
      indexDialogOpen: index
    })
  }

  render() {
    const { classes } = this.props

    const info = this.props.info

    if (info.platformTariff) {
      return (
        <Payment
          info={info}
          tariff={this.props.tariff}
          onClick={this.props.onClick}
        />
      )
    }

    console.log(info.blocks);

    return (
      <div
        id={this.props.index === 0 ? this.props.tariff : undefined}
        className={cx(classes.tariff, {
          [classes.tariffBig]: !info.isBasic
        })}
      >
        <div
          className={cx(classes.icon, {
            [classes.iconBig]: !info.isBasic
          })}
        >
          <info.icon />
        </div>

        <div
          className={cx(classes.title, {
            [classes.titleBig]: !info.isBasic
          })}
        >
          {info.title}
        </div>

        <div className={classes.divider}>
          <DividerTariff />
        </div>

        <div className={classes.subTitle}>{info.subTitle}</div>

        {(info.blocks || []).map((el, index) => {
          if (el.type === 'dialog') {
            return (
              <React.Fragment key={index}>
                <div
                  className={classes.niceBlock}
                  onClick={this.openDialog.bind(this, index)}
                >
                  <div className={classes.niceBlockTitle}>{el.title}</div>
                  <div className={classes.niceBlockIcon}>
                    <PlusIcon />
                  </div>
                </div>

                <Dialog
                  open={this.state.indexDialogOpen === index}
                  onClose={this.onCloseDialog.bind(this, index)}
                  fullWidth
                >
                  <DialogContent>
                    <el.contentDialog
                      onClose={this.onCloseDialog.bind(this, index)}
                    />
                  </DialogContent>
                </Dialog>
              </React.Fragment>
            )
          }

          if (el.type === 'niceBlock') {
            return (
              <div
                key={index}
                className={classes.niceBlock}
                style={{
                  cursor: 'default'
                }}
              >
                <div className={classes.niceBlockTitle}>{el.title}</div>
              </div>
            )
          }

          if (el.type === 'niceBlockBig') {
            return (
              <div
                key={index}
                className={cx(classes.niceBlock, {
                  [classes.niceBlockBig]: true
                })}
                style={{
                  cursor: 'default'
                }}
              >
                <div className={classes.niceBlockTitle}>{el.title}</div>
              </div>
            )
          }

          if (el.type === 'subBlock') {
            return (
              <div key={index} className={classes.subBlock}>
                {el.block}
              </div>
            )
          }

          return null
        })}

        {info.isBasic ? (
          <div
            className={cx(classes.emptyField, {
              [classes.emptyFieldShort]: this.props.info.showDivider
            })}
          />
        ) : (
          <div className={classes.OlyaWrapper}>
            <img src={Olya} className={classes.Olya} />
          </div>
        )}

        <div
          className={cx(classes.marketingPrice, {
            [classes.marketingPriceBig]: !info.isBasic
          })}
        >
          {info.marketingPrice}₽
        </div>

        <div
          className={cx(classes.realPrice, {
            [classes.realPriceBig]: !info.isBasic
          })}
        >
          {info.userPrice}₽
        </div>

        <div
          className={classes.buttonPay}
          onClick={() => {
            this.props.onClick(this.props.tariff, undefined)
          }}
        >
          Оплатить тариф
        </div>

        {this.props.isFull ? (
          <div
            className={classes.buttonSubPay}
            onClick={() => {
              this.props.onClick(this.props.tariff, 'firstPay')
            }}
          >
            Воспользоваться поэтапной оплатой
          </div>
        ) : null}
      </div>
    )
  }
}

export default withStyles(style)(PlansWidget)
