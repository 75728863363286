import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'

//import J from 'proper-url-join';

//console.log( J('https://payment.profinansy.ru/796?potok=3', { query: { biz: 'buz', foo: 'bar' }}) );

ReactDOM.render(
  <BrowserRouter>

      {/*<div style={{
          padding: '56.25% 0 0 0',
          position: 'relative'
      }}>
          <iframe
              src="https://player.vimeo.com/video/885840988?h=a8bbf2328d&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
              frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
              style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
              }}></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>*/}

      <App />
  </BrowserRouter>,
  document.getElementById('root')
)
