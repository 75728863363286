import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import classNames from "classnames";
import {withUserContext, withUserContextProps} from "../context/userContext";
import QueryString from "qs";
import J from 'proper-url-join';
import PayDialog from "./Tabs/PayDialog";

interface Props extends WithStyles<typeof style>, withUserContextProps {
    data: Array<string>
}

const style = (theme: Theme) => createStyles({
    box: {
        maxWidth: 500,
        margin: '0 auto',
        marginTop: 50,
        marginBottom: 50
    },
    primaryBtn: {
        cursor: 'pointer',
        borderRadius: 10,
        padding: '25px 0',
        color: '#fff',
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '23px',
        width: '100%',
        border: 'none'
    },
    pinkSecondaryBg: {
        background: 'linear-gradient(180deg, #783A79 0%, #482748 100%)'
    },
    bluePrimaryBg: {
        backgroundColor: '#6777FF',
        boxShadow: '0px -2px 24px rgba(98, 151, 255, 0.44)'
    },
    blueSecondaryBg: {
        background: 'linear-gradient(180deg, #393F78 0%, #252845 100%)'
    },
});

const parse = QueryString.parse(window.location.search || '', {
    ignoreQueryPrefix: true
}) as any

delete parse.ukey;

class New_payment extends React.Component<Props> {

    state = {
        openDialog: false
    }

    setOpenDialog = (openDialog) => {
        this.setState({
            openDialog
        });
    }

    render() {

        const {classes} = this.props;

        const ukey = this.props.ukey;

        let link1, link2;

        if (this.props.data.length > 2) {
            link1 = J( J(this.props.data[1], {query: parse}), {query: {ukey}} );
            link2 = J( J(this.props.data[2], {query: parse}), {query: {ukey}} );
        } else {
            link1 = J( J(this.props.data[1], {query: parse}), {query: {ukey}} );
        }

        return (
            <div className={classes.box}>

                {
                    this.props.data.length > 2 ?
                        <PayDialog
                            link1={link1}
                            link2={link2}
                            open={this.state.openDialog}
                            onClose={this.setOpenDialog.bind(this, false)}
                        />
                        :
                        null
                }

                {
                    this.props.data.length === 2 ?
                        <a href={link1} target={'_blank'}>
                            <button
                                className={classNames(classes.primaryBtn, {
                                    [classes.pinkSecondaryBg]: true,
                                    [classes.blueSecondaryBg]: false
                                })}
                            >
                                {this.props.data[0]}
                            </button>
                        </a>
                        :
                        <button
                            className={classNames(classes.primaryBtn, {
                                [classes.pinkSecondaryBg]: true,
                                [classes.blueSecondaryBg]: false
                            })}
                            onClick={this.setOpenDialog.bind(this, true)}
                        >
                            {this.props.data[0]}
                        </button>
                }
            </div>
        )
    }
}

export default withUserContext(withStyles(style)(New_payment)) as any;