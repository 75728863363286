import { FC, useState } from 'react'

import PayDialog from '../PayDialog'
import { CardDetails } from '../Tabs'

import { usePlatformCardStyles } from './PlatformCard.styles'

export const PlatformCard: FC<CardDetails> = ({
  title,
  paymentUrl,
  price,
  offPrice,
  fullPrice,
  subtitlePrice,
  forever,
  buttonTitle
}) => {
  const classes = usePlatformCardStyles()

  const [openDialog, setOpenDialog] = useState(false)

  const handleClickPay = () => {
    const el = document.getElementById(`payment_${title.replaceAll(' ', '_')}`)
    el.scrollIntoView({ behavior: 'auto', block: 'center' })
  }

  return (
    <div className={classes.root}>
      <PayDialog
        link1={paymentUrl[0]}
        link2={paymentUrl[1]}
        open={openDialog}
        onClose={setOpenDialog.bind(this, false)}
      />

      <div className={classes.head}>
        <p className={classes.accessSubTitle}>Полный доступ к profinansy.ru</p>
        <p className={classes.mainTitle}>{title}</p>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.pricesHead}>
          <div className={classes.desktopPrice}>
            <span className={classes.title1}>{price}</span> {''}
            <span className={classes.fullPrice}>{fullPrice}</span>{' '}
            <span className={classes.title3}>/ месяц</span>
          </div>
          <div className={classes.mobilePrice}>
            <span className={classes.fullPrice}>{fullPrice}</span>{' '}
            <div>
              <span className={classes.title1}>{price}</span> {''}
              <span className={classes.title3}>/ месяц</span>
            </div>
          </div>
        </div>
        {!forever ? (
          <p className={classes.subtitle}>
            При оплате <b>{subtitlePrice} рублей</b> за полгода
          </p>
        ) : (
          <p
            className={classes.subtitle}
            style={{ marginBottom: forever ? 16 : 32 }}
          >
            При единовременной оплате{' '}
            <span style={{ textDecoration: 'line-through' }}> 60 000р.</span> 23
            780 рублей за доступ навсегда
          </p>
        )}
        <button className={classes.primaryBtn} onClick={handleClickPay}>
          {buttonTitle}
        </button>
        <p className={classes.contentTitle}>Доступно по подписке</p>
        <div className={classes.benefitsList}>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/1.png"
              alt=""
            />
            <p className={classes.descText}>
              Неограниченный доступ к урокам «Инвестология»:{' '}
              <b>100+ экспресс видеоуроков</b>
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/2.png"
              alt=""
            />
            <p className={classes.descText}>
              Курс «Школа начинающего инвестора»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/3.png"
              alt=""
            />
            <p className={classes.descText}>
              Ведение до 15 финансовых целей в разделе «Мои цели»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/4.png"
              alt=""
            />
            <p className={classes.descText}>
              Ведение безлимитного количества желаний в разделе «Мои желания»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/5.png"
              alt=""
            />
            <p className={classes.descText}>
              <b>Готовые подборки</b> лучших инструментов для инвестиций
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/6.png"
              alt=""
            />
            <p className={classes.descText}>
              Интерактивная статистика и аналитика{' '}
              <b>по вашим брокерским счетам и инвестиционным портфелям</b> в
              разделе «Портфели»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/7.png"
              alt=""
            />
            <p className={classes.descText}>
              Доступ к <b>готовым стратегиям по облигациям и акциям</b> в
              разделе «Календарь инвестора»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/8.png"
              alt=""
            />
            <p className={classes.descText}>
              Технологичный <b>удобный поиск инвестиций</b> в разделе «Поиск»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/9.png"
              alt=""
            />
            <p className={classes.descText}>Аналитика по 12 677 акциям</p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/10.png"
              alt=""
            />
            <p className={classes.descText}>Аналитика по 2 955 ETF</p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/11.png"
              alt=""
            />
            <p className={classes.descText}>
              Аналитика по 130 000 + облигациям всего мира
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/12.png"
              alt=""
            />
            <p className={classes.descText}>
              Доступ в аналитике к инструментам <b> 8-ми мировых бирж </b>из
              России, США, Китая и Европы
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/13.png"
              alt=""
            />
            <p className={classes.descText}>
              <b>Быстрый подбор инвестиций по фильтрам pro.finansy </b> - выбор
              только потенциально прибыльных компаний и инструментов для
              инвестиций
            </p>
          </div>
        </div>

        <p className={classes.contentTitle}>+ всё, что в бесплатном доступе</p>
        <div style={{ borderBottom: 'none' }} className={classes.benefitsList}>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/14.png"
              alt=""
            />
            <p className={classes.descText}>
              Ведение личного и семейного бюджета
            </p>
          </div>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/15.png"
              alt=""
            />
            <p className={classes.descText}>
              Полный обзор фондового рынка в разделе «Дашборд инвестора»
            </p>
          </div>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/16.png"
              alt=""
            />
            <p className={classes.descText}>
              1000+ статей о финансах и инвестициях в разделе «Лента»
            </p>
          </div>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/17.png"
              alt=""
            />
            <p className={classes.descText}>
              50+ файлов и инструкций в pdf о финансах и инвестициях в
              «Библиотеке инвестора»
            </p>
          </div>
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/18.png"
              alt=""
            />
            <p className={classes.descText}>
              Вебинар «Как управлять деньгами и умножать их при помощи
              profinansy.ru»
            </p>
          </div>
        </div>
        <p className={classes.giftsTitle}>ПОДАРКИ</p>
        <div style={{ borderBottom: 'none' }} className={classes.benefitsList}>
          <img
            alt=""
            src="/assets/platform-card-icons/Present.png"
            className={classes.present}
          />
          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/19.png"
              alt=""
            />
            <p className={classes.descText}>
              Неограниченный доступ к урокам «Инвестология»: 100+ экспресс
              видеоуроков
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/20.png"
              alt=""
            />
            <p className={classes.descText}>
              Готовый портфель с ежемесячными пополнениями на 3000р.
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/21.png"
              alt=""
            />
            <p className={classes.descText}>
              Урок «Как наемному сотруднику увеличить свой доход»
            </p>
          </div>

          <div className={classes.descBlock}>
            <img
              className={classes.icon}
              src="/assets/platform-card-icons/22.png"
              alt=""
            />
            <p className={classes.descText}>
              Урок "Как использовать инвестиции по максимуму"
            </p>
          </div>
        </div>

        {Array.isArray(paymentUrl) ? (
          <button
            id={`payment_${title.replaceAll(' ', '_')}`}
            className={classes.primaryBtn}
            onClick={setOpenDialog.bind(this, true)}
          >
            Купить {title}
          </button>
        ) : (
          <a href={paymentUrl} target={'_blank'}>
            <button
              className={classes.primaryBtn}
              id={`payment_${title.replaceAll(' ', '_')}`}
            >
              Подключить {title}
            </button>
          </a>
        )}
      </div>
    </div>
  )
}
