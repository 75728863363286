import {
  InputBase,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import CookiesLib from 'browser-cookies'
import React from 'react'

import { setComment } from '../api/api'
import { withUserContext, withUserContextProps } from '../context/userContext'
import { SendMessage } from '../icons/SendMessage'

import { EnterNameModal } from './Auth/EnterNameModal'
import { withMainContext, withMainContextProps } from './lib'

interface Props
  extends WithStyles<typeof style>,
    withMainContextProps,
    withUserContextProps {
  newComment: any
  isAdmin: any
  ukey: string
}

const style = (theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: '#2F3030',
      height: 40,
      position: 'relative'
    },
    input: {
      height: '100%',
      paddingLeft: 25,
      color: '#fff',
      paddingRight: 80
    },
    actions: {
      position: 'absolute',
      right: 15,
      top: 0,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    sendMessage: {
      cursor: 'pointer'
    },
    inputProps: {
      padding: 0
    },
    maxCount: {
      position: 'absolute',
      top: 10,
      right: 50,
      color: '#A1A0A3'
    },
    timer: {
      top: 10,
      left: 20,
      position: 'absolute',
      color: '#A1A0A3'
    }
  })

const MAX_SIZE = 120

class InputComment extends React.Component<Props> {
  state = {
    input: '',
    timer: null,
    nameModalVisible: false
  }

  timer: any

  request: any

  sendMessage = () => {
    // let finalName = this.props.userName
    const name = localStorage.getItem('nameBlackFriday')
    if (!name) {
      this.setState({ nameModalVisible: true })
      return
    }

    const comment = this.state.input
    this.request = setComment(
      {
        comment: this.state.input,
        name: name
      },
      this.props.ukey
    )
    this.request.then((data) => {
      this.props.newComment({
        comment,
        id: data.data,
        name: name
      })
      this.setState({
        input: '',
        timer: Date.now()
      })
    })
  }

  onChange = (e: any) => {
    if (e.target.value.length > MAX_SIZE) return

    this.setState({
      input: e.target.value
    })
  }

  keyPress = (e) => {
    if (e.which === 13 || e.key === 'Enter') {
      this.sendMessage()
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.forceUpdate()
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { classes } = this.props

    const now = Date.now()

    const needCalc = this.state.timer + 60000 - now

    const minutes = Math.floor(needCalc / 60000)
    let seconds = Math.floor((needCalc - minutes * 60000) / 1000) as any

    if (seconds < 10) {
      seconds = '0' + seconds
    }

    return (
      <div className={classes.box}>
        {this.state.nameModalVisible && (
          <EnterNameModal
            open={this.state.nameModalVisible}
            onClose={() => this.setState({ nameModalVisible: false })}
            handleSubmit={(name) => {
              CookiesLib.set('translation_name', name)
              localStorage.setItem('nameBlackFriday', name)
              this.props.setUserData((prev) => ({
                ...prev,
                userMetadata: { ...prev.userMetadata, name }
              }))
              this.sendMessage()
              this.setState({ nameModalVisible: false })
            }}
          />
        )}
        {needCalc > 0 && !this.props.isAdmin ? (
          <div className={classes.timer}>{`${minutes}:${seconds}`}</div>
        ) : (
          <InputBase
            className={classes.input}
            placeholder={'Комментировать'}
            value={this.state.input}
            onChange={this.onChange}
            fullWidth
            onKeyPress={this.keyPress}
            inputProps={{
              className: classes.inputProps
            }}
          />
        )}

        <div className={classes.maxCount}>
          {MAX_SIZE - (this.state.input || '').length}
        </div>

        <div className={classes.actions}>
          <SendMessage
            onClick={this.sendMessage}
            className={classes.sendMessage}
          />
        </div>
      </div>
    )
  }
}

export default withUserContext(
  withMainContext(withStyles(style)(InputComment))
) as any
