import {
  InputBase,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import React from 'react'

import { answerAverage, answerPoll } from '../../api/api'
import { withUserContext, withUserContextProps } from '../../context/userContext'
import Button from '../UI/Button'
import { GLOBAL_STORE } from '../lib'

interface Props extends WithStyles<typeof style>, withUserContextProps {
  info: any
  resetPolls: any
  isAdmin: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {
      maxWidth: 600,
      margin: '0 auto'
    },
    title: {
      fontSize: 36,
      fontWeight: 700,
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    inputWrapper: {
      backgroundColor: '#181818',
      border: '1px solid #616161',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20
    },
    input: {
      color: '#fff',
      height: '100%',
      padding: 16,
      '& input': {
        fontSize: 16
      }
    },
    metric: {
      fontWeight: 400,
      fontSize: 24,
      color: '#B3B3B3',
      marginLeft: 10
    },
    averageCount: {
      borderRadius: 5,
      backgroundColor: '#6553F5',
      padding: '10px 15px',
      fontSize: 24,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputProps: {
      padding: 0
    },

    adminBox: {
      backgroundColor: '#181818',
      borderRadius: 20,
      width: 550,
      height: 550,
      marginBottom: 30,
      overflow: 'hidden',
      padding: '0 56px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    adminTitle: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: '43px',
      marginBottom: 25
    },
    adminValue: {
      color: '#6553F5',
      fontSize: 60,
      fontWeight: 700
    }
  })

class Average extends React.Component<Props> {
  state = {
    value: ''
  }

  onChange = (e) => {
    const val = +e.target.value

    if (isNaN(+val)) return

    this.setState({
      value: e.target.value
    })
  }

  onSave = () => {
    if (GLOBAL_STORE[this.props.info.id] !== undefined) return

    const amount = +this.state.value

    answerAverage(
      {
        id: this.props.info.id,
        amount
      },
      this.props.ukey
    )
      .then(() => {
        this.props.resetPolls(this.props.info.id, amount)
      })
      .catch((e) => {})

    GLOBAL_STORE[this.props.info.id] = amount
    this.forceUpdate()
  }

  render() {
    const { classes } = this.props

    if (this.props.isAdmin) {
      return (
        <div className={classes.adminBox}>
          <div className={classes.adminTitle}>
            {this.props.info.return_title}
          </div>

          <div className={classes.adminValue}>
            {`${this.props.info.rating.toLocaleString('ru-RU')}${
              this.props.info.metric
            }`}
          </div>
        </div>
      )
    }

    return (
      <div className={classes.box}>
        <div className={classes.title}>
          {GLOBAL_STORE[this.props.info.id] === undefined
            ? this.props.info.title
            : this.props.info.return_title}
        </div>

        {GLOBAL_STORE[this.props.info.id] !== undefined ? (
          <div className={classes.averageCount}>
            {`${this.props.info.rating}${this.props.info.metric}`}
          </div>
        ) : null}

        {GLOBAL_STORE[this.props.info.id] === undefined ? (
          <div className={classes.inputWrapper}>
            <div className={classes.metric}>{this.props.info.metric}</div>
            <InputBase
              fullWidth
              className={classes.input}
              value={this.state.value}
              placeholder={this.props.info.placeholder}
              onChange={this.onChange}
              inputProps={{
                className: classes.inputProps
              }}
            />
          </div>
        ) : null}

        {GLOBAL_STORE[this.props.info.id] === undefined ? (
          <Button onClick={this.onSave}>Отправить</Button>
        ) : null}
      </div>
    )
  }
}

export default withUserContext(withStyles(style)(Average)) as any
