import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'

import MPU from '../../MPU.png'
import PlansWidget from '../PlansWidget'
import Button from '../UI/Button'
import { withMainContext, withMainContextProps } from '../lib'
import { Plans } from '../lib/Tariffs'

interface Props extends WithStyles<typeof style>, withMainContextProps {
  data: any
  imm: boolean
}

const style = (theme: Theme) =>
  createStyles({
    box: {},
    yellowButton: {
      background:
        'radial-gradient(50% 50% at 50% 50%, #DBAA52 0%, #F1D18E 100%)',
      boxShadow: '0px 4.47931px 0px #A08349',
      borderRadius: '11.1983px',
      fontWeight: 700,
      fontSize: 20,
      color: '#050405',
      padding: '32px',
      textAlign: 'center',
      cursor: 'pointer'
    },
    primaryButton: {
      background:
        'radial-gradient(115.17% 98.6% at 50% 50.35%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%), linear-gradient(86.07deg, #AB81F4 -10.57%, #6553F5 106.55%)',
      boxShadow: '0px 4.47931px 0px #725D97',
      borderRadius: '11.1983px',
      fontWeight: 700,
      fontSize: 20,
      color: '#FFFFFF',
      padding: '32px',
      textAlign: 'center',
      cursor: 'pointer'
    },
    container: {
      justifyContent: 'center'
    },
    buttonsPay: {},
    buttonPay: {
      marginBottom: 30,
      maxWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    link: {
      textDecoration: 'none'
    },
    bigButton: {
      textAlign: 'center',
      cursor: 'pointer',
      padding: '40px 0',
      fontWeight: 700,
      fontSize: 32,
      color: '#fff',
      background:
        'radial-gradient(115.17% 98.6% at 50% 50.35%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%), linear-gradient(86.07deg, #AB81F4 -10.57%, #6553F5 106.55%)',
      boxShadow: '0px 4.47931px 0px #725D97',
      borderRadius: '11.1983px'
    },
    immBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#F8F8F8',
      fontSize: 36,
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '&>img:last-child': {
          display: 'none'
        }
      }
    },
    saleText: {
      marginLeft: 50,
      marginRight: 50,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
        textAlign: 'center'
      }
    },
    personal: {
      background:
        'radial-gradient(115.17% 98.6% at 50% 50.35%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%), radial-gradient(50% 50% at 50% 50%, #DBAA52 0%, #F1D18E 100%)',
      color: '#000',
      boxShadow: '0px 4.47931px 0px #725D97',
      borderRadius: '11.1983px',
      padding: '38px 0',
      fontWeight: 700,
      fontSize: 32,
      textAlign: 'center',
      cursor: 'pointer'
    },
    linkA: {
      textDecoration: 'none'
    },
    containerCenter: {
      alignItems: 'center'
    }
  })

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

class PayButtons extends React.Component<Props> {
  state = {
    open: false,
    type: null,
    frames: [],

    immShow: null
  }

  interval: any

  _tick = () => {
    if (this.state.immShow <= Date.now()) {
      clearInterval(this.interval)
      this.setState({
        immShow: null
      })
    }
  }

  componentDidMount() {
    if (this.props.imm) {
      this.setState({
        immShow: Date.now() + randomIntFromInterval(0, 60) * 1000
      })

      this.interval = setInterval(() => {
        this._tick()
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  buttonClick = (tariff, type?: string) => {
    const infoPlan = Plans[tariff]

    const balance = this.props.user.balance || 0

    const frames = infoPlan.balances[type || balance] || []

    const newLinks = []

    frames.forEach((frame) => {
      const email = encodeURIComponent(this.props.user.email)
      const name = encodeURIComponent(this.props.user.name)

      const link = `${frame}?email_default=${email}&fio_default=${name}`

      newLinks.push(link)
    })

    this.setState({
      open: true,
      type: tariff,
      frames: newLinks
    })
  }

  render() {
    const { classes } = this.props

    if (this.state.immShow) {
      return (
        <div className={classes.immBlock}>
          <img src={MPU} />
          <div className={classes.saleText}>Продажи вот-вот откроются</div>
          <img src={MPU} />
        </div>
      )
    }

    let isCenter = false

    try {
      if (this.props.data && this.props.data[0] && this.props.data[0].tariff) {
        if (Plans[this.props.data[0].tariff]) {
          const info = Plans[this.props.data[0].tariff]

          if (info && info.platformTariff) {
            isCenter = true
          }
        }
      }
    } catch (e) {}

    return (
      <div className={classes.box}>
        <Dialog
          open={this.state.open}
          fullWidth
          onClose={() => {
            this.setState({
              open: false
            })
          }}
        >
          <DialogTitle>Выберите тип оплаты</DialogTitle>
          <DialogContent>
            <div className={classes.buttonsPay}>
              <a
                href={this.state.frames[0]}
                target={'_blank'}
                className={classes.link}
              >
                <Button className={classes.buttonPay}>Российская карта</Button>
              </a>

              <a
                href={this.state.frames[1]}
                target={'_blank'}
                className={classes.link}
              >
                <Button className={classes.buttonPay}>Иностранная карта</Button>
              </a>
            </div>
          </DialogContent>
        </Dialog>

        <Grid
          container
          className={cx(classes.container, {
            [classes.containerCenter]: isCenter
          })}
          spacing={3}
        >
          {this.props.data.map((el, index) => {
            if (el.type === 'button_pay_individual') {
              return (
                <Grid item xs={12}>
                  {el.type === 'button_pay_individual' ? (
                    <a
                      href={el.tariff}
                      target={'_blank'}
                      className={classes.linkA}
                    >
                      <div className={classes.personal}>
                        Хочу учиться персонально
                      </div>
                    </a>
                  ) : null}
                </Grid>
              )
            }

            if (!Plans[el.tariff]) return null

            const infoPlan = Plans[el.tariff]

            return (
              <Grid
                item
                xs={12}
                md={
                  el.type === 'bron' || el.type === 'button_pay_individual'
                    ? 12
                    : 4
                }
                key={index}
              >
                {el.type === 'bron' ? (
                  <div
                    className={classes.bigButton}
                    onClick={this.buttonClick.bind(this, el.tariff, 'bron')}
                  >
                    Внести бронь 2000₽ и сохранить цену и подарки
                  </div>
                ) : null}

                {el.type === 'button_pay' ? (
                  <div
                    id={index === 0 ? el.tariff : undefined}
                    className={cx({
                      [classes.yellowButton]: infoPlan.isBasic,
                      [classes.primaryButton]: !infoPlan.isBasic
                    })}
                    onClick={this.buttonClick.bind(this, el.tariff, undefined)}
                  >
                    оплатить тариф
                    <br />"{infoPlan.title}"
                  </div>
                ) : null}

                {el.type === 'tarif' ? (
                  <PlansWidget
                    info={infoPlan}
                    onClick={this.buttonClick}
                    tariff={el.tariff}
                    isFull={el.isFull}
                    index={index}
                  />
                ) : null}

                  {el.type === 'tarif_new' ? (
                      <>

                      </>
                  ) : null}
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

export default withMainContext(withStyles(style)(PayButtons)) as any
