import classNames from 'classnames'
import { FC, useState } from 'react'

import PayDialog from '../PayDialog'
import { CardDetails } from '../Tabs'

import { useMethodCardStyles } from './MethodCard.styles'
import { tariffGiftsList, tariffIncludesList } from './constant'

export const MethodCard: FC<CardDetails> = ({
  bg,
  titleIcon,
  offPrice,
  personalTariff,
  name,
  fullPrice,
  payNow,
  payFull,
  takePlace,
  payPart,
    buttons
}) => {

  const classes = useMethodCardStyles()

  const [openDialog, setOpenDialog] = useState(false)

  const handleClickPay = () => {
    const el = document.getElementById(
      personalTariff ? 'payment_personal' : 'payment'
    )
    el.scrollIntoView({ behavior: 'auto', block: 'center' })
  }

  const buttonsMap = {} as any;

  buttons.forEach(item => {
      buttonsMap[item] = true;
  })

  return (
    <div className={classes.root}>
      <PayDialog
        link1={payPart[0]}
        link2={payPart[1]}
        open={openDialog}
        onClose={setOpenDialog.bind(this, false)}
      />

      <div className={classes.content}>
        <div className={classes.titleBlock}>
          <img
            alt=""
            className={classes.titleIcon}
            src={'/assets/' + titleIcon}
          />
          <p className={classes.mainTitle}>{name}</p>
        </div>
        <div className={classes.pricesBlock}>
          <p className={classes.offPrice}>{offPrice}</p>
          <p className={classes.fullPrice}>{fullPrice}</p>
        </div>
        <button
          onClick={handleClickPay}
          className={classNames(classes.primaryBtn, {
            [classes.pinkPrimaryBg]: !personalTariff,
            [classes.bluePrimaryBg]: personalTariff
          })}
        >
          Оплатить сейчас
        </button>
        <div className={classes.accessDescBlock}>
          <div className={classes.accessBlockHead}>
            <p className={classes.accessBlockTitle}>
              БОНУС - ДОСТУП К PROFINANSY.RU
            </p>
            <img alt="" src="/assets/card-icons/fire.png" />
          </div>
          <div className={classes.descBlock}>
            <img
              className={classes.iconLeft}
              src="/assets/card-icons/icon-1.png"
              alt=""
            />
            <p className={classes.classicDescription}>
              Доступ к платному функционалу profinansy.ru и приложению
              pro.finansy - 6 месяцев
            </p>
            <img src="/assets/card-icons/tick.png" alt="" />
          </div>
        </div>
        <div className={classes.tariffIncludes}>
          <p className={classes.tariffIncludesTitle}>ВХОДИТ В ТАРИФ</p>
          <div className={classes.tariffIncludesList}>
            {tariffIncludesList.map(({ desc, icon }) => (
              <div className={classes.descBlock}>
                <img
                  className={classes.iconLeft}
                  src={'/assets/card-icons/' + icon}
                  alt=""
                />
                <p className={classes.classicDescription}>{desc}</p>
                <img src="/assets/card-icons/tick.png" alt="" />
              </div>
            ))}
          </div>
          <div className={classes.giftsBlock}>
            <img
              src="/assets/card-icons/gifts-group.png"
              className={classes.giftsGroup}
              alt=""
            />
            <p className={classes.giftsTitle}>ПОДАРКИ</p>
            <div className={classes.tariffIncludesList}>
              {tariffGiftsList.map(({ desc, icon }) => (
                <div className={classes.descBlock}>
                  <img
                    className={classes.iconLeft}
                    src={'/assets/card-icons/' + icon}
                    alt=""
                  />
                  <p className={classes.classicDescription}>{desc}</p>
                  <img src="/assets/card-icons/tick.png" alt="" />
                </div>
              ))}
            </div>
            <div className={classes.bonusesBlock}>
              <p
                className={classNames(
                  classes.tariffIncludesTitle,
                  classes.bonusesTitle
                )}
              >
                БОНУСЫ
              </p>
              <div className={classes.bonusesList}>
                <div className={classes.descBlock}>
                  <img
                    className={classes.iconLeft}
                    src={'/assets/card-icons/dot.png'}
                    alt=""
                  />
                  <p className={classes.classicDescription}>
                    Ежедневные рекомендации в чатах от карьерного консультанта и
                    финансового психолога «Как пробить финансовый потолок»
                  </p>
                  <img src="/assets/card-icons/tick.png" alt="" />
                </div>
                <div className={classes.descBlock}>
                  <img
                    className={classes.iconLeft}
                    src={'/assets/card-icons/dot.png'}
                    alt=""
                  />
                  <p className={classes.classicDescription}>
                    1 групповая сессия-разбор с карьерным консультантом
                  </p>
                  <img src="/assets/card-icons/tick.png" alt="" />
                </div>
                <div className={classes.descBlock}>
                  <img
                    className={classes.iconLeft}
                    src={'/assets/card-icons/dot.png'}
                    alt=""
                  />
                  <p className={classes.classicDescription}>
                    1 групповая сессия-разбор с карьерным консультантом
                  </p>
                  <img src="/assets/card-icons/tick.png" alt="" />
                </div>
                {personalTariff ? (
                  <>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold
                        )}
                      >
                        <span className={classes.bold}>
                          <span className={classes.boldBlue}>5 созвонов </span>с
                          личным финансовым консультантом
                        </span>
                      </p>
                      <img src="/assets/card-icons/tick.png" alt="" />
                    </div>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold
                        )}
                      >
                        <span className={classes.bold}>
                          <span className={classes.boldBlue}>
                            Индивидуальный{' '}
                          </span>
                          с чат с финансовым консультантом
                        </span>{' '}
                        5 дней в неделю в течение всего курса
                      </p>
                      <img src="/assets/card-icons/tick.png" alt="" />
                    </div>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold
                        )}
                        id={personalTariff ? 'payment_personal' : 'payment'}
                      >
                        <span className={classes.bold}>
                          Составление{' '}
                          <span className={classes.boldBlue}>
                            {' '}
                            индивидуальной инвестиционной стратегии{' '}
                          </span>{' '}
                          (максимум 2 цели)
                        </span>
                      </p>
                      <img src="/assets/card-icons/tick.png" alt="" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold,
                          classes.lineThrough
                        )}
                      >
                        5 созвонов личным финансовым консультантом
                      </p>
                      <img src="/assets/card-icons/minus.png" alt="" />
                    </div>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold,
                          classes.lineThrough
                        )}
                      >
                        Индивидуальный с чат с финансовым консультантом 5 дней в
                        неделю в течение всего курса
                      </p>
                      <img src="/assets/card-icons/minus.png" alt="" />
                    </div>
                    <div className={classes.descBlock}>
                      <img
                        className={classes.iconLeft}
                        src={'/assets/card-icons/dot.png'}
                        alt=""
                      />
                      <p
                        className={classNames(
                          classes.classicDescription,
                          classes.bold,
                          classes.lineThrough
                        )}
                        id={personalTariff ? 'payment_personal' : 'payment'}
                      >
                        Составление индивидуальной инвестиционной стратегии
                        (максимум 2 цели)
                      </p>
                      <img src="/assets/card-icons/minus.png" alt="" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
          {
              buttonsMap.full ? <div>
                  <a
                      href={payFull}
                      target={'_blank'}
                  >
                      <button
                          className={classNames(classes.primaryBtn, {
                              [classes.pinkPrimaryBg]: !personalTariff,
                              [classes.bluePrimaryBg]: personalTariff
                          })}
                      >
                          Оплатить полностью
                      </button>
                  </a>
              </div>
                  :
                  null
          }
          {
              buttonsMap.bron ?
                  <div style={{ marginTop: 20 }}>
                      <a href={takePlace} target={'_blank'}>
                          <button
                              className={classNames(classes.primaryBtn, {
                                  [classes.pinkSecondaryBg]: !personalTariff,
                                  [classes.blueSecondaryBg]: personalTariff
                              })}
                          >
                              Забронировать место
                          </button>
                      </a>
                  </div>
                  :
                  null
          }
          {
              buttonsMap.etap ?
                  <div className={classes.subBtns}>
                      <button
                          className={classNames(classes.primaryBtn, {
                              [classes.pinkSecondaryBg]: !personalTariff,
                              [classes.blueSecondaryBg]: personalTariff
                          })}
                          onClick={() => {
                              setOpenDialog(true)
                          }}
                      >
                          Поэтапная оплата
                      </button>
                  </div>
                  :
                  null
          }
      </div>

      <img src={'/assets/' + bg} alt="" className={classes.bgImg} />
    </div>
  )
}
