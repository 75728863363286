import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import Button from '../UI/Button'

import Timer from './timer'

interface Props extends WithStyles<typeof style> {
  info: any
  tariff: string
  onClick: any
}

const style = (theme: Theme) =>
  createStyles({
    box: {
      marginTop: 72,
      paddingBottom: 40
    },
    plansContainer: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      background: `url(/Payment/HeaderBackground.png)`,
      backgroundColor: '#F5EEF8',
      paddingTop: 60
    },
    infoContainer: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: `url(/Payment/LineBg.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      paddingTop: 60
    },
    flex: {
      display: 'flex'
    },
    iframe: {
      width: '100%',
      height: 700,
      border: 0,
      margin: 0,
      padding: 0,
      marginBottom: -10
      // marginTop: 70
    },
    bigTitle: {
      fontWeight: 700,
      fontSize: 48,
      color: '#242424',
      textAlign: 'center',
      marginBottom: 24
    },
    title: {
      fontWeight: 400,
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 32,
      color: '#242424'
    },
    timerBox: {
      width: 'fit-content',
      minHeight: 62,
      background: '#6553F5',
      marginBottom: 30,
      borderRadius: 80,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 10px 0 40px',
      alignItems: 'center'
    },
    timerTitle: {
      fontWeight: 700,
      fontSize: 16,
      color: '#FFFFFF',
      marginRight: 40
    },
    timer: {
      background: '#F4F5FF',
      borderRadius: 80,
      padding: '5px 40px',
      width: 203
    },
    card: {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      border: '1px solid #E0E0E0',
      borderRadius: 12
    },
    cardHeaderTitle: {
      paddingLeft: 28,
      fontWeight: 500,
      fontSize: 20
    },
    imgHeader: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    cardItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12,
      '&:last-child': {
        marginBottom: 0
      }
    },
    plans: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    cardWrapper: {
      width: '100%',
      maxWidth: 400,
      height: 400,
      borderRadius: 20,
      border: '1px solid #DADADA ',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '28px 23px',
      marginTop: 30,
      cursor: 'default'
    },
    cardInactive: {
      opacity: 0.6
    },
    cardHeader: {
      borderBottom: '1px solid #DADADA',
      paddingBottom: 24,
      width: '100%',
      position: 'relative'
    },
    textDescription: {
      color: '#5E5E5E',
      fontSize: 14,
      fontWeight: 400,
      margin: 0
    },
    headerInner: {
      color: '#5E5E5E',
      fontSize: 24,
      fontWeight: 700,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      marginTop: 6,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    },
    headerDiscount: {
      color: '#6553F5',
      background: '#E2DEFF',
      borderRadius: 20,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 700,
      margin: 0,
      padding: '6px 12px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    cardBody: {
      paddingTop: 24,
      width: '100%'
    },
    planPrice: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      margin: '12px 0 32px 0',
      justifyContent: 'space-between'
    },
    planPriceNew: {
      fontSize: 36,
      fontWeight: 700,
      color: '#242424',
      margin: 0
    },
    planPriceOld: {
      fontSize: 18,
      fontWeight: 500,
      color: '#5E5E5E',
      opacity: 0.4,
      textDecoration: 'line-through',
      margin: 0,
      width: '100%',
      textAlign: 'center'
    },
    cardButton: {
      cursor: 'pointer',
      background: '#6553F5',
      borderRadius: 6,
      width: '100%',
      color: '#FFFFFF',
      fontSize: 15,
      fontWeight: 500,
      padding: '13px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '28px 0'
    },
    cardButtonInactive: {
      background: '#898989',
      cursor: 'default'
    },
    economy: {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      margin: '4px 0 0 0',
      alignItems: 'flex-end'
    },
    textDescriptionEconomy: {
      color: '#C56F88',
      fontSize: 14,
      fontWeight: 400,
      margin: '0 4px 0 0'
    },
    specialCardWrapper: {
      width: '100%',
      maxWidth: 400,
      height: 'fit-content',
      background: `linear-gradient(180deg, #4A4A4A 0%, #151515 100%)`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderRadius: 20,
      border: '4px solid #6553F5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // padding: '28px 32px',
      marginTop: 30,
      overflow: 'hidden'
    },
    specialCardFooter: {
      margin: '20px 0 0 0',
      color: '#494949',
      fontSize: 14,
      fontWeight: 400
    },
    specialTextDescription: {
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 400,
      margin: 0
    },
    specialHeaderInner: {
      color: '#FFFFFF',
      fontSize: 24,
      fontWeight: 700,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      marginTop: 6,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    },
    specialHeaderDiscount: {
      color: '#D84952',
      background: '#FFE7EE',
      borderRadius: 20,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 700,
      margin: 0,
      padding: '6px 12px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    specialPlanPriceNew: {
      fontSize: 36,
      fontWeight: 700,
      color: '#FFFFFF',
      margin: 0
    },
    specialPlanPriceOld: {
      fontSize: 18,
      fontWeight: 500,
      color: '#FFFFFF',
      opacity: 0.4,
      textDecoration: 'line-through',
      margin: 0
    },
    specialTextDescriptionFooter: {
      color: '#898989',
      fontSize: 14,
      fontWeight: 400,
      margin: 0
    },
    aboutSubscription: {
      fontSize: 20,
      fontWeight: 700,
      width: '100%',
      textAlign: 'center',
      margin: '11px 0 48px 0',
      color: '#A296FF',
      cursor: 'pointer'
    },
    headerTimerWrapper: {
      background: '#6553F5',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      width: '100%',
      padding: '8px 18px'
    },
    headerTimerIndicator: {
      background: '#A296FF',
      borderRadius: 80,
      padding: '2px 8px',
      width: 83
    },
    specialCardWrap: {
      width: '100%',
      padding: '0 23px'
    },
    specialCardHeader: {
      borderBottom: '1px solid #DADADA',
      width: '100%',
      position: 'relative',
      padding: '28px 0 24px 0'
    },
    specialCardBody: {
      width: '100%',
      padding: '24px 32px 28px 32px'
    },
    specialTextDescriptionTimer: {
      color: '#FFFFFF',
      fontSize: 11,
      fontWeight: 400,
      margin: 0
    },
    banner: {
      width: '100vw',
      background: `url(/Payment/Banner.png)`,
      height: 80,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: '#F5EEF8'
    },
    bannerTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center'
    },
    bannerTitleWhite: {
      margin: '18px 0 4px 0'
    },
    bannerTitlePurple: {
      color: '#C9C3FC',
      margin: '18px 0 4px 4px'
    },
    bannerSubtitle: {
      color: '#C9C3FC',
      fontSize: 14,
      fontWeight: 400,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: 18
    },
    slide: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: '0 0 0 100px'
    },
    slideSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    slideSectionInfo: {
      display: 'flex',
      flexDirection: 'column'
    },
    slideTitle: {
      fontSize: 40,
      fontWeight: 700,
      color: '#242424'
    },
    slideText: {
      fontSize: 16,
      fontWeight: 400,
      color: '#242424',
      whiteSpace: 'nowrap'
    },
    slideCards: {
      display: 'grid',
      gridTemplateColumns: '200px 200px'
    },
    slideCard: {
      display: 'flex',
      flexDirection: 'column',
      width: 200,
      margin: 20
    },
    slideCardImg: {
      width: 'fit-content'
    },
    slideImg: {
      width: '40%'
    },
    bold: {
      fontWeight: 700,
      margin: 0
    },
    purple: {
      color: '#6553F5',
      margin: 0
    },
    wrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })

class Payment extends React.Component<Props> {
  Menus = []

  constructor(props: Props) {
    super(props)

    const classes = this.props.classes
  }

  state = {
    open: false,
    plan: null
  }

  render() {
    const { classes } = this.props

    const info = this.props.info

    if (info.premiumCard) {
      return (
        <div className={classes.wrap}>
          <div className={classes.specialCardWrapper}>
            <div className={classes.headerTimerWrapper}>
              <div className={classes.specialTextDescriptionTimer}>
                Предложение действует только во время эфира
              </div>
            </div>
            <div className={classes.specialCardWrap}>
              <div className={classes.specialCardHeader}>
                <p className={classes.specialTextDescription}>
                  Доступ к платформе
                </p>
                <div className={classes.specialHeaderInner}>
                  {info.subTitle}
                  <p className={classes.specialHeaderDiscount}>
                    скидка {info.sale}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.specialCardBody}>
              <p className={classes.specialTextDescription}>
                Единоразовая оплата:
              </p>
              <div className={classes.planPrice}>
                <p className={classes.specialPlanPriceNew}>
                  {info.userPrice.toLocaleString('ru-RU')}₽
                </p>
                <p className={classes.specialPlanPriceOld}>
                  {info.marketingPrice.toLocaleString('ru-RU')}₽
                </p>
              </div>
              <div
                onClick={this.props.onClick.bind(
                  this,
                  this.props.tariff,
                  undefined
                )}
                className={classes.cardButton}
              >
                Подключить
              </div>
              <p className={classes.specialTextDescriptionFooter}>
                Сумма за месяц: до {info.monthPrice.toLocaleString('ru-RU')}{' '}
                рублей<sup>*</sup>
              </p>
              <div className={classes.economy}>
                <p className={classes.textDescriptionEconomy}>
                  Ваша экономия:{' '}
                </p>
                <p className={classes.specialTextDescriptionFooter}>
                  от {info.economySum.toLocaleString('ru-RU')} рублей
                  <sup>*</sup>
                </p>
              </div>
              <p className={classes.specialCardFooter}>
                * Суммы рассчитаны исходя из использования платформы от 10 лет
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.wrap}>
        <div className={classes.cardWrapper}>
          <div className={classes.cardHeader}>
            <p className={classes.textDescription}>Доступ к платформе</p>
            <div className={classes.headerInner}>
              {this.props.info.subTitle}
              <p className={classes.headerDiscount}>
                скидка {this.props.info.sale}
              </p>
            </div>
          </div>
          <div className={classes.cardBody}>
            <p className={classes.textDescription}>
              {this.props.info.subTitleSub}
            </p>
            <div className={classes.planPrice}>
              <p className={classes.planPriceNew}>
                {`${this.props.info.userPrice.toLocaleString('ru-RU')} ₽`}
              </p>
              <p className={classes.planPriceOld}>
                {`${this.props.info.marketingPrice.toLocaleString('ru-RU')} ₽`}
              </p>
            </div>
            <div
              onClick={this.props.onClick.bind(
                this,
                this.props.tariff,
                undefined
              )}
              className={classes.cardButton}
            >
              Подключить
            </div>
            <p className={classes.textDescription}>
              {`Сумма за месяц: ${this.props.info.monthPrice} ₽`}
            </p>
            <div className={classes.economy}>
              <p className={classes.textDescriptionEconomy}>Ваша экономия: </p>
              <p className={classes.textDescription}>
                {`${this.props.info.economySum.toLocaleString('ru-RU')} ₽`}
              </p>
            </div>
          </div>
        </div>
      </div>
    )

    return (
      <div className={classes.box}>
        <Container className={classes.plansContainer}>
          <div className={classes.plans}>
            <div className={classes.cardWrapper}>
              <div className={classes.cardHeader}>
                <p className={classes.textDescription}>Доступ к платформе</p>
                <div className={classes.headerInner}>
                  6 месяцев
                  <p className={classes.headerDiscount}>скидка 47%</p>
                </div>
              </div>
              <div className={classes.cardBody}>
                <p className={classes.textDescription}>
                  Оплата подписки: раз в полгода
                </p>
                <div className={classes.planPrice}>
                  <p className={classes.planPriceNew}>4 740 &#8381;</p>
                  <p className={classes.planPriceOld}>8 880 &#8381;</p>
                </div>
                <div
                  onClick={this.props.onClick.bind(
                    this,
                    this.props.tariff,
                    undefined
                  )}
                  className={classes.cardButton}
                >
                  Подключить
                </div>
                <p className={classes.textDescription}>
                  Сумма за месяц: 790 рублей
                </p>
                <div className={classes.economy}>
                  <p className={classes.textDescriptionEconomy}>
                    Ваша экономия:{' '}
                  </p>
                  <p className={classes.textDescription}> 8 000 рублей</p>
                </div>
              </div>
            </div>

            <div className={classes.cardWrapper}>
              <div className={classes.cardHeader}>
                <p className={classes.textDescription}>Доступ к платформе</p>
                <div className={classes.headerInner}>
                  12 месяцев
                  <p className={classes.headerDiscount}>скидка 59%</p>
                </div>
              </div>
              <div className={classes.cardBody}>
                <p className={classes.textDescription}>
                  Оплата подписки: раз в год
                </p>
                <div className={classes.planPrice}>
                  <p className={classes.planPriceNew}>7 390 &#8381;</p>
                  <p className={classes.planPriceOld}>17 760 &#8381;</p>
                </div>
                <div
                  onClick={this.props.onClick.bind(
                    this,
                    this.props.tariff,
                    undefined
                  )}
                  className={classes.cardButton}
                >
                  Подключить
                </div>
                <p className={classes.textDescription}>
                  Сумма за месяц: 615 рублей
                </p>
                <div className={classes.economy}>
                  <p className={classes.textDescriptionEconomy}>
                    Ваша экономия:{' '}
                  </p>
                  <p className={classes.textDescription}> 10 370 рублей</p>
                </div>
              </div>
            </div>

            <div className={classes.specialCardWrapper}>
              <div className={classes.headerTimerWrapper}>
                <p className={classes.specialTextDescriptionTimer}>
                  Предложение действует ещё{' '}
                </p>
                <div className={classes.headerTimerIndicator}>
                  <Timer
                    fontSize={13}
                    color={'#FFFFFF'}
                    date={new Date('Sun, 14 Oct 2022 23:59:00')}
                  />
                </div>
              </div>
              <div className={classes.specialCardHeader}>
                <p className={classes.specialTextDescription}>
                  Доступ к платформе
                </p>
                <div className={classes.specialHeaderInner}>
                  Навсегда
                  <p className={classes.specialHeaderDiscount}>скидка 75%</p>
                </div>
              </div>
              <div className={classes.specialCardBody}>
                <p className={classes.specialTextDescription}>
                  Единоразовая оплата:
                </p>
                <div className={classes.planPrice}>
                  <p className={classes.specialPlanPriceNew}>15 000 &#8381;</p>
                  <p className={classes.specialPlanPriceOld}>60 000 &#8381;</p>
                </div>
                <div
                  onClick={this.props.onClick.bind(
                    this,
                    this.props.tariff,
                    undefined
                  )}
                  className={classes.cardButton}
                >
                  Подключить
                </div>
                <p className={classes.specialTextDescriptionFooter}>
                  Сумма за месяц: до 125 рублей<sup>*</sup>
                </p>
                <div className={classes.economy}>
                  <p className={classes.textDescriptionEconomy}>
                    Ваша экономия:{' '}
                  </p>
                  <p className={classes.specialTextDescriptionFooter}>
                    от 162 600 рублей<sup>*</sup>
                  </p>
                </div>
                <p className={classes.specialCardFooter}>
                  * Суммы рассчитаны исходя из использования платформы от 10 лет
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default withStyles(style)(Payment)
